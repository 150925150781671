export default class Size {

  static convertAndFormat(bytes: string) {
    return Size.format(Number.parseInt(bytes, 10));
  }

  static format(bytes: number) {
    const thresh = 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' b';
    }
    const units = ['kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }
}
