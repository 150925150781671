import { CommonModule, Location } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap, Router, RouterLink } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { CookieService } from "../../services/cookie.service";
// import cryptoJs from 'crypto-js';
import { FormsModule } from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { PopoverModule } from "ngx-bootstrap/popover";
import { NotFoundModalComponent } from "../../features/not-found-modal/not-found-modal.component";
import { LoadingModalComponent } from "../../features/loading-modal/loading-modal.component";
import { EscapeHtmlPipe } from "../../shared/pipes/escape/keep-html.pipe";
import { OnlyNumberDirective } from "../../shared/directives";
import { CBPaginationComponent } from "../../features/cbpagination/cbpagination.component";
import { CBLoadingComponent } from "../../features/cbloading/cbloading.component";
import { BaseChartDirective } from "ng2-charts";
import { User } from "../../shared/models/user";
import {
  BoardStrength,
  BoardType,
  Box,
  BoxType,
  PaperType,
  Preset,
  Price,
  PrintType,
  Subcategory,
} from "../../shared/models/box";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { environment } from "../../../environments/environment";
import { ERROR_BODY, ERROR_TITLE } from "../../../globals";
import { ConfirmationModalComponent } from "../../features/confirmation-modal/confirmation-modal.component";
import { BoxService } from "../../services/box.service";
import { CheckoutService } from "../../services/checkout.service";
import { ContainerService } from "../../services/container.service";
import { DesignService } from "../../services/design.service";
// import { LocalStorageService } from '../../services/localstorage.service';
import { NavigationService } from "../../services/navigation.service";
import { UserService } from "../../services/user.service";
import Fractions from "../../shared/fractions";
import { PrintingTableComponent } from "./printing-table/printing-table.component";
import { PageContentWrapperComponent } from "../../features/page-content-wrapper/page-content-wrapper.component";
import { ConnectedBetterComponent } from "../../features/connected-better/connected-better.component";
import { isEmpty } from "rxjs";
declare var window: any;

@Component({
  standalone: true,
  selector: "app-box-options",
  templateUrl: "./box-options.component.html",
  styleUrls: ["./box-options.component.scss"],
  imports: [
    CommonModule,
    BaseChartDirective,
    AlertModule,
    CBLoadingComponent,
    FormsModule,
    CBPaginationComponent,
    ButtonsModule,
    PopoverModule,
    AlertModule,
    PdfJsViewerModule,
    AccordionModule,
    EscapeHtmlPipe,
    OnlyNumberDirective,
    MatSelectModule,
    MatOptionModule,
    LoadingModalComponent,
    NotFoundModalComponent,
    RouterLink,
    PrintingTableComponent,
    PageContentWrapperComponent,
    ConnectedBetterComponent,
  ],
})
export class BoxOptionsComponent implements OnInit {
  @ViewChild("connected", { static: false }) connectedModal!: TemplateRef<any>;
  @ViewChild("notFoundModal", { static: false })
  notFoundModal!: TemplateRef<any>;

  public pageLoaded!: boolean;
  public type: any;
  public templateLoading = false;

  private user: User | undefined;

  public updating!: boolean;
  public presets!: Preset[];
  public box: Box | undefined;
  public subcategoryCode!: string;
  public categoryCode!: string;

  public selectedImage = 0;
  public mainGalleryImageURL: string | undefined;
  public selectedSubcategory: Subcategory | undefined;
  infoDataRows: any[] | undefined = [];
  noteDataRows: any[] | undefined = [];
  public selectedBoardType: BoardType | undefined;
  public boardType: BoardType | undefined;
  public selectedBoardStrength: BoardStrength | undefined;

  public length = 0;
  public lengthFraction = 0;
  public lengthWhole = 0;
  public width = 0;
  public widthFraction = 0;
  public widthWhole = 0;
  public depth = 0;
  public depthFraction = 0;
  public depthWhole = 0;

  public prices!: Price[];
  public paperTypes: any[] = [];

  // private subcategories!: Subcategory[];
  subcategoriesFailed!: boolean;
  public selectedPaperType: PaperType | undefined;
  public selectedPrintType: PrintType | undefined;
  public selectedPreset: Preset | undefined;
  public gloss = false;
  public twoSided = false;
  public insideOnly = false;
  public noPrinting = false;
  public expedited = false;

  public quantityError = "";
  public quantity!: number;
  public quantities!: number[];
  public standardQuantities = [
    1, 10, 25, 50, 100, 250, 500, 1000, 1500, 2000, 5000, 10000, 12000,
  ];
  public luxeQuantities = [500, 1000, 2000, 3000, 5000, 8000, 10000];

  public hasViolationErrors = false;
  public violations: any[] = [];
  public presetMatches: Preset[] = [];
  public customSizeIncrease!: number;

  selectedPrice: Price | undefined;
  public showCustomQuantity!: boolean;
  public design: any;
  public designNamed!: boolean;

  private modal: BsModalRef | undefined;
  public subcategoryImages!: [];
  public printing: string | string[] | undefined;
  public printingOptions: (string | string[])[] | undefined;
  public standard!: boolean;
  public noprint!: boolean;
  public luxe!: boolean;
  public material = "";
  public innerMaterial = "";
  public outerMaterial = "";
  public materialOptions: string[] = [];
  public outerMaterialOptions: string[] = [];
  public innerMaterialOptions: string[] = [];
  public finish = "Matte";
  public finishOptions = ["Matte"];
  public quantityArrayIndex: number = 0;
  public boardStrength: BoardStrength | undefined;
  public preset: Preset | undefined;
  public allowCustomSizes!: boolean;
  public displayPremiumWhite!: boolean;
  public displayWhite!: boolean;
  public displayKraft!: boolean;
  public displaySecondaryWhite!: boolean;
  public displaySecondaryKraft!: boolean;
  public displayTwoSided!: boolean;
  public displayLuxe!: boolean;
  public displayNoprint!: boolean;
  public displayStandard!: boolean;
  public allowCustomSample!: boolean;
  public displayFinish: any;
  public suggestedPreset: any;
  public customSizeActive = false;
  public customSizeEntered = false;
  public presetSuggestion = false;
  public depthError: any;
  public paperTypeId: string | undefined;
  public boardTypeList!: BoardType[];
  public boardStrengthList!: BoardStrength[];
  public isDHC = false;
  public isPartition = false;
  public isTape = false;
  public isPizza = false;
  public isTissue = false;
  public isCrinkle = false;
  public isLabel = false;
  public isOtherProduct = false;
  public isPaddedMailer = false;
  public customQuantityViolation = false;
  public hasVariants = false;
  public variants: any[] = [];
  public selectedVariant: { code: string; name: string } | undefined;

  public customQuantityActive = false;
  public customQuantityEntered = false;
  public enteredCustomQuantity: string = "";

  public uploadDesign = false;

  public templateDownloadUrl!: SafeUrl;

  public boxType: BoxType | undefined;
  public categoryName: string | undefined;
  public date = new Date();

  // Other products variables
  public cellLength = 0.375;
  public cellWidth = 0.375;
  public lengthSlots = 1;
  public widthSlots = 1;
  public tapeLengthOptions = [2, 4, 8, 16];
  public tissueIncrementViolation = false;

  public shape = "Circle";
  public shapeOptions = ["Circle", "Oval", "Square", "Rectangle"];

  public downloadingTemplate = false;
  options!: string;
  public productData: any;
  private productDetails: any;
  isTest = false;

  constructor(
    private boxService: BoxService,
    private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private router: Router,
    private logger: NGXLogger,
    private containerService: ContainerService,
    private userService: UserService,
    private designService: DesignService,
    private sanitizer: DomSanitizer,
    private checkoutService: CheckoutService,
    private navigationService: NavigationService,
    private cookieService: CookieService
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    this.pageLoaded = false;
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      const sessionKey: string = params.get("sessionID") ?? "";
      if (sessionKey) {
        sessionStorage.setItem("sessionKey", sessionKey);
      }
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.categoryCode = params.get("categoryCode") ?? "";
      this.subcategoryCode = params.get("subcategory") ?? "";
      this.navigationService.setSubcategory(this.subcategoryCode);
      this.uploadDesign = Boolean(JSON.parse(params.get("uploadDesign") ?? "false"));
    });

    await this.userService
      .getCurrentUser()
      .then((response) => {
        this.user = response;
      })
      .catch((error) => {
        console.log(error);
      });
    this.isTest = JSON.parse(this.cookieService.read("isTest") || "false");
    if (this.isTest) {
      this.productDetails = this.readProductCookie();
    }
    // If not display header card.
    this.isPaddedMailer = this.subcategoryCode.startsWith("RPM");
    if (this.subcategoryCode === "tape") {
      this.isOtherProduct = true;
      this.isTape = true;
      this.getTape();
    } else if (this.subcategoryCode === "part") {
      this.isOtherProduct = true;
      this.isPartition = true;
      this.getPartition();
    } else if (this.subcategoryCode === "tissue") {
      this.isOtherProduct = true;
      this.isTissue = true;
      this.getTissue();
    } else if (this.subcategoryCode === "CKLP") {
      this.isOtherProduct = true;
      this.isCrinkle = true;
      this.getCrinkle();
    } else if (this.subcategoryCode.toLowerCase() === "lbl") {
      this.isOtherProduct = true;
      this.isLabel = true;
      this.getLabel();
    } else if (this.subcategoryCode === "DHC") {
      this.isDHC = true;
      this.getDHC();
    } else {
      this.isPizza = this.subcategoryCode === "PIZ";
      this.getPresets();
    }

    // if (window.loyaltylion) {
    //   window.loyaltylion.authenticateCustomer({
    //     customer: { id: this.user.shopifyId, email: this.user.username },
    //     auth: { date: this.date.toISOString(), token: this.getAuthToken() },
    //   });
    // }
  }
  // getAuthToken() {
  //   const date = this.date.toISOString();
  //   const secret = "b3456ad863ff28b11560dce3d09e5ee0";
  //   const authToken = cryptoJs.SHA1(
  //     this.user.shopifyId + date + this.user.username + secret
  //   );

  //   return authToken.toString();
  // }
  readProductCookie() {
    const encodedData = this.cookieService.read("productDetails");
    if (encodedData) {
      return JSON.parse(atob(encodedData));
    }
    return undefined;
  }

  goBack() {
    if (this.isPaddedMailer) {
      this.router.navigate(["/box-category"], {
        fragment: `upload=${this.uploadDesign}`,
      });
    } else {
      this.router.navigate([
        "/box-style",
        this.categoryCode,
        this.uploadDesign,
      ]);
    }
  }

  getPresets() {
    if (
      this.subcategoryCode.toLowerCase().includes("snap2") &&
      this.selectedVariant
    ) {
      this.subcategoryCode = this.selectedVariant.code;
    }
    let errorAdded = false;
    this.boxService.listPresets(this.categoryCode, this.subcategoryCode).then(
      (presets) => {
        this.presets = presets;
        if (presets && presets.length > 0) {
          this.mainGalleryImageURL =
            this.presets[0].type?.subcategory?.images[0].location;
          // Sets default box photo.
          for (const image of this.presets[0].type!.subcategory!.images) {
            if (image.default) {
              this.mainGalleryImageURL = image.location;
              break;
            }
          }
        }

        const label = this.isTest
          ? this.productDetails.length +
            " x " +
            this.productDetails.width +
            " x " +
            this.productDetails.depth
          : "";
        this.customSizeActive =
          this.isTest && this.productDetails.customSizeActive;
        this.customSizeEntered =
          this.isTest && this.productDetails.customSizeEntered;
        this.pageLoaded = true;
        if (
          this.subcategoryCode !== "PIZ" &&
          this.subcategoryCode !== "QPME" &&
          this.subcategoryCode !== "WINE" &&
          this.subcategoryCode !== "BEER"
        ) {
          this.selectedPreset = this.isTest
            ? !this.customSizeEntered
              ? this.presets.find((p) => p.label === label)
              : this.presets[0]
            : this.presets[0];
        } else if (
          this.subcategoryCode === "PIZ" ||
          this.subcategoryCode === "QPME" ||
          this.subcategoryCode === "WINE" ||
          this.subcategoryCode === "BEER"
        ) {
          this.selectedPreset = this.presets[0];
        }
        this.categoryName = this.selectedPreset?.type?.description;
        this.selectedSubcategory = this.selectedPreset?.type?.subcategory;
        this.infoDataRows = this.selectedSubcategory?.data.filter(
          (value: any) => value.type === "INFO"
        );
        console.log(this.infoDataRows);
        this.noteDataRows = this.selectedSubcategory?.data?.filter(
          (value: any) => value.type === "NOTE"
        );
        console.log(this.noteDataRows);
        this.selectedBoardType = this.selectedPreset?.type?.boardType;
        this.updatePrintingOptions();
        this.standard = true;
        this.noprint = true;
        this.luxe = false;
        this.twoSided = false;
        this.finish = "Matte";
        this.boardTypeList =
          this.selectedPreset?.type?.subcategory?.boardTypes ?? [];
        if (this.isTest && this.customSizeEntered) {
          this.lengthWhole = this.productDetails.lengthWhole;
          this.widthWhole = this.productDetails.widthWhole;
          this.depthWhole = this.productDetails.depthWhole;
          this.lengthFraction = this.productDetails.lengthFraction;
          this.widthFraction = this.productDetails.widthFraction;
          this.depthFraction = this.productDetails.depthFraction;
        }

        this.selectedBoardType = this.boardTypeList.find(
          (boardType) =>
            boardType.id === this.selectedPreset?.type?.boardType?.id
        );

        this.paperTypes = this.boxService.getPaperTypes(
          this.selectedSubcategory!
        );
        this.selectedBoardStrength = this.selectedBoardType?.boardStrengths[0];
        this.boardStrength = this.selectedBoardStrength;
        this.allowCustomSizes = this.selectedPreset?.allowCustomSizes ?? false;
        this.displayPremiumWhite =
          this.selectedPreset?.displayPremiumWhite ?? false;
        this.displayWhite = this.selectedPreset?.displayWhite ?? false;
        this.displayKraft = this.selectedPreset?.displayKraft ?? false;
        this.displaySecondaryWhite =
          this.selectedPreset?.displaySecondaryWhite ?? false;
        this.displaySecondaryKraft =
          this.selectedPreset?.displaySecondaryKraft ?? false;
        this.displayTwoSided = this.selectedPreset?.displayTwoSided ?? false;
        this.displayLuxe = this.selectedPreset?.displayLuxe ?? false;
        this.displayStandard = this.selectedPreset?.displayStandard ?? false;
        this.displayNoprint = this.selectedPreset?.displayNoprint ?? false;
        this.allowCustomSample =
          this.selectedPreset?.allowCustomSample ?? false;
        this.hasVariants = this.selectedPreset?.hasVariants ?? false;

        if (this.hasVariants) {
          this.getVariants();
        }
        this.updateForm();
      },
      (reason) => {
        if (!errorAdded) {
          errorAdded = true;
          this.showModal(this.notFoundModal);
          this.logger.error(reason);
        }
      }
    );
  }

  getDHC() {
    this.boxService.getSubcategory(this.subcategoryCode).then(
      (subcategory) => {
        this.pageLoaded = true;
        this.selectedSubcategory = subcategory;
        this.standard = true;
        this.luxe = false;
        this.customSizeActive = true;
        this.customSizeEntered = false;
        this.twoSided = false;
        this.length = 8;
        this.lengthWhole = 8;
        this.width = 4;
        this.widthWhole = 4;
        this.depth = 0;
        this.presets = [];
        this.printingOptions = [];
        this.finish = "Matte";
        this.boardTypeList = subcategory.boardTypes;
        this.selectedBoardType = this.boardTypeList[0];
        this.selectedBoardStrength =
          subcategory.boardTypes[0].boardStrengths[0];
        this.mainGalleryImageURL = subcategory.images[0].location;
        this.boardStrength =
          this.selectedSubcategory.boardTypes[0].boardStrengths[0];
        this.allowCustomSizes =
          this.selectedSubcategory.allowCustomSizes ?? false;
        this.displayPremiumWhite =
          this.selectedSubcategory.displayPremiumWhite ?? false;
        this.displayWhite = this.selectedSubcategory.displayWhite ?? false;
        this.displayKraft = this.selectedSubcategory.displayKraft ?? false;
        this.displaySecondaryWhite =
          this.selectedSubcategory.displaySecondaryWhite ?? false;
        this.displaySecondaryKraft =
          this.selectedSubcategory.displaySecondaryKraft ?? false;
        this.displayTwoSided =
          this.selectedSubcategory.displayTwoSided ?? false;
        this.displayLuxe = this.selectedSubcategory.displayLuxe ?? false;
        this.displayStandard =
          this.selectedSubcategory.displayStandard ?? false;
        this.allowCustomSample =
          this.selectedSubcategory.allowCustomSample ?? false;

        // Sets default box photo.
        for (const image of subcategory.images) {
          if (image.default) {
            this.mainGalleryImageURL = image.location;
            break;
          }
        }

        this.listPrices();
      },
      (reason) => {
        this.showModal(this.notFoundModal);
        this.logger.error(reason);
      }
    );
  }

  getTape() {
    this.customSizeEntered = true;
    this.prices = [];
    this.length = 2;
    this.lengthWhole = 2;
    this.width = 3;
    this.widthWhole = 3;
    this.depth = 0;
    this.depthWhole = 0;
    this.categoryName = "Tape";

    this.boxService.getSubcategory(this.subcategoryCode).then(
      (subcategory) => {
        for (const image of subcategory.images) {
          if (image.default) {
            this.mainGalleryImageURL = image.location;
            break;
          }
        }

        this.boxService
          .getType(this.subcategoryCode)
          .then((type) => {
            this.selectedBoardType = type.boardType;
            this.boardType = type.boardType;
            this.type = type;
          })
          .catch(() => {
            this.showModal(this.notFoundModal);
          });
        this.selectedSubcategory = subcategory;
        this.paperTypes = this.boxService.getPaperTypes(
          this.selectedSubcategory
        );
        this.presets = [];
        this.selectedPreset = undefined;
        this.prices = [];
        this.allowCustomSizes = subcategory.allowCustomSizes ?? false;
        this.displayPremiumWhite = subcategory.displayPremiumWhite ?? false;
        this.displayWhite = subcategory.displayWhite ?? false;
        this.displayKraft = subcategory.displayKraft ?? false;
        this.displaySecondaryWhite = subcategory.displaySecondaryWhite ?? false;
        this.displaySecondaryKraft = subcategory.displaySecondaryKraft ?? false;
        this.displayTwoSided = subcategory.displayTwoSided ?? false;
        this.displayLuxe = subcategory.displayLuxe ?? false;
        this.displayStandard = subcategory.displayStandard ?? false;
        this.allowCustomSample = subcategory.allowCustomSample ?? false;
        this.hasVariants = subcategory.hasVariants ?? false;

        this.updatePrintingOptions();
        this.updateMaterialOptions();
        this.listTapePrices();
      },
      (reason) => {
        this.showModal(this.notFoundModal);
        this.logger.error(reason);
      }
    );
  }

  getPartition() {
    this.customSizeEntered = true;
    this.prices = [];
    this.length = 10;
    this.lengthWhole = 10;
    this.width = 10;
    this.widthWhole = 10;
    this.depth = 10;
    this.depthWhole = 10;
    this.categoryName = "Partition";

    this.boxService.getSubcategory(this.subcategoryCode).then(
      (subcategory) => {
        for (const image of subcategory.images) {
          if (image.default) {
            this.mainGalleryImageURL = image.location;
            break;
          }
        }

        this.selectedSubcategory = subcategory;
        this.selectedBoardStrength = {
          code: "standard32",
          name: "Standard 32",
          strength: "32",
          deserialize(input: any): BoardStrength {
            // super.deserialize(input);
            this.code = input.code;
            this.name = input.name;
            this.strength = input.strength;
            return this;
          },
          id: 1,
          createdBy: "SYSTEM",
          createdTime: new Date(),
          updatedBy: "SYSTEM",
          updatedTime: new Date(),
        };
        this.paperTypes = this.boxService.getPaperTypes(
          this.selectedSubcategory
        );
        this.quantity = 10;
        this.presets = [];
        this.selectedPreset = undefined;
        this.prices = [];
        this.allowCustomSizes = subcategory.allowCustomSizes ?? false;
        this.displayPremiumWhite = subcategory.displayPremiumWhite ?? false;
        this.displayWhite = subcategory.displayWhite ?? false;
        this.displayKraft = subcategory.displayKraft ?? false;
        this.displaySecondaryWhite = subcategory.displaySecondaryWhite ?? false;
        this.displaySecondaryKraft = subcategory.displaySecondaryKraft ?? false;
        this.displayTwoSided = subcategory.displayTwoSided ?? false;
        this.displayLuxe = subcategory.displayLuxe ?? false;
        this.displayStandard = subcategory.displayStandard ?? false;
        this.allowCustomSample = subcategory.allowCustomSample ?? false;
        this.hasVariants = subcategory.hasVariants ?? false;

        this.updatePrintingOptions();
        this.updateMaterialOptions();
        this.listPartitionPrices();
      },
      (reason) => {
        this.showModal(this.notFoundModal);
        this.logger.error(reason);
      }
    );
  }

  getTissue() {
    this.categoryName = "Tissue";

    this.boxService
      .listPresets(this.categoryCode, this.subcategoryCode)
      .then((presets) => {
        this.boxService.getSubcategory(this.subcategoryCode).then(
          (subcategory) => {
            this.pageLoaded = true;
            this.presets = presets;
            for (const image of subcategory.images) {
              if (image.default) {
                this.mainGalleryImageURL = image.location;
                break;
              }
            }
            this.selectedPreset = presets[0];
            this.selectedSubcategory = subcategory;
            this.paperTypes = this.boxService.getPaperTypes(
              this.selectedSubcategory
            );
            this.quantity = 10;
            this.prices = [];
            this.allowCustomSizes = subcategory.allowCustomSizes ?? false;
            this.displayPremiumWhite = subcategory.displayPremiumWhite ?? false;
            this.displayWhite = subcategory.displayWhite ?? false;
            this.displayKraft = subcategory.displayKraft ?? false;
            this.displaySecondaryWhite =
              subcategory.displaySecondaryWhite ?? false;
            this.displaySecondaryKraft =
              subcategory.displaySecondaryKraft ?? false;
            this.displayTwoSided = subcategory.displayTwoSided ?? false;
            this.displayLuxe = subcategory.displayLuxe ?? false;
            this.displayStandard = subcategory.displayStandard ?? false;
            this.allowCustomSample = subcategory.allowCustomSample ?? false;
            this.hasVariants = subcategory.hasVariants ?? false;

            this.updatePrintingOptions();
            this.updateMaterialOptions();
            this.listTissuePrices();
          },
          (reason) => {
            this.showModal(this.notFoundModal);
            this.logger.error(reason);
          }
        );
      });
  }

  getCrinkle() {
    this.categoryName = "Crinkle";

    this.boxService.listPresets(this.categoryCode, this.subcategoryCode).then(
      (presets) => {
        this.boxService.getSubcategory(this.subcategoryCode).then(
          (subcategory) => {
            this.pageLoaded = true;
            this.presets = presets;
            for (const image of subcategory.images) {
              if (image.default) {
                this.mainGalleryImageURL = image.location;
                break;
              }
            }
            this.selectedPreset = presets[0];
            this.selectedSubcategory = subcategory;
            this.paperTypes = this.boxService.getPaperTypes(
              this.selectedSubcategory
            );
            // this.paperType = this.paperTypes[0].id;
            this.quantity = 10;
            this.prices = [];
            this.allowCustomSizes = subcategory.allowCustomSizes ?? false;
            this.displayPremiumWhite = subcategory.displayPremiumWhite ?? false;
            this.displayWhite = subcategory.displayWhite ?? false;
            this.displayKraft = subcategory.displayKraft ?? false;
            this.displaySecondaryWhite =
              subcategory.displaySecondaryWhite ?? false;
            this.displaySecondaryKraft =
              subcategory.displaySecondaryKraft ?? false;
            this.displayTwoSided = subcategory.displayTwoSided ?? false;
            this.displayLuxe = subcategory.displayLuxe ?? false;
            this.displayStandard = subcategory.displayStandard ?? false;
            this.allowCustomSample = subcategory.allowCustomSample ?? false;
            this.hasVariants = subcategory.hasVariants ?? false;
            this.listCrinklePrices();
          },
          (reason) => {
            this.showModal(this.notFoundModal);
            this.logger.error(reason);
          }
        );
      },
      (reason) => {
        this.showModal(this.notFoundModal);
        this.logger.error(reason);
      }
    );
  }

  getLabel() {
    this.categoryName = "Label";

    this.boxService.listPresets(this.categoryCode, this.subcategoryCode).then(
      (presets) => {
        presets = presets.filter((x) => {
          return x.label?.toLowerCase().includes(this.shape.toLowerCase());
        });

        presets.forEach((x) => {
          const i = x.label?.indexOf("(");
          x.label = x.label?.substr(0, i);
        });

        this.boxService.getSubcategory(this.subcategoryCode).then(
          (subcategory) => {
            this.pageLoaded = true;
            for (const image of subcategory.images) {
              if (image.default) {
                this.mainGalleryImageURL = image.location;
                break;
              }
            }
            this.presets = presets;
            this.selectedPreset = presets[0];
            this.length = this.selectedPreset.length;
            this.width = this.selectedPreset.width;
            this.depth = this.selectedPreset.depth;
            this.selectedSubcategory = subcategory;
            this.paperTypes = this.boxService.getPaperTypes(
              this.selectedSubcategory
            );
            this.prices = [];
            this.allowCustomSizes = subcategory.allowCustomSizes ?? false;
            this.displayPremiumWhite = subcategory.displayPremiumWhite ?? false;
            this.displayWhite = subcategory.displayWhite ?? false;
            this.displayKraft = subcategory.displayKraft ?? false;
            this.displaySecondaryWhite =
              subcategory.displaySecondaryWhite ?? false;
            this.displaySecondaryKraft =
              subcategory.displaySecondaryKraft ?? false;
            this.displayTwoSided = subcategory.displayTwoSided ?? false;
            this.displayLuxe = subcategory.displayLuxe ?? false;
            this.displayStandard = subcategory.displayStandard ?? false;
            this.allowCustomSample = subcategory.allowCustomSample ?? false;
            this.hasVariants = subcategory.hasVariants ?? false;
            this.quantity = 10;

            this.updatePrintingOptions();
            this.updateMaterialOptions();
            this.listLabelPrices();
          },
          (reason) => {
            this.showModal(this.notFoundModal);
            this.logger.error(reason);
          }
        );
      },
      (reason) => {
        this.showModal(this.notFoundModal);
        this.logger.error(reason);
      }
    );
  }
  cancel() {
    this.modal?.hide();
  }
  getVariants() {
    if (
      this.subcategoryCode === "SNAP2S" ||
      this.subcategoryCode === "SNAP2RS"
    ) {
      this.luxe = true;
      this.options = "superseal";
    }
    if (!this.selectedVariant) {
      this.boxService.getVariants(this.selectedSubcategory?.id!).then(
        (value) => {
          this.variants = value;
          this.selectedVariant = this.variants[0];
        },
        (reason) => {
          this.showModal(this.notFoundModal);
          this.logger.error(reason);
        }
      );
    } else {
      this.selectedPreset = undefined;
    }
  }

  updateForm() {
    if (this.isLabel) {
      this.updateLabelForm();
    } else if (this.isOtherProduct) {
      this.updateOtherProductForm();
    } else {
      this.box = undefined;
      if (this.subcategoryCode === "DHC") {
        this.categoryName = "Display Header Card";
      } else {
        this.categoryName =
          this.selectedPreset?.type?.name + " " + this.selectedBoardType?.name;
      }

      if (!this.customSizeEntered) {
        this.length = this.selectedPreset?.length ?? 0;
        this.lengthWhole = this.getWholeNumber(this.length);
        this.lengthFraction = this.convertFractionToDecimal(this.length);
        this.width = this.selectedPreset?.width ?? 0;
        this.widthWhole = this.getWholeNumber(this.width);
        this.widthFraction = this.convertFractionToDecimal(this.width);
        this.depth = this.selectedPreset?.depth ?? 0;
        this.depthWhole = this.getWholeNumber(this.depth);
        this.depthFraction = this.convertFractionToDecimal(this.depth);
      } else {
        this.length =
          this.lengthWhole + this.convertFractionToDecimal(this.lengthFraction);
        this.width =
          this.widthWhole + this.convertFractionToDecimal(this.widthFraction);
        this.depth =
          this.depthWhole + this.convertFractionToDecimal(this.depthFraction);
      }

      this.boardStrengthList = this.selectedBoardType?.boardStrengths ?? [];
      this.selectedBoardStrength = this.boardStrengthList.find(
        (value) => value.code === this.selectedBoardStrength?.code
      );
      if (!this.selectedBoardStrength) {
        this.selectedBoardStrength = this.boardStrengthList[0];
      }
      this.noPrinting = false;
      this.updatePrintingOptions();
      this.updateMaterialOptions();
      if (this.customQuantityActive) {
        this.enteredCustomQuantity = this.quantity.toString().split("(")[0];
        if (
          this.getPaperTypeID(this.outerMaterial) === "pkw" ||
          this.getPaperTypeID(this.innerMaterial) === "pkw"
        ) {
          this.paperTypeId = "pkw";
        } else if (this.categoryCode === "Event Boxes") {
          this.paperTypeId = this.getPaperTypeID(this.outerMaterial);
        } else if (
          this.getPaperTypeID(this.outerMaterial) === "kw" &&
          this.getPaperTypeID(this.innerMaterial) === "k"
        ) {
          this.paperTypeId = "kw";
        } else if (
          this.getPaperTypeID(this.outerMaterial) === "k" &&
          this.getPaperTypeID(this.innerMaterial) === "kw"
        ) {
          this.paperTypeId = "kw";
        } else if (
          this.getPaperTypeID(this.outerMaterial) === "k" ||
          this.getPaperTypeID(this.innerMaterial) === "k"
        ) {
          this.paperTypeId = "k";
        } else {
          this.paperTypeId = "kw";
        }
        this.gloss = this.finish === "Gloss";
        this.checkForViolations();
      }
      // if (!this.customQuantityActive) {
      if (this.isPizza) {
        this.listPizzaPrices();
      } else if (this.isPaddedMailer) {
        this.listPaddedMailerPrices();
      } else {
        this.listPrices();
      }
    }
  }

  updateLabelForm() {
    this.box = undefined;
    this.prices = [];

    this.length = this.selectedPreset?.length ?? 0;
    this.lengthWhole = this.getWholeNumber(this.length);
    this.lengthFraction = this.convertFractionToDecimal(this.length);
    this.width = this.selectedPreset?.width ?? 0;
    this.widthWhole = this.getWholeNumber(this.width);
    this.widthFraction = this.convertFractionToDecimal(this.width);
    this.depth = this.selectedPreset?.depth ?? 0;
    this.depthWhole = this.getWholeNumber(this.depth);
    this.depthFraction = this.convertFractionToDecimal(this.depth);

    this.expedited = false;
    this.noPrinting = false;
    this.updatePrintingOptions();
    this.updateMaterialOptions();
    // if (this.isValid) {
    this.listLabelPrices();
  }

  updateOtherProductForm() {
    this.box = undefined;
    this.prices = [];

    console.log("Length slots is: ", this.lengthSlots);

    if (!this.customSizeEntered) {
      this.length = this.selectedPreset?.length ?? 0;
      this.lengthWhole = this.getWholeNumber(this.length);
      this.lengthFraction = this.convertFractionToDecimal(this.length);
      this.width = this.selectedPreset?.width ?? 0;
      this.widthWhole = this.getWholeNumber(this.width);
      this.widthFraction = this.convertFractionToDecimal(this.width);
      this.depth = this.selectedPreset?.depth ?? 0;
      this.depthWhole = this.getWholeNumber(this.depth);
      this.depthFraction = this.convertFractionToDecimal(this.depth);
    } else {
      this.length =
        this.getWholeNumber(this.length) +
        this.convertFractionToDecimal(this.lengthFraction);
      this.width =
        this.widthWhole + this.convertFractionToDecimal(this.widthFraction);
      this.depth =
        this.depthWhole + this.convertFractionToDecimal(this.depthFraction);
    }
    this.expedited = false;
    this.noPrinting = false;
    this.updatePrintingOptions();
    this.updateMaterialOptions();

    if (this.isTape) {
      this.listTapePrices();
    } else if (this.isTissue) {
      this.listTissuePrices();
    } else if (this.isCrinkle) {
      this.listCrinklePrices();
    } else {
      this.listPartitionPrices();
    }
  }

  // Called from the custom size container. Takes the length entered and fraction selected
  // And creates a respective length, width, and depth and then calls pricing function.
  checkForPresets() {
    this.length =
      this.lengthWhole + this.convertFractionToDecimal(this.lengthFraction);
    this.width =
      this.widthWhole + this.convertFractionToDecimal(this.widthFraction);
    if (!this.isDHC) {
      this.depth =
        this.depthWhole + this.convertFractionToDecimal(this.depthFraction);
    } else {
      this.depth = this.convertFractionToDecimal(this.depthFraction);
    }
    if (this.selectedPrice) {
      this.quantity = this.selectedPrice.quantity;
    } else {
      if (this.standard) {
        this.quantity = 100;
      } else {
        this.quantity = 1000;
      }
    }
    if (this.subcategoryCode === "part") {
      this.listPartitionPrices();
    } else if (this.subcategoryCode === "tissue") {
      this.listTissuePrices();
    } else if (this.subcategoryCode === "tape") {
      this.listTapePrices();
    } else if (this.isLabel) {
      this.listLabelPrices();
    } else {
      this.listPrices();
    }
  }

  presetSuggestionSelected(preset: Preset) {
    // After user selects the suggest preset instead of custom sizing,
    // find the preset's ID in the existing presetsList and set that as the selectedPreset.
    this.presets.forEach((_item, index) => {
      if (preset.id === this.presets[index].id) {
        this.selectedPreset = this.presets[index];
      }
    });
    this.customSizeEntered = false;
    this.updateForm();
  }

  checkForViolations() {
    if (this.enteredCustomQuantity) {
      this.quantity = parseInt(this.enteredCustomQuantity);
    } else {
      this.enteredCustomQuantity = `${this.quantity}`;
    }

    let paperTypeName = "white";
    if (this.outerMaterial === "Kraft" || this.outerMaterial === "k") {
      paperTypeName = "kraft";
    }

    if (this.isPaddedMailer) {
      this.boxService
        .getPaddedMailerPrice(
          this.selectedPreset?.type?.code ?? "",
          paperTypeName,
          this.quantity,
          this.noPrinting
        )
        .then((value: any) => {
          // If custom quantity is 1 then it is considered a sample and is allowed

          const tmp = parseInt(this.enteredCustomQuantity);
          this.customQuantityViolation =
            this.enteredCustomQuantity !== "1" &&
            (tmp! < this.prices[0]!.minimumOrder ||
              tmp > this.prices[0]!.maxQuantity);

          if (!this.customQuantityViolation) {
            this.selectedPrice = value.price;
            this.enteredCustomQuantity += ` cases of ${
              this.selectedPrice?.unitsPerCase
            } ($${this.selectedPrice?.pricePerUnit!.toFixed(2)}) each`;
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else if (this.isPartition) {
      this.boxService
        .getPartitionPrice(
          "e",
          this.depth,
          this.length,
          this.cellLength,
          paperTypeName,
          this.quantity,
          this.width,
          this.cellWidth,
          this.lengthSlots,
          this.widthSlots
        )
        .then((value: any) => {
          // If custom quantity is 1 then it is considered a sample and is allowed
          const tmp = parseInt(this.enteredCustomQuantity);
          this.customQuantityViolation =
            this.enteredCustomQuantity !== "1" &&
            (tmp < this.prices[0].minimumOrder ||
              tmp > this.prices[0].maxQuantity);

          if (!this.customQuantityViolation) {
            this.selectedPrice = value.price;
            this.enteredCustomQuantity += ` ($${this.selectedPrice?.pricePerUnit?.toFixed(
              2
            )}) each`;
          }
        })
        .catch(() => {
          this.bsModalService.show(ConfirmationModalComponent);
          // .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else if (this.isTape) {
      this.boxService
        .getTapePrice(paperTypeName, this.quantity)
        .then((value: any) => {
          const tmp = parseInt(this.enteredCustomQuantity);
          this.customQuantityViolation =
            this.enteredCustomQuantity !== "1" &&
            (tmp < this.prices[0].minimumOrder ||
              tmp > this.prices[0].maxQuantity);

          if (!this.customQuantityViolation) {
            this.selectedPrice = value.price;
            // 8 rolls per case
            this.enteredCustomQuantity += ` case(s) ($${
              value.price.pricePerCase.toFixed(2) / 8
            }/Roll)`;
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else if (this.isTissue) {
      if (this.quantity % 100 !== 0) {
        this.tissueIncrementViolation = true;
      } else {
        this.tissueIncrementViolation = false;
        this.boxService
          .getTissuePrice(Math.ceil(this.quantity / 100), 18)
          .then((value: any) => {
            const tmp = parseInt(this.enteredCustomQuantity);
            this.customQuantityViolation =
              (tmp < this.prices[0].minimumOrder ||
                tmp > this.prices[0].maxQuantity) &&
              this.enteredCustomQuantity !== "1";

            if (!this.customQuantityViolation) {
              this.selectedPrice = value.price;
              this.enteredCustomQuantity += ` sheets ($${value.price.pricePerSheet.toFixed(
                2
              )} per sheet)`;
            }
          })
          .catch(() => {
            this.bsModalService
              .show(ConfirmationModalComponent)
              .content?.showModal(ERROR_BODY, ERROR_TITLE);
          });
      }
    } else if (this.isLabel) {
      this.boxService
        .getLabelPrice(
          this.quantity,
          this.shape.toLowerCase(),
          "c",
          this.length,
          this.width,
          this.depth
        )
        .then((value: any) => {
          const tmp = parseInt(this.enteredCustomQuantity);
          this.customQuantityViolation =
            (tmp < this.prices[0].minimumOrder ||
              tmp > this.prices[0].maxQuantity) &&
            this.enteredCustomQuantity !== "1";

          if (!this.customQuantityViolation) {
            this.selectedPrice = value.price;
            this.enteredCustomQuantity += ` ($${value.price.pricePerUnit.toFixed(
              2
            )}) each`;
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else if (this.isCrinkle) {
      this.boxService
        .getCrinklePrice(this.quantity, this.selectedPreset?.label)
        .then((value: any) => {
          const tmp = parseInt(this.enteredCustomQuantity);
          this.customQuantityViolation =
            (tmp < this.prices[0].minimumOrder ||
              tmp > this.prices[0].maxQuantity) &&
            this.enteredCustomQuantity !== "1";

          if (!this.customQuantityViolation) {
            this.selectedPrice = value.price;
            this.enteredCustomQuantity += ` ($${value.price.pricePerUnit.toFixed(
              2
            )}) each`;
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else {
      this.boxService
        .getPrice(
          this.boxCode ?? "",
          this.selectedBoardStrength?.strength ?? "",
          this.paperTypeId ?? "",
          this.length,
          this.width,
          this.depth,
          this.quantity,
          this.twoSided,
          this.luxe,
          this.gloss,
          this.noPrinting,
          this.options,
          this.expedited !== undefined ? this.expedited : false
        )
        .then((value) => {
          const tmp = parseInt(this.enteredCustomQuantity);
          this.box = value;
          if (!this.luxe) {
            this.customQuantityViolation =
              (tmp < (this.box.boxType?.minimumOrder ?? 0) ||
                tmp > (this.box.boxType?.maxQuantity ?? 0)) &&
              (this.enteredCustomQuantity !== "1" ||
                this.subcategoryCode === "SNAP2" ||
                this.subcategoryCode === "SNAP2R");
          } else {
            this.customQuantityViolation =
              (tmp < (this.box.boxType?.luxeMinimumOrder ?? 0) ||
                tmp > (this.box.boxType?.maxQuantity ?? 0)) &&
              (this.enteredCustomQuantity !== "1" ||
                this.subcategoryCode === "SNAP2" ||
                this.subcategoryCode === "SNAP2R");
          }
          if (
            this.subcategoryCode === "PIZ" &&
            this.enteredCustomQuantity === "1"
          ) {
            this.customQuantityViolation = true;
          }

          if (!this.customQuantityViolation) {
            this.selectedPrice = value.price;
            this.enteredCustomQuantity += ` ($${this.selectedPrice?.pricePerUnit?.toFixed(
              2
            )}) each`;
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    }
  }
  numberOnly(event: { which: any; keyCode: any }) {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  listPrices() {
    this.prices = [];
    this.updating = true;
    if (
      this.getPaperTypeID(this.outerMaterial) === "pkw" ||
      this.getPaperTypeID(this.innerMaterial) === "pkw"
    ) {
      this.paperTypeId = "pkw";
    } else if (this.categoryCode === "Event Boxes") {
      this.paperTypeId = this.getPaperTypeID(this.outerMaterial);
    } else if (
      this.getPaperTypeID(this.outerMaterial) === "kw" &&
      this.getPaperTypeID(this.innerMaterial) === "k"
    ) {
      this.paperTypeId = "kw";
    } else if (
      this.getPaperTypeID(this.outerMaterial) === "k" &&
      this.getPaperTypeID(this.innerMaterial) === "kw"
    ) {
      this.paperTypeId = "kw";
    } else if (
      this.getPaperTypeID(this.outerMaterial) === "k" ||
      this.getPaperTypeID(this.innerMaterial) === "k"
    ) {
      this.paperTypeId = "k";
    } else {
      this.paperTypeId = "kw";
    }

    this.gloss = this.finish === "Gloss";

    this.quantities = [];
    if (this.standard) {
      this.standardQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    } else if (this.noprint) {
      if (
        this.subcategoryCode === "SNAP2" &&
        this.printing === "Blank Boxes | No Printing"
      ) {
        this.luxe = true;
        this.noprint = true;
        this.quantities.push();
      }
      this.standardQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    } else if (this.noprint) {
      if (
        this.subcategoryCode === "SNAP2R" &&
        this.printing === "Blank Boxes | No Printing"
      ) {
        this.luxe = true;
        this.noprint = true;
        this.quantities.push();
      }
      this.standardQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    } else if (this.luxe) {
      if (this.subcategoryCode === "SNAP2") {
        this.quantities.push();
      }
      this.luxeQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    } else if (this.luxe) {
      if (this.subcategoryCode === "SNAP2R") {
        this.quantities.push();
      }
      this.luxeQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    }

    if (
      (this.printing?.includes("Luxe") ||
        this.printing === "Blank Boxes | No Printing") &&
      this.subcategoryCode === "SNAP2"
    ) {
      this.luxe = true;
      this.options = "";
    } else if (
      (this.printing?.includes("Luxe") ||
        this.printing === "Blank Boxes | No Printing") &&
      this.subcategoryCode === "SNAP2S"
    ) {
      this.luxe = true;
    }
    if (
      (this.printing?.includes("Luxe") ||
        this.printing === "Blank Boxes | No Printing") &&
      this.subcategoryCode === "SNAP2R"
    ) {
      this.luxe = true;
      this.options = "";
    } else if (
      (this.printing?.includes("Luxe") ||
        this.printing === "Blank Boxes | No Printing") &&
      this.subcategoryCode === "SNAP2RS"
    ) {
      this.luxe = true;
    }
    this.boxService
      .listPrices(
        this.boxCode ?? "",
        this.selectedBoardStrength?.strength ?? "",
        this.paperTypeId,
        this.length,
        this.width,
        this.depth,
        this.quantities,
        this.twoSided,
        this.luxe,
        this.noPrinting,
        this.options,
        this.expedited
      )
      .then(
        (priceData) => {
          if (priceData.prices.length > 0 && !this.customQuantityActive) {
            if (!this.quantityArrayIndex) {
              if (this.standard) {
                this.selectedPrice = priceData.prices[4];
              } else if (this.noprint) {
                this.selectedPrice = priceData.prices[4];
              } else {
                this.selectedPrice = priceData.prices[0];
              }
              // Default SNAP2 quantity to 1000
              if (this.subcategoryCode.startsWith("SNAP2")) {
                this.selectedPrice = priceData.prices[0];
              }
            } else {
              this.selectedPrice = priceData.prices[this.quantityArrayIndex];
            }
          }
          this.box = priceData.box;
          if (this.box.boxType?.code === "RSCB") {
            if (
              this.box &&
              this.box.dimensions &&
              (this.box.dimensions.length ?? 0) > 2.9375 &&
              (this.box.dimensions.width ?? 0) > 2.9375 &&
              (this.box.dimensions.depth ?? 0) > 2.9375
            ) {
              priceData.box.violations = priceData.box.violations.filter(
                (x) => !x.rule?.includes("Rule15")
              );
            } else {
              priceData.box.violations.map((x) => {
                if (x.rule?.includes("Rule15")) {
                  x.message = "Please call to order";
                }
                return x;
              });
            }
            //
          }
          this.violations = priceData.box.violations;
          if (!this.luxe) {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].minimumOrder &&
                  x.quantity <= priceData.prices[0].maxQuantity) ||
                (this.selectedSubcategory?.allowCustomSample &&
                  x.quantity === 1)
            );
          } else {
            priceData.prices = priceData.prices.filter(
              (x) =>
                x.quantity >= priceData.prices[0].luxeMinimumOrder ||
                (this.selectedSubcategory?.allowCustomSample &&
                  x.quantity === 1)
            );
          }

          if (this.selectedSubcategory?.allowCustomSample) {
            priceData.prices.push();
          }
          this.prices = priceData.prices;
          console.log("The prices are: ", this.prices);
          this.presetMatches = priceData.presetMatches ?? [];
          this.customSizeIncrease = priceData.customSizeIncrease / 100;
          // this.boardTypeList = priceData.box.boxType.subcategory.boardTypes;
          const something = priceData.box.violations.find((violation) => {
            if (this.subcategoryCode === "OPF") {
              return !violation.warning;
            }
            return violation.error;
          });

          this.hasViolationErrors = something !== undefined;
          this.updating = false;
        },
        (reason) => {
          this.showModal(this.notFoundModal);
          this.logger.error(reason);
          this.prices = [];
          this.updating = false;
        }
      );
  }

  public trackByFn(index: any, item: any) {
    if (!item) {
      return index;
    } else {
      //return item.id;
      return index;
    }
  }

  listPizzaPrices() {
    this.quantities = [
      500, 750, 1000, 1250, 1500, 1750, 2000, 3000, 4000, 5000, 6000, 7000,
      8000, 9000, 10000, 11000, 12000,
    ];
    this.luxe = true;
    this.prices = [];
    this.updating = true;
    if (
      this.getPaperTypeID(this.outerMaterial) === "pkw" ||
      this.getPaperTypeID(this.innerMaterial) === "pkw"
    ) {
      this.paperTypeId = "pkw";
    } else if (this.categoryCode === "Event Boxes") {
      this.paperTypeId = this.getPaperTypeID(this.outerMaterial);
    } else if (this.getPaperTypeID(this.outerMaterial) === "k") {
      this.paperTypeId = "k";
    } else {
      this.paperTypeId = "kw";
    }

    this.gloss = this.finish === "Gloss";

    this.boxService
      .listPrices(
        this.boxCode ?? "",
        this.selectedBoardStrength?.strength ?? "",
        this.paperTypeId,
        this.length,
        this.width,
        this.depth,
        this.quantities,
        this.twoSided,
        this.luxe,
        this.noPrinting,
        this.options,
        this.expedited
      )
      .then(
        (priceData) => {
          if (priceData.prices.length > 0) {
            if (!this.quantityArrayIndex) {
              if (this.standard) {
                this.selectedPrice = priceData.prices[4];
              } else {
                this.selectedPrice = priceData.prices[0];
              }
              // Default SNAP2 quantity to 1000
              if (this.subcategoryCode === "SNAP2") {
                this.selectedPrice = priceData.prices[0];
              }
              if (this.subcategoryCode === "SNAP2S") {
                this.selectedPrice = priceData.prices[0];
              }
              if (this.subcategoryCode === "SNAP2R") {
                this.selectedPrice = priceData.prices[0];
              }
              if (this.subcategoryCode === "SNAP2RS") {
                this.selectedPrice = priceData.prices[0];
              }
            } else {
              this.selectedPrice = priceData.prices[this.quantityArrayIndex];
            }
          }
          this.box = priceData.box;
          this.violations = priceData.box.violations;
          if (!this.luxe) {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].minimumOrder &&
                  x.quantity <= priceData.prices[0].maxQuantity) ||
                (this.selectedSubcategory?.allowCustomSample &&
                  x.quantity === 1)
            );
          } else {
            priceData.prices = priceData.prices.filter(
              (x) =>
                x.quantity >= priceData.prices[0].luxeMinimumOrder ||
                (this.selectedSubcategory?.allowCustomSample &&
                  x.quantity === 1)
            );
          }

          if (this.selectedSubcategory?.allowCustomSample) {
            priceData.prices.push();
          }
          this.prices = priceData.prices;
          console.log("The prices are: ", this.prices);
          this.presetMatches = priceData.presetMatches ?? [];
          this.customSizeIncrease = priceData.customSizeIncrease / 100;
          // this.boardTypeList = priceData.box.boxType.subcategory.boardTypes;
          const something = priceData.box.violations.find((violation) => {
            return !violation.warning;
          });

          this.hasViolationErrors = something !== undefined;
          this.updating = false;
        },
        (reason) => {
          this.showModal(this.notFoundModal);
          this.logger.error(reason);
          this.prices = [];
          this.updating = false;
        }
      );
  }

  listPaddedMailerPrices() {
    switch (this.selectedPreset?.type?.code) {
      case "RPM00Z":
        this.quantities = [1, 2, 10, 20, 100];
        break;
      case "RPM02Z":
      case "RPM05Z":
        this.quantities = [1, 5, 20, 50, 100, 200];
        break;
      case "RPM06Z":
        this.quantities = [1, 10, 50, 150, 250, 500];
        break;
    }

    if (this.getPaperTypeID(this.material) === "kw") {
      this.paperTypeId = "w";
    }
    this.noPrinting = this.printing === "Blank Mailers | No Printing";

    const paperType =
      this.outerMaterial === "Kraft" || this.outerMaterial === "k"
        ? "kraft"
        : "white";
    this.boxService
      .listPaddedMailerPrices(
        this.selectedPreset?.type?.code ?? "",
        paperType,
        this.quantities,
        this.noPrinting
      )
      .then((priceData: any) => {
        this.pageLoaded = true;

        if (priceData.prices.length > 0 && !this.customQuantityActive) {
          // priceData.prices = priceData.prices.filter(price => price);
          this.selectedPrice = priceData.prices[0];
        }
        this.prices = priceData.prices;
        this.presetMatches = priceData.presetMatches ?? [];
        this.customSizeIncrease = priceData.customSizeIncrease;
        // this.boardTypeList = priceData.box.boxType.subcategory.boardTypes;
        if (!this.boardType) {
          this.boardType = this.boardTypeList[0];
        }

        this.updating = false;
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  listTapePrices() {
    this.quantities = [3, 10, 20, 50, 100];

    let paperTypeName = "white";
    if (this.material === "Kraft") {
      paperTypeName = "kraft";
    }
    if (this.getPaperTypeID(this.material) === "kw") {
      this.paperTypeId = "w";
    }

    this.boxService
      .listTapePrices(paperTypeName, this.quantities)
      .then((priceData: any) => {
        this.pageLoaded = true;

        priceData.prices = priceData.prices.filter((price: any) => price);
        this.selectedPrice = priceData.prices[0];
        this.prices = priceData.prices;
        this.presetMatches = priceData.presetMatches ?? [];
        this.customSizeIncrease = priceData.customSizeIncrease / 100;
        // this.boardTypeList = priceData.box.boxType.subcategory.boardTypes;
        if (!this.boardType) {
          this.boardType = this.boardTypeList[0];
        }

        this.updating = false;
      })
      .catch(() => {
        this.updating = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  listPartitionPrices() {
    this.quantities = [
      200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 5000, 10000,
      12000,
    ];

    let paperTypeName = "white";
    if (this.material === "Kraft") {
      paperTypeName = "kraft";
    }

    this.boxService
      .listPartitionPrices(
        "e",
        this.depth,
        this.length,
        this.cellLength,
        paperTypeName,
        this.quantities,
        this.width,
        this.cellWidth,
        this.lengthSlots,
        this.widthSlots
      )
      .then((priceData: any) => {
        priceData.prices = priceData.prices.filter(
          (x: { pricePerUnit: any }) => x.pricePerUnit
        );
        this.pageLoaded = true;
        if (priceData.prices.length > 0) {
          if (this.standard) {
            this.selectedPrice = priceData.prices[4];
          } else {
            this.selectedPrice = priceData.prices[0];
          }
        }

        this.prices = priceData.prices;
        this.presetMatches = priceData.presetMatches ?? [];
        this.customSizeIncrease = priceData.customSizeIncrease / 100;

        this.violations = priceData.partition.constraintViolations;

        const something = priceData.partition.constraintViolations.find(
          (violation: { warning: any }) => {
            return !violation.warning;
          }
        );

        this.hasViolationErrors = something !== undefined;

        this.updating = false;
      })
      .catch(() => {
        this.updating = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  listTissuePrices() {
    this.quantities = [2, 3, 4, 5, 6, 7, 8];

    // Weight is always 18
    this.boxService
      .listTissuePrices(this.quantities, 18)
      .then((priceData: any) => {
        this.pageLoaded = true;

        priceData.prices = priceData.prices.filter((price: null) => {
          return price != null;
        });

        // To display # of sheets, multiple quantity by 100 because 100 sheets per ream
        priceData.prices.forEach(
          (price: {
            totalSheets: number;
            quantity: number;
            minimumOrder: number;
            maxQuantity: number;
          }) => {
            price.totalSheets = price.quantity * 100;
            price.minimumOrder *= 100;
            price.maxQuantity *= 100;
            price.quantity *= 100;
          }
        );

        this.selectedPrice = priceData.prices[0];
        this.prices = priceData.prices;
        this.presetMatches = priceData.presetMatches ?? [];
        this.customSizeIncrease = priceData.customSizeIncrease / 100;

        this.updating = false;
      })
      .catch(() => {
        this.updating = false;
        this.bsModalService.show(ConfirmationModalComponent);
        // .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  listCrinklePrices() {
    this.quantities = [1, 5, 10];
    this.boxService
      .listCrinklePrices(this.quantities, this.selectedPreset?.label)
      .then((priceData: any) => {
        this.pageLoaded = true;

        priceData.prices = priceData.prices.filter((price: null) => {
          return price != null;
        });
        this.customQuantityActive = false;
        this.selectedPrice = priceData.prices[0];
        this.prices = priceData.prices;
        this.presetMatches = priceData.presetMatches ?? [];
        this.customSizeIncrease = priceData.customSizeIncrease / 1;

        this.updating = false;
      })
      .catch(() => {
        this.updating = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  listLabelPrices() {
    this.quantities = [
      100, 200, 250, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
      5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000,
    ];

    this.boxService
      .listLabelPrices(
        this.quantities,
        this.shape.toLowerCase(),
        "c",
        this.length,
        this.width,
        this.depth
      )
      .then((priceData: any) => {
        this.pageLoaded = true;

        priceData.prices = priceData.prices.filter((price: null) => {
          return price != null;
        });

        this.selectedPrice = priceData.prices[0];
        this.prices = priceData.prices;
        this.presetMatches = priceData.presetMatches ?? [];
        this.customSizeIncrease = priceData.customSizeIncrease / 100;

        this.updating = false;
      })
      .catch(() => {
        this.updating = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  getPaperTypeID(paperType: string) {
    if (paperType === "Kraft") {
      return "k";
    } else if (paperType === "Premium White") {
      return "pkw";
    } else {
      return "kw";
    }
  }

  get boxCode() {
    let code = this.selectedSubcategory?.code;

    if (this.selectedBoardType) {
      code += this.selectedBoardType?.flute ?? "";
    }
    if (
      this.selectedPreset &&
      !this.selectedSubcategory?.allowCustomSizes &&
      this.subcategoryCode !== "tissue"
    ) {
      code = this.selectedPreset.type?.code;
    }

    if (this.subcategoryCode === "tape") {
      code = "tape";
    }
    return code;
  }

  get luxePrinting() {
    return this.selectedPrintType && this.selectedPrintType.id === "luxe";
  }

  updatePrintingOptions() {
    this.printingOptions = [];

    if (this.displayStandard) {
      this.printingOptions.push("Standard | Outside");
      if (this.displayTwoSided) {
        this.printingOptions.push("Standard | Inside", "Standard | 2-Sided");
      }
    }
    if (this.displayLuxe) {
      this.printingOptions.push("Luxe | Outside");
      if (this.displayTwoSided) {
        this.printingOptions.push("Luxe | Inside", "Luxe | 2-Sided");
      }
    }
    if (this.displayNoprint) {
      if (this.isPaddedMailer) {
        this.printingOptions.push("Blank Mailers | No Printing");
      } else {
        this.printingOptions.push("Blank Boxes | No Printing");
      }
    }
    // If printing not selected yet, default to first option.
    if (!this.printing && this.printingOptions.length > 0) {
      this.printing = this.isTest
        ? this.productDetails.print
        : this.printingOptions[0];
      this.printingSelected(this.printing);
    }
  }

  printingSelected(printingType: string | string[] | undefined) {
    this.printing = printingType;

    if (this.printing === "Standard | Outside") {
      // If changing from Luxe -> Standard, reset quantity to default.
      this.insideOnly = false;
      if (this.luxe) {
        this.quantityArrayIndex = 2;
      }
      this.standard = true;
      this.luxe = false;
      this.twoSided = false;
      this.insideOnly = false;
    } else if (this.printing === "Standard | Inside") {
      this.insideOnly = true;
      // If changing from Luxe -> Standard, reset quantity to default.
      if (this.luxe) {
        this.quantityArrayIndex = 2;
      }
      this.standard = true;
      this.luxe = false;
      this.twoSided = false;
      this.insideOnly = true;
    } else if (this.printing === "Blank Boxes | No Printing") {
      this.insideOnly = false;
      this.standard = false;
      this.noprint = true;
      this.luxe = false;
      this.twoSided = false;
      this.insideOnly = false;
    } else if (this.printing === "Standard | 2-Sided") {
      this.insideOnly = false;
      // If changing from Luxe -> Standard, reset quantity to default.
      if (this.luxe) {
        this.quantityArrayIndex = 2;
      }
      this.standard = true;
      this.twoSided = true;
      this.insideOnly = false;
      this.luxe = false;
    } else if (this.printing === "Luxe | Outside") {
      this.insideOnly = false;
      // If changing from Standard -> Luxe, reset quantity to default.
      if (this.standard) {
        this.quantityArrayIndex = 0;
      }
      this.luxe = true;
      this.noprint = false;
      this.standard = false;
      this.twoSided = false;
      this.insideOnly = false;
    } else if (this.printing === "Luxe | Inside") {
      this.insideOnly = true;
      // If changing from Standard -> Luxe, reset quantity to default.
      if (this.standard) {
        this.quantityArrayIndex = 0;
      }
      this.luxe = true;
      this.noprint = false;
      this.standard = false;
      this.twoSided = false;
      this.insideOnly = true;
    } else if (this.printing === "Luxe | 2-Sided") {
      this.insideOnly = false;
      // If changing from Luxe -> Standard, reset quantity to default.
      if (this.luxe) {
        this.quantityArrayIndex = 2;
      }
      this.luxe = true;
      this.twoSided = true;
      this.insideOnly = false;
      this.standard = false;
    }
    this.updateFinishOptions();
    this.updateForm();
  }

  updateMaterialOptions() {
    // Clear out materialOptions
    this.materialOptions = [];
    this.outerMaterialOptions = [];
    this.innerMaterialOptions = [];

    if (this.displayWhite) {
      this.materialOptions.push("Standard White");
      if (this.isPaddedMailer) {
        this.outerMaterialOptions.push("White");
      } else {
        this.outerMaterialOptions.push("Standard White");
      }
      if (
        this.categoryCode !== "Event Boxes" &&
        this.subcategoryCode !== "PIZ"
      ) {
        this.innerMaterialOptions.push("Standard White");
      }
    }

    if (this.displayPremiumWhite) {
      this.materialOptions.push("Premium White");

      // If inner material is premium white, remove this option from outer material
      if (
        this.innerMaterial !== "Premium White" &&
        this.subcategoryCode !== "PIZ"
      ) {
        this.outerMaterialOptions.push("Premium White");
      }
      // If outer material is premium white, remove this option from inner material
      if (
        this.outerMaterial !== "Premium White" &&
        this.categoryCode !== "Event Boxes" &&
        this.subcategoryCode !== "PIZ"
      ) {
        this.innerMaterialOptions.push("Premium White");
      }
    }
    if (this.displayKraft) {
      this.materialOptions.push("Kraft");
      this.outerMaterialOptions.push("Kraft");
      this.innerMaterialOptions.push("Kraft");
    }
    if (this.displaySecondaryKraft && this.subcategoryCode === "PIZ") {
      this.innerMaterialOptions.push("Kraft");
    }
    // If material not selected yet, default to first option.
    if (!this.material && this.materialOptions.length > 0) {
      this.material = this.materialOptions[0];
    }
    // If outer material not selected yet, default to first option.
    if (!this.outerMaterial && this.outerMaterialOptions.length > 0) {
      this.outerMaterial = this.isTest
        ? this.productDetails.outerMaterial
        : this.outerMaterialOptions[0];
    }
    // If inner material not selected yet, default to first option.
    if (!this.innerMaterial && this.innerMaterialOptions.length > 0) {
      this.innerMaterial = this.isTest
        ? this.productDetails.innerMaterial
        : this.innerMaterialOptions[0];
    }

    this.updateFinishOptions();
  }

  updateFinishOptions() {
    if (
      this.luxe &&
      this.printing === "Luxe | Inside" &&
      this.innerMaterial === "Premium White"
    ) {
      this.finish = "Gloss";
      this.finishOptions = ["Gloss"];
    } else if (
      this.luxe &&
      this.printing === "Luxe | Outside" &&
      this.outerMaterial === "Premium White"
    ) {
      this.finish = "Gloss";
      this.finishOptions = ["Gloss"];
    } else if (
      this.luxe &&
      (this.innerMaterial !== "Premium White" ||
        this.outerMaterial !== "Premium White")
    ) {
      this.finish = "Matte";
      this.finishOptions = ["Matte"];
    } else {
      this.finishOptions = ["Matte"];
    }

    // Displays Finish if premium white is selected.

    // Labels should not display gloss finish
    if (this.standard && this.material === "Premium White" && !this.isLabel) {
      this.finishOptions.push("Gloss");
    } else if (
      this.standard &&
      (this.innerMaterial === "Premium White" ||
        this.outerMaterial === "Premium White")
    ) {
      this.finishOptions.push("Gloss");
    } else if (
      this.noprint &&
      (this.innerMaterial === "Premium White" ||
        this.outerMaterial === "Premium White")
    ) {
      this.finishOptions.push("Gloss");
    }
  }

  // Displays custom size fractions
  convertDecimalToFraction(num: any) {
    return Fractions.convertDecimalToFraction(num);
  }

  getWholeNumber(fraction: number) {
    const temp = String(fraction).split(".", 2);
    return parseFloat(temp[0]);
  }

  convertFractionToDecimal(fraction: number) {
    const temp = String(fraction).split(".", 2);
    return parseFloat("0." + temp[1]);
  }

  nextSubcategoryImage() {
    this.selectedImage =
      (this.selectedImage + 1) %
      (this.selectedSubcategory?.images?.length ?? 0);
    this.mainGalleryImageURL =
      this.selectedSubcategory?.images[this.selectedImage].location;
  }

  prevSubcategoryImage() {
    const numImages = this.selectedSubcategory?.images.length ?? 0;
    this.selectedImage = (this.selectedImage + numImages - 1) % numImages;
    this.mainGalleryImageURL =
      this.selectedSubcategory?.images[this.selectedImage].location;
  }

  selectSubcategoryPreset(preset: Preset) {
    if (this.updating) {
      return;
    }
    if (!this.selectedPreset || this.selectedPreset.id !== preset.id) {
      this.selectedPreset = preset;
      this.updateForm();
    }
  }

  selectPresetMatch(preset: Preset) {
    this.length = preset.length;
    this.width = preset.width;
    this.depth = preset.depth;
    this.presetMatches = [];
    this.listPrices();
  }

  addCustomQuantity() {
    if (this.quantity) {
      let valid = true;
      this.quantityError = "";
      const quantity = +this.quantity;

      if (this.luxePrinting) {
        if (quantity === 1) {
          this.quantityError =
            "Minimum order is " + this.box!.boxType?.luxeMinimumOrder;
          valid = false;
        } else {
          if (quantity < (this.box!.boxType?.luxeMinimumOrder ?? 0)) {
            this.quantityError =
              "Minimum order is " + this.box!.boxType?.luxeMinimumOrder;
            valid = false;
          }
        }
      } else {
        if (quantity === 1) {
          valid = this.box!.boxType?.subcategory?.allowCustomSample ?? false;
        } else if (quantity < (this.box!.boxType?.minimumOrder ?? 0)) {
          this.quantityError =
            "Minimum order is " + this.box!.boxType?.minimumOrder;
          valid = false;
        }
      }
      if (quantity > (this.box!.boxType?.maxQuantity ?? 0)) {
        this.quantityError =
          "Maximum order is " + this.box!.boxType?.maxQuantity;
        valid = false;
      }
      if (valid) {
        if (quantity > 0) {
          this.listPrices();
        }
      }
    }
  }

  showModal(template: TemplateRef<any>, templateClass?: any) {
    if (templateClass) {
      this.modal = this.bsModalService.show(template, { class: templateClass });
    } else {
      this.modal = this.bsModalService.show(template, { class: "modal-lg" });
    }
  }

  hide() {
    this.modal!.hide();
  }

  // If product is tissue, multiply be 100 to get the # of sheets instead of reams
  stripText() {
    if (this.isTissue && (this.selectedPrice?.quantity ?? 0 < 100)) {
      this.enteredCustomQuantity = `${this.selectedPrice?.quantity ?? 0 * 100}`;
    } else {
      this.enteredCustomQuantity = `${this.selectedPrice?.quantity ?? 0}`;
    }
  }

  goToUploadDesign(resolveObj: any) {
    const uploadDetails = {
      Origin: "designer",
      Category: this.categoryCode,
      Subcategory: resolveObj.boxType.aeCode,
      Code: this.isLabel ? "label" : resolveObj.boxType.code,
      BoardStrength: this.isOtherProduct
        ? "32"
        : resolveObj.boardStrength.strength,
      ContainerId: resolveObj.containerName,
      kraftType: this.getPaperTypeID(this.outerMaterial),
      secondaryKraftType: this.getPaperTypeID(this.innerMaterial),
      paperType: resolveObj.paperTypeId,
      boxDimensions: resolveObj.dimensions,
      // sample: $stateParams.sample,
      gloss: this.gloss,
      shape: this.shape,
      preset: this.selectedPreset !== undefined,
      luxe: this.luxe,
      options: this.options,
      material: this.material,
      rush: this.expedited,
      twoSided: this.twoSided,
      insideOnly: this.insideOnly,
      quantity: this.selectedPrice?.quantity,
    };

    this.templateLoading = false;
    this.router.navigate(["upload-design", uploadDetails]);
  }

  createAndDownloadTemplate() {
    this.quantity = this.selectedPrice?.quantity ?? 0;
    this.downloadingTemplate = true;

    this.getTemplate(true)
      .then((template) => {
        this.downloadingTemplate = false;

        this.templateDownloadUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(
            `${environment.apiHost}/project/container/${template.container}/${template.templateZip}?download=true&downloadAs=${template.templateZipName}`
          );
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
        this.templateLoading = false;
      });
  }

  async createBasicContainer() {
    if (await this.checkUser()) {
      this.templateLoading = true;
      this.getTemplate()
        .then((response) => {
          this.addCustomerInfo(response)
            .then((customer) => {
              this.createBasicDesign(customer)
                .then((design) => {
                  this.goToBuilder(design);
                })
                .catch(() => {
                  this.bsModalService
                    .show(ConfirmationModalComponent)
                    .content?.showModal(ERROR_BODY, ERROR_TITLE);
                  this.templateLoading = false;
                });
            })
            .catch((_error) => {
              this.productData = {
                Category: this.categoryCode,
                paperType: this.paperTypeId,
                NewDesignID: 0,
                options: this.options,
                length: this.length,
                width: this.width,
                depth: this.depth,
                gloss: this.gloss,
                luxe: this.luxe,
                twoSided: this.twoSided,
                insideOnly: this.insideOnly,
                print: this.printing,
                material: this.material,
                finish: this.finish,
                categoryName: this.categoryName,
                quantity: this.isTissue
                  ? this.selectedPrice?.quantity ?? 0 / 100
                  : this.selectedPrice?.quantity,
                outerMaterial: this.outerMaterial,
                innerMaterial: this.innerMaterial,
                continuedProject: false,
                shape: this.shape,
                rush: this.expedited,
                customSizeEntered: this.customSizeEntered,
                customSizeActive: this.customSizeActive,
                lengthWhole: this.lengthWhole,
                widthWhole: this.widthWhole,
                depthWhole: this.depthWhole,
                lengthFraction: this.lengthFraction,
                widthFraction: this.widthFraction,
                depthFraction: this.depthFraction,
                username: "test@companybox.com",
                password: "Password1!",
              };
              this.showModal(this.connectedModal, "connectionModal");
              this.templateLoading = false;
            });
        })
        .catch(() => {
          this.templateLoading = false;
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    }
  }

  async createAdvancedContainer() {
    if (await this.checkUser()) {
      this.templateLoading = true;
      this.getTemplate()
        .then((response) => {
          this.addCustomerInfo(response)
            .then((customer) => {
              this.createAdvancedDesign(customer)
                .then((design) => {
                  this.templateLoading = false;
                  this.goToUploadDesign(design);
                })
                .catch(() => {
                  this.templateLoading = false;
                  this.bsModalService
                    .show(ConfirmationModalComponent)
                    .content?.showModal(ERROR_BODY, ERROR_TITLE);
                });
            })
            .catch((_error) => {
              this.productData = {
                Category: this.categoryCode,
                paperType: this.paperTypeId,
                NewDesignID: 0,
                options: this.options,
                length: this.length,
                width: this.width,
                depth: this.depth,
                gloss: this.gloss,
                luxe: this.luxe,
                twoSided: this.twoSided,
                insideOnly: this.insideOnly,
                print: this.printing,
                material: this.material,
                finish: this.finish,
                categoryName: this.categoryName,
                quantity: this.isTissue
                  ? this.selectedPrice?.quantity ?? 0 / 100
                  : this.selectedPrice?.quantity,
                outerMaterial: this.outerMaterial,
                innerMaterial: this.innerMaterial,
                continuedProject: false,
                shape: this.shape,
                rush: this.expedited,
                customSizeEntered: this.customSizeEntered,
                customSizeActive: this.customSizeActive,
                lengthWhole: this.lengthWhole,
                widthWhole: this.widthWhole,
                depthWhole: this.depthWhole,
                lengthFraction: this.lengthFraction,
                widthFraction: this.widthFraction,
                depthFraction: this.depthFraction,
                username: "test@companybox.com",
                password: "Password1!",
              };
              this.showModal(this.connectedModal, "connectionModal");
              this.templateLoading = false;
            });
        })
        .catch(() => {
          this.templateLoading = false;
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    }
  }

  addPartitionToCart() {
    const boardType = "e";
    const paperType =
      this.material.toLowerCase() === "kraft" ? "kraft" : "white";
    console.log(paperType);
    this.checkoutService
      .createPartition(
        paperType,
        boardType,
        this.length,
        this.width,
        this.depth,
        this.cellLength,
        this.cellWidth,
        this.lengthSlots,
        this.widthSlots,
        this.selectedPrice?.quantity,
        false
      )
      .then((response) => {
        this.boxService
          .getType("PARTE")
          .then((type) => {
            const lineItem = this.checkoutService.createPartitionLineItem(
              response,
              type
            );

            this.checkoutService
              .addToCart(lineItem)
              .then(() => {
                this.router.navigate(["/cart"]);
              })
              .catch(() => {
                this.templateLoading = false;
                this.bsModalService
                  .show(ConfirmationModalComponent)
                  .content?.showModal(ERROR_BODY, ERROR_TITLE);
              });
          })
          .catch(() => {
            this.templateLoading = false;
            this.bsModalService
              .show(ConfirmationModalComponent)
              .content?.showModal(ERROR_BODY, ERROR_TITLE);
          });
      })
      .catch(() => {
        this.templateLoading = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  addCrinkleToCart() {
    this.checkoutService
      .createCrinkle(
        this.selectedPrice?.quantity ?? 0,
        this.selectedPreset?.label ?? ""
      )
      .then((response) => {
        this.boxService
          .getType("CKLP")
          .then((type) => {
            const lineItem = this.checkoutService.createCrinkleLineItem(
              response,
              type
            );

            this.checkoutService
              .addToCart(lineItem)
              .then(() => {
                this.router.navigate(["/cart"]);
              })
              .catch(() => {
                this.templateLoading = false;
                this.bsModalService
                  .show(ConfirmationModalComponent)
                  .content?.showModal(ERROR_BODY, ERROR_TITLE);
              });
          })
          .catch(() => {
            this.templateLoading = false;
            this.bsModalService
              .show(ConfirmationModalComponent)
              .content?.showModal(ERROR_BODY, ERROR_TITLE);
          });
      })
      .catch(() => {
        this.templateLoading = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  addToCart() {
    this.modal?.hide();
    if (
      this.getPaperTypeID(this.outerMaterial) === "pkw" ||
      this.getPaperTypeID(this.innerMaterial) === "pkw"
    ) {
      this.paperTypeId = "pkw";
    } else if (this.categoryCode === "Event Boxes") {
      this.paperTypeId = this.getPaperTypeID(this.outerMaterial);
    } else if (
      this.getPaperTypeID(this.outerMaterial) === "kw" &&
      this.getPaperTypeID(this.innerMaterial) === "k"
    ) {
      this.paperTypeId = "kw";
    } else if (
      this.getPaperTypeID(this.outerMaterial) === "k" &&
      this.getPaperTypeID(this.innerMaterial) === "kw"
    ) {
      this.paperTypeId = "kw";
    } else if (
      this.getPaperTypeID(this.outerMaterial) === "k" ||
      this.getPaperTypeID(this.innerMaterial) === "k"
    ) {
      this.paperTypeId = "k";
    } else {
      this.paperTypeId = "kw";
    }
    this.gloss = this.finish === "Gloss";
    if (this.subcategoryCode.startsWith("SNAP2S")) {
      this.options = "superseal";
    }

    this.templateLoading = true;
    this.getTemplate()
      .then((response) => {
        this.boxService
          .getType(this.boxCode!)
          .then((type) => {
            this.checkoutService
              .createNoprint(
                response.containerName,
                this.boxCode,
                this.boardStrength?.strength,
                this.getPaperTypeID(this.outerMaterial),
                this.getPaperTypeID(this.innerMaterial),
                this.gloss,
                this.luxe,
                undefined,
                this.length,
                this.width,
                this.depth,
                this.preset,
                this.twoSided,
                this.insideOnly,
                this.expedited,
                this.options,
                this.quantity,
                this.noprint
              )
              .then((responses) => {
                responses.quantity = this.selectedPrice?.quantity;
                const lineItem = this.checkoutService.createLineItem(
                  responses.containerName,
                  type,
                  responses
                );
                // this.boxService.getType(this.boxCode).then(type => {
                // const lineItem = this.checkoutService.createNoprintLineItem( response, type);
                this.checkoutService
                  .addToCart(lineItem)
                  .then(() => {
                    this.router.navigate(["/cart"]);
                  })
                  .catch(() => {
                    this.templateLoading = false;
                    this.bsModalService
                      .show(ConfirmationModalComponent)
                      .content?.showModal(ERROR_BODY, ERROR_TITLE);
                  });
              })
              .catch(() => {
                this.templateLoading = false;
                this.bsModalService
                  .show(ConfirmationModalComponent)
                  .content?.showModal(ERROR_BODY, ERROR_TITLE);
              });
          })
          .catch(() => {
            this.templateLoading = false;
            this.bsModalService
              .show(ConfirmationModalComponent)
              .content?.showModal(ERROR_BODY, ERROR_TITLE);
          });
      })
      .catch(() => {
        this.templateLoading = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  async saveProject() {
    let template;
    await this.getTemplate()
      .then((response) => {
        template = response;
      })
      .catch(() => {
        this.templateLoading = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.checkUser();

    this.design = {
      // customerId: this.user.shopifyId,
      container: template!.containerName,
      type: "BASIC",
      outside: "",
      inside: "",
      twoSided: this.twoSided,
      insideOnly: this.insideOnly,
      name: "My Box",
      dimensions: template!.dimensions.replace(/\s/g, ""),
      subcategory: template!.boxType.subcategory,
      boardType: template!.boardType,
      boardStrength: template!.boardStrength.strength,
      kraftType: this.getPaperTypeID(this.material),
      preset: template!.preset,
      gloss: this.gloss,
      luxe: this.luxe,
      quantity: this.quantity,
      optionsArray: [],
      options: "",
    };

    this.designService
      .getDesign(this.user!.id!, template!.containerName)
      .then((response) => {
        this.design = { ...response, ...this.design };
        this.designService
          .updateDesign(this.user!.id!, this.design)
          .then(() => {
            this.router.navigate(["/continue-project"]);
          })
          .catch(() => {
            this.bsModalService
              .show(ConfirmationModalComponent)
              .content?.showModal(ERROR_BODY, ERROR_TITLE);
          });
      })
      .catch((error) => {
        if (error.status === 404) {
          this.designService
            .createDesign(this.user!.id!, this.design)
            .then((_response) => {
              this.router.navigate(["/continue-project"]);
            })
            .catch(() => {
              this.bsModalService
                .show(ConfirmationModalComponent)
                .content?.showModal(ERROR_BODY, ERROR_TITLE);
            });
        }
      });
  }

  createAdvancedDesign(resolveObj: any) {
    const design = {
      customerId: resolveObj.customerId,
      container: resolveObj.containerName,
      type: "ADVANCED",
      name: "My Box",
      dimensions: resolveObj.dimensions.replace(/\s/g, ""),
      boardType: resolveObj.boxCode,
      boardStrength: this.isOtherProduct
        ? "32"
        : resolveObj.boardStrength.strength,
      kraftType: this.getPaperTypeID(this.outerMaterial),
      secondaryKraftType: this.getPaperTypeID(this.innerMaterial),
      preset: resolveObj.preset,
      filename: new Date().getTime().toString(), // Per ESKO's request, use epoch as filename.
      gloss: this.gloss ? this.gloss : "false",
      luxe: this.luxe,
      rush: this.expedited,
      twoSided: this.twoSided,
      insideOnly: this.insideOnly,
    };
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.designService
        .createDesign(resolveObj.userId, design)
        .then((savedDesign) => {
          resolveObj.design = savedDesign;
          resolve(resolveObj);
        })
        .catch((error) => {
          console.log("Unable to create design", error);
          reject(error);
        });
    });
  }

  createBasicDesign(resolveObj: any) {
    const design = {
      customerId: resolveObj.customerId,
      container: resolveObj.containerName,
      type: "ADVANCED",
      name: "My Box",
      dimensions: resolveObj.dimensions.replace(/\s/g, ""),
      boardType: resolveObj.boxCode,
      boardStrength: this.isOtherProduct
        ? "32"
        : resolveObj.boardStrength.strength,
      kraftType: this.getPaperTypeID(this.outerMaterial),
      secondaryKraftType: this.getPaperTypeID(this.innerMaterial),
      preset: resolveObj.preset,
      filename: new Date().getTime().toString(), // Per ESKO's request, use epoch as filename.
      gloss: this.gloss ? this.gloss : "false",
      luxe: this.luxe,
      rush: this.expedited,
      twoSided: this.twoSided,
      insideOnly: this.insideOnly,
    };
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.designService
        .createDesign(resolveObj.userId, design)
        .then((savedDesign) => {
          resolveObj.basicdesign = savedDesign;
          resolve(resolveObj);
        })
        .catch((error) => {
          console.log("Unable to create design", error);
          reject(error);
        });
    });
  }

  addCustomerInfo(resolveObj: any) {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          resolveObj.userId = user.id;
          // resolveObj.customerId = user.shopifyId;
          resolveObj.firstName = user.firstName;
          resolveObj.lastName = user.lastName;
          // resolveObj.email = user.username;
          resolve(resolveObj);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getTemplate(downloadOnly = false): Promise<any> {
    if (this.subcategoryCode.toLowerCase() === "tape") {
      this.width = 3;
    }

    const dimensions = this.length + " x " + this.width + " x " + this.depth;

    const product: any = {
      boardType: this.boardType ?? "",
      boxCode: this.boxCode ?? "",
      kraftType: this.getPaperTypeID(this.material),
      boardStrength: this.selectedBoardStrength,
      boardStrengthName: this.selectedBoardStrength
        ? this.selectedBoardStrength?.strength ??
          "" + this.selectedBoardType?.flute ??
          ""
        : null,
      flute:
        this.isTissue || this.isLabel
          ? "z"
          : this.selectedBoardType?.flute ?? "",
      boxLength: this.length,
      boxWidth: this.width,
      boxDepth: this.depth,
      dimensions,
      preset: this.selectedPreset !== undefined,
      boxName:
        this.subcategoryCode.toLowerCase() === "dhc"
          ? this.box?.boxType?.name
          : this.isTape || this.isTissue
          ? this.type
          : this.selectedPreset?.type?.name,
      boxType: undefined,
      boardTypeName: undefined,
      templateName: undefined,
      containerName: undefined,
    };

    return new Promise((resolve, reject) => {
      this.boxService
        .getType(product.boxCode)
        .then((boxType) => {
          product.boxType = boxType;

          if (product.boxCode && product.boxCode.startsWith("SNAP2")) {
            product.boxType.aeCode = product.boxType.code;
            if (this.selectedVariant) {
              if (
                this.selectedVariant.name === "Super Seal" &&
                this.length === 6
              ) {
                product.boxType.aeCode =
                  product.boxType.code.slice(0, 5) +
                  "S662" +
                  product.boxType.code.slice(5, 6);
              } else if (
                this.selectedVariant.name === "Super Seal" &&
                this.length === 9
              ) {
                product.boxType.aeCode =
                  product.boxType.code.slice(0, 5) +
                  "S963" +
                  product.boxType.code.slice(5, 6);
              } else if (
                this.selectedVariant.name === "Super Seal" &&
                this.length === 12
              ) {
                product.boxType.aeCode =
                  product.boxType.code.slice(0, 5) +
                  "S1294" +
                  product.boxType.code.slice(5, 6);
              } else if (
                this.selectedVariant.name === "Returnable Super Seal" &&
                this.length === 9 &&
                this.depth === 3.5
              ) {
                product.boxType.aeCode =
                  product.boxType.code.slice(0, 5) +
                  "S9635" +
                  product.boxType.code.slice(5, 6);
              } else if (
                this.selectedVariant.name === "Returnable Super Seal" &&
                this.length === 6 &&
                this.depth === 3.5
              ) {
                product.boxType.aeCode =
                  product.boxType.code.slice(0, 5) +
                  "S6635" +
                  product.boxType.code.slice(5, 6);
              } else if (
                this.selectedVariant.name === "Returnable Super Seal" &&
                this.length === 12
              ) {
                product.boxType.aeCode =
                  product.boxType.code.slice(0, 5) +
                  "S1294" +
                  product.boxType.code.slice(5, 6);
              }
            }
          }

          const dimensionsArr = product.dimensions
            .replace(/&quot;/g, "")
            .split("x");
          const lengthName =
            dimensionsArr[0].replace(/ /g, "").replace(".", "_") + "_";
          const widthName =
            dimensionsArr[1].replace(/ /g, "").replace(".", "_") + "_";
          const depthName = dimensionsArr[2]
            .replace(/ /g, "")
            .replace(".", "_");
          product.boardTypeName =
            this.isTape || this.isTissue || this.isLabel
              ? null
              : boxType.boardTypeCode +
                (product.boardStrength ? "-" + product.boardStrength.code : "");
          product.flute =
            this.isTape || this.isTissue || this.isLabel
              ? "zflute"
              : boxType.boardType?.code;
          product.templateName =
            boxType.aeCode +
            "-" +
            product.boardTypeName +
            "-" +
            lengthName.trim() +
            widthName.trim() +
            depthName.trim();

          if (this.isTape) {
            this.containerService
              .getTapeTemplate(product)
              .then((response) => {
                product.containerName = response.container;
                product.templateName = response.template;
                if (downloadOnly) {
                  resolve(response);
                } else {
                  resolve(product);
                }
              })
              .catch((error) => {
                reject(error);
              });
          } else if (this.isTissue) {
            this.containerService
              .getTissueTemplate(product)
              .then((response) => {
                product.containerName = response.container;
                product.templateName = response.template;
                resolve(downloadOnly ? response : product);
              })
              .catch((error) => {
                reject(error);
              });
          } else if (this.isLabel) {
            let labelType;
            // Have to get the shape from the selected preset's label
            if (this.shape.toLowerCase().includes("circle")) {
              labelType = "LBLCIR";
            } else if (this.shape.toLowerCase().includes("oval")) {
              labelType = "LBLOVL" + this.length + this.width;
            } else {
              labelType = "LBLREC";
            }

            this.containerService
              .getLabelTemplate(product, labelType)
              .then((response) => {
                product.containerName = response.container;
                product.templateName = response.template;
                resolve(downloadOnly ? response : product);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            this.containerService
              .getTemplate(product)
              .then((response) => {
                product.containerName = response.container;
                product.templateName = response.template;
                resolve(downloadOnly ? response : product);
              })
              .catch((error) => {
                reject(error);
              });
          }
        })
        .catch((reason) => reject(reason));
    });
  }

  async goToBuilder(product: any) {
    const productDetails = {
      Category: this.categoryCode,
      Subcategory: this.subcategoryCode,
      Code: this.isLabel ? "label" : product.boxType.code,
      BoardStrength: product.boardStrength
        ? product.boardStrength.strength
        : null,
      ContainerName: product.containerName,
      paperType: this.paperTypeId,
      NewDesignID: 0,
      BoxDimensions: product.dimensions,
      options: this.options,
      Preset: product.preset,
      gloss: this.gloss,
      luxe: this.luxe,
      twoSided: this.twoSided,
      insideOnly: this.insideOnly,
      print: this.printing,
      material: this.material,
      finish: this.finish,
      categoryName: this.categoryName
        ? this.categoryName
        : this.subcategoryCode.toLowerCase() === "tape"
        ? "Tape"
        : this.subcategoryCode.toLowerCase() === "lbl"
        ? "Label"
        : "Tissue",
      quantity: this.isTissue
        ? this.selectedPrice?.quantity ?? 0 / 100
        : this.selectedPrice?.quantity,
      outerMaterial: this.outerMaterial,
      innerMaterial: this.innerMaterial,
      continuedProject: false,
      shape: this.shape,
      rush: this.expedited,
    };
    this.navigationService.setContainerName(product.containerName);
    this.navigationService.setProductDetails(productDetails);
    sessionStorage.setItem("productDetails", JSON.stringify(productDetails));

    this.router.navigate([
      "/design",
      this.categoryCode,
      this.subcategoryCode
    ]);
  }

  async checkUser(): Promise<boolean> {
    return await this.userService
      .getCurrentUser()
      .then((response) => {
        this.user = response;
        // temporary session won't have a `userId`
        if (this.user && this.user.id) return true;
        // not a valid logged in user, show modal
        this.showModal(this.connectedModal, "connectionModal");
        return false;
      })
      .catch((error) => {
        console.log(error);
        this.showModal(this.connectedModal, "connectionModal");
        return false;
      });
  }

  async getCurrentUser(): Promise<any> {
    let item = sessionStorage.getItem("sessionData");
    if (item == null) {
      return Promise.reject("No session data found");
    }

    let sessionData = JSON.parse(<string>item);
    return Promise.resolve(sessionData);
  }
}
