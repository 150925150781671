import {AuditedSerializable} from './base';
import * as moment from 'moment';

export class FormattedAddress extends AuditedSerializable<FormattedAddress> {
    name: string | undefined;
    formatted: string[] = [];
    formattedArea: string | undefined;

    override deserialize(input: any): FormattedAddress {
        super.deserialize(input);
        this.name = input.name;
        (input.formatted as string[]).forEach((value) => {
            this.formatted.push(value);
        });
        this.formattedArea = input.formattedArea;
        return this;
    }
}

export class Address extends AuditedSerializable<Address> {
    name: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    phoneNumber: string | undefined;
    address1: string | undefined;
    address2: string | undefined;
    city: string | undefined;
    zip: string | undefined;
    company: string | undefined;
    country: string | undefined;
    countryCode: string | undefined;
    state: string | undefined;
    stateCode: string | undefined;
    contactEmail: string | undefined;

    override deserialize(input: any): Address {
        super.deserialize(input);
        this.name = input.name;
        this.firstName = input.first_name;
        this.lastName = input.last_name;
        this.phoneNumber = input.phone;
        this.address1 = input.address1;
        this.address2 = input.address2;
        this.city = input.city;
        this.zip = input.zip;
        this.company = input.company;
        this.country = input.country;
        this.countryCode = input.country_code;
        this.state = input.state;
        this.stateCode = input.state_code;
        this.contactEmail = input.contact_email;
        return this;
    }
}

export class User extends AuditedSerializable<User> {
    static EMPTY: User = new User();

    userId: number | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    password: string = ""; // Only used when setting password
    phone: string | undefined;
    validUntil: Date | undefined;

    override deserialize(input: any): User {
        super.deserialize(input);
        this.userId = input.userId;
        this.firstName = input.firstName;
        this.lastName = input.lastName;
        if (input.createdAt) {
            this.validUntil = moment.utc(input.validUntil).toDate();
        }
        return this;
    }
}

