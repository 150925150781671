<div class="modal-container">
  <button type="button" class="close-button pull-right" (click)="hide()">
    <img src="assets/cb/images/close-icon.png" alt="Close" />
  </button>
  <div class="left-col">
    <div class="information-container">
      <h4>Shape Library</h4>
      <!-- <mat-form-field>
      <input matInput type="text">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field> -->
    <ul>
      @for (collection of shapeCollections; track trackByFn($index, collection)) {
        <li (click)="scroll('shape-collection-' + collection.id)">
        {{collection.name}} </li>
      }
    </ul>
  </div>
</div>
<div class="pattern-container">
  @for (collection of shapeCollections; track trackByFn($index, collection)) {
    <div>
      <h5 id="shape-collection-{{collection.id}}">{{collection.name | uppercase}}</h5>
      <div class="patterns-list">
        @for (shape of getCollectionShapes(collection.id); track trackByFn($index, shape)) {
          <div>
            <!-- <app-canvas-shape [shape]="shape" [location]="'library'" (click)="addShape(shape)">
            </app-canvas-shape> -->
          </div>
        }
      </div>
    </div>
  }
</div>
</div>