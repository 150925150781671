<div class="modal-container">
  <div class="modal-body">
    <div class="container-fluid">
      <h2>Connected is Better</h2>
      <div class="p-4">
        This experience requires you to create an account or log in with your
        existing FedEx account. This will ensure a better experience designing,
        saving for later, and ordering.
      </div>
      <div class="button-div">
        <form name="register" ngNoForm [action]="loginUrl" method="post">
          <button type="submit" class="orange-outline">
            CREATE A USER ID
          </button>
          <input name="form_key" type="hidden" [value]="formKey">
          <input name="action_type" type="hidden" value="register">
          <input name="product_config_data" type="hidden" [value]="productConfigData">
          <input name="configuration_data" type="hidden" [value]="configurationData">
        </form>
        <form name="login" ngNoForm [action]="loginUrl" method="post">
          <button type="submit" class="orange-submit">
            LOG IN
          </button>
          <input name="form_key" type="hidden" [value]="formKey">
          <input name="action_type" type="hidden" value="login">
          <input name="product_config_data" type="hidden" [value]="productConfigData">
          <input name="configuration_data" type="hidden" [value]="configurationData">
        </form>
      </div>
    </div>
  </div>
</div>
