<div id="smallScreenWarning" class="small-screen-warning">
  <div class="empty-icon">
    <i class="fal fa-desktop" data-fa-transform="grow-100"></i>
  </div>
  <h1>Hey Designer, make your screen bigger please.</h1>
  <p>Our Box Designer experience requires a larger screen size. Please enlarge your browser window. If
    you need assistance give us a call at <b>877.926.3223</b>.</p>
  </div>

  <div id="pageContainer" class="page-container">
    @if (pageLoading) {
      <app-loading-modal [content]="isContinuing ? 'loading Review Page' : 'loading template'" [showLoadingBar]="true"></app-loading-modal>
    }
    <div [style.visibility]="pageLoading ? 'hidden' : 'initial'" class="content-container">
      <div [ngClass]="{'highlight': helpSection=='elementExplanation' }" class="vertical-menu">

        <div class="upper-menu">
          <div class="section-title top" id="designText">
            <span>DESIGN</span>
          </div>
          @for (category of designCategories.slice(0, 4); track trackByFn(i, category); let i = $index) {
            <div [class.second-menu-active]="activeButton[i]">
              <div [ngClass]="getClassName(category)" (click)="changeActiveMenu(category, i)">
                <a></a>
                <span>{{category}}</span>
              </div>
            </div>
          }
          <div class="category review-details" (click)="changeActiveMenu('Review Details', 5)" [class.second-menu-active]="activeButton[5]">
            <a></a>
            <span>Material <br>& Finish</span>
          </div>
        </div>

        <div class="help-menu">
          <img class="horizontal-bar" src="assets/cb/images/designer/divider_line_white.png">
          <div class="section-title">
            <span>HELP</span>
          </div>
          <div id="Help" class="category vid"
            [class.second-menu-active]="activeButton[7]" (click)="!helpView ? changeActiveMenu1('Help', 7) : false">
            <a></a>
            <span>Tutorial</span>
          </div>
          <div id="Help" class="category help" (click)="!helpView ? changeActiveMenu('Help', 6) : false"
            [class.second-menu-active]="activeButton[6]">
            <a></a>
            <span>Guided<br>Tour</span>
          </div>
        </div>
      </div>

      @if (activeButton[0]) {
        <div class="second-menu" [ngClass]="{'highlight': helpSection=='patternExplanation' }">
          <div class="menu-header">
            <h3>Does your design need<br> a background?</h3>
            <div class="brown-bar"></div>
          </div>
          <div class="pattern-container">
            @for (collection of patternCollections; track trackByFn($index, collection)) {
              <div>
                <h5>{{collection.name | uppercase}}</h5>
                <div class="patterns-list">
                  @for (pattern of getCollectionPatterns(collection.id); track trackByFn($index, pattern)) {
                    <div class="thumbnail-container">
                      <img [src]="pattern.previewUrl" (click)="addPattern(pattern)">
                    </div>
                  }
                </div>
              </div>
            }
          </div>
          <button (click)="showModal(patternLibrary, 'view-entire-library')" class="big-button">VIEW ENTIRE LIBRARY</button>
          <a class="bottom-link" (click)="changeActiveMenu('', -1)">Collapse</a>
        </div>
      }

      @if (activeButton[1]) {
        <div class="second-menu" [ngClass]="{'highlight': helpSection=='patternExplanation' }">
          <div class="menu-header">
            <h3>Does your design need special shapes?</h3>
            <div class="brown-bar"></div>
          </div>
          <div class="shape-container">
            @for (collection of shapeCollections; track trackByFn($index, collection)) {
              <div>
                <h5>{{collection.name | uppercase}}</h5>
                <div class="shape-list">
                  @for (shape of getCollectionShapes(collection.id); track trackByFn($index, shape)) {
                    <div>
                      <!-- <app-canvas-shape [shape]="shape" [location]="'menu'" (click)="addShape(shape)"></app-canvas-shape> -->
                    </div>
                  }
                </div>
              </div>
            }
          </div>
          <button (click)="showModal(shapeLibrary, 'view-entire-library')" class="big-button">VIEW ENTIRE LIBRARY</button>
          <a class="bottom-link" (click)="changeActiveMenu('', -1)">Collapse</a>
        </div>
      }

      @if (activeButton[2]) {
        <div class="second-menu text-menu">
          <div class="menu-header">
            <h3>Does your design<br> need text?</h3>
            <div class="brown-bar"></div>
          </div>
          <p>Personalize your design by adding custom text!</p>
          <button (click)="addText()" (mouseenter)="showWhiteLogo('addText')" (mouseleave)="showRedLogo('addText')"> <img
          [src]="addTextSrc">ADD TEXT</button>
          <a class="bottom-link" (click)="changeActiveMenu('', -1)">Collapse</a>
        </div>
      }

      @if (activeButton[3]) {
        <div class="second-menu">
          <div class="menu-header">
            <h3>Does your design need images or logos?</h3>
            <div class="brown-bar"></div>
          </div>
          <button (click)="showModal(uploadImage)" class="image-menu-button" (mouseenter)="showWhiteLogo('addImage')"
            (mouseleave)="showRedLogo('addImage')">
            <img [src]="addImageSrc">ADD IMAGE</button>
            <button (click)="showModal(uploadPixabay)" class="image-menu-button" (mouseenter)="showWhiteLogo('addPixabay')"
              (mouseleave)="showRedLogo('addPixabay')"> <img [src]="addPixabaySrc">ADD PIXABAY</button>
              <h5>YOUR UPLOADED IMAGES</h5>
              @if (images.length != 0) {
                <div class="pattern-container image-container">
                  @if (updatingImages) {
                    <div>
                      <img src="assets/cb/images/loading_box.gif">
                    </div>
                  }
                  @if (!updatingImages) {
                    <div class="patterns-list">
                      @for (image of images; track trackByFn($index, image)) {
                        <div class="image-container" (click)="addImage(image)"
                          [ngStyle]="{background: 'url(' + image.src + ')'}">
                          <div (click)="updatingImages = true; removeImage(image)" class="background-hover-button">
                            <div class="background-hover-button-content"></div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
              <a class="bottom-link" (click)="changeActiveMenu('', -1)">Collapse</a>
            </div>
          }

          @if (activeButton[5]) {
            <div class="second-menu">
              <div class="menu-header">
                <h3>Review the details you've chosen</h3>
                <div class="brown-bar"></div>
              </div>
              @if (!isTape && !islabel && !isTissue && !isPaddedMailer) {
                <h5 class="box-details-title">My Box Details</h5>
              }
              @if (isTape) {
                <h5 class="box-details-title">My Tape Details</h5>
              }
              @if (islabel) {
                <h5 class="box-details-title">My Label Details</h5>
              }
              @if (isTissue) {
                <h5 class="box-details-title">My Tissue Details</h5>
              }
              @if (isPaddedMailer) {
                <h5 class="box-details-title">My Mailer Details</h5>
              }
              <div class="box-details">
                @if (!isPaddedMailer) {
                  <p [innerHTML]="productDetails.categoryName"></p>
                  @if (selectedPreset.label) {
                    <p>{{selectedPreset.label}}</p>
                  }
                  @if (!selectedPreset.label) {
                    <p>{{productDetails.BoxDimensions}} (in)</p>
                  }
                }
                @if (isPaddedMailer) {
                  <p>{{selectedPreset.label}}</p>
                }
              </div>
              <form>
                <div class="form-group">
                  <mat-label>Printing </mat-label><br>
                  <mat-select name="printing" disableOptionCentering="true" [(ngModel)]="printing">
                    @for (printing of printingOptions; track trackByFn($index, printing)) {
                      <mat-option (click)="printingSelected(printing)"
                      [value]="printing">{{printing}}</mat-option>
                    }
                  </mat-select>
                </div>
                @if (categoryCode !=='Event Boxes' && !isOtherProduct && !isPaddedMailer) {
                  <div class="form-group">
                    <mat-label>Material</mat-label><br>
                    <mat-select name="material" disableOptionCentering="true" [(ngModel)]="material" (selectionChange)="updateForm()">
                      @for (material of materialOptions; track trackByFn($index, material)) {
                        <mat-option [value]="material">{{material}}</mat-option>
                      }
                    </mat-select>
                  </div>
                }
                @if (!isOtherProduct) {
                  <div class="form-group">
                    <mat-label>@if (!isPaddedMailer) {
                      <span>Outer </span>
                    }Material</mat-label><br>
                    <mat-select name="outerMaterial" disableOptionCentering="true" [(ngModel)]="outerMaterial" (selectionChange)="updateForm()">
                      @for (outerMaterial of outerMaterialOptions; track trackByFn($index, outerMaterial)) {
                        <mat-option [value]="outerMaterial">{{outerMaterial}}</mat-option>
                      }
                    </mat-select>
                  </div>
                }
                @if (!isPaddedMailer && !isOtherProduct) {
                  <div class="form-group">
                    <mat-label>Inner Material</mat-label><br>
                    <mat-select name="innerMaterial" disableOptionCentering="true" [(ngModel)]="innerMaterial" (selectionChange)="updateForm()">
                      @for (innerMaterial of innerMaterialOptions; track trackByFn($index, innerMaterial)) {
                        <mat-option [value]="innerMaterial">{{innerMaterial}}</mat-option>
                      }
                    </mat-select>
                  </div>
                }
                @if (!isPaddedMailer && !isOtherProduct) {
                  <div class="form-group">
                    <mat-label>Finish</mat-label><br>
                    <mat-select name="finish" disableOptionCentering="true" [(ngModel)]="finish" (selectionChange)="updateForm()">
                      @for (finish of finishOptions; track trackByFn($index, finish)) {
                        <mat-option [value]="finish">{{finish}}</mat-option>
                      }
                    </mat-select>
                  </div>
                }
              </form>
              <a class="bottom-link" (click)="changeActiveMenu('', -1)">Collapse</a>
            </div>
          }

          @if (activeButton[6]) {
            <div class="second-menu help-menu">
              <div class="arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
              <div class="text-container">
                <h3>NEED A LITTLE HELP?</h3>
                <p>Click here for a guided tour.</p>
                <div class="help-options">
                  <a (click)="changeActiveMenu('', -2)" class="exit-tour">NO THANKS</a>
                  <a (click)="changeActiveMenu('', -1); showHelpSection('elementExplanation')" class="continue-tour">START
                  TOUR</a>
                </div>
              </div>
            </div>
          }
          @if (activeButton[7]) {
            <div class="second-menu help-menu">
              <div class="arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
              <div class="text-container">
                <h3>NEED A LITTLE HELP?</h3>
                <p>Click here to watch the tutorial.</p>
                <div class="help-options">
                  <a (click)="changeActiveMenu1('', -2)" class="exit-tour">CLOSE</a>
                  <a (click)="showModal(Video)" class="continue-tour">START VIDEO</a>
                </div>
              </div>
            </div>
          }
          <div
            [ngClass]="{'highlight': helpSection=='toolbarExplanation' || helpSection=='designAreaExplanation' || helpSection=='customBoxExplanation' || helpSection=='viewerExplanation' }"
            class="webgl-content">
            <div #gameContainer id="gameContainer"></div>
          </div>
        </div>

        <!------------------------------------------------ BEGINNNING OF MODALS ------------------------------------------------>

        <ng-template #uploadImage>
          <app-image-upload [containerID]="containerID" [user]="user" (imageAdded)='getImages(); hide()' (hideModal)="hide()">
          </app-image-upload>
        </ng-template>

        <ng-template #uploadPixabay>
          <app-pixabay-modal [containerId]="containerID" [user]="user" (pixabayAdded)='getImages(); hide()'>
          </app-pixabay-modal>
        </ng-template>

        <ng-template #patternLibrary>
          <app-pattern-library [patterns]="patterns" [patternCollections]="patternCollections"
            (patternSelected)="addPattern($event); hide();" (hideModal)="hide()">
          </app-pattern-library>
        </ng-template>

        <ng-template #shapeLibrary>
          <app-shape-library [shapes]="shapes" [shapeCollections]="shapeCollections"
          (shapeSelected)="addShape($event); hide();" (hideModal)="hide()"></app-shape-library>
        </ng-template>
        <ng-template #leavingPageWarning>
          <div class="modal-header pb-0 border-0">
            <div class="modal-title page-title eyebrow">
              <h1>Have you forgotten something?</h1>
              <div class="brown-bar"></div>
            </div>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <p>Before you leave, save your progress, or changes made to your design will be lost.</p>
            </div>
            <div class="multi-button-container">
              <button type="button" class="white-submit" (click)="navigationChoice(true)">Continue</button>
              <button type="button" class="white-submit" (click)="navigationChoice(false)">Cancel</button>
            </div>
          </div>
        </ng-template>

        <ng-template #helpModal>
          <div class="modal-header pb-0 border-0">
            <div class="modal-title page-title eyebrow">
              <h1>Keyboard Shortcuts</h1>
              <div class="brown-bar"></div>
            </div>
          </div>
          <div class="modal-body">
            <div class="container-fluid help-container">
              <div class="col">
                <ul>
                  <li><b>Shift + Arrows:</b> move the selected object</li>
                  <li><b>Option/Alt + Arrows:</b> resize the selected object</li>
                  <li><b>Shift + Delete:</b> deletes the selected object</li>
                </ul>
              </div>
            </div>

            <div class="multi-button-container">
              <button type="button" class="white-submit" (click)="hide()">OK</button>
            </div>
          </div>
        </ng-template>

        <ng-template #twoSidedWarning>
          <div class="modal-header pb-0 border-0">
            <div class="modal-title page-title eyebrow">
              <h1>Add Two-sided Printing?</h1>
              <div class="brown-bar"></div>
            </div>
            <button type="button" class="close-button pull-right" (click)="hide()">
              <img src="assets/cb/images/close-icon.png" alt="Close" />
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <p>For a better unpacking experience, upgrade your box to two-sided printing.</p>
            </div>
            <div class="button-container">
              <button type="button" class="white-submit" (click)="hide(); addTwoSidePrinting();">OK</button>
              <button type="button" class="white-submit" (click)="hide();">CANCEL</button>
            </div>
          </div>
        </ng-template>
        <ng-template #Video>
          <div class="modal-header1">

            <iframe #video width="1100" height="600" src="https://www.youtube.com/embed/2gCGo-i0RpU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>

        </ng-template>

        <ng-template #nameDesign>
          <div class="name-design-container">
            <i class="far fa-4x fa-check"></i>
            <h4>Design Saved</h4>

            <p>One last step, give this design a name:</p>
            <input matInput type="text" [(ngModel)]="designName">
            <a (click)="hide(); navigationChoice(true); saveProject()">OK</a>
          </div>
        </ng-template>

        <ng-template #blankBoxWarning>
          <div class="name-design-container">
            <h4>Blank Design</h4>

            <p>One or more sides of your design is blank, is that correct?</p>
            <div class="button-container">
              <button type="button" class="white-submit" (click)="hide(); saveCanvasImages();">YES</button>
              <button type="button" class="white-submit" (click)="hide();">NO</button>
            </div>
          </div>
        </ng-template>

        <!-- <ng-template #login>
        <app-login-modal [reload]='false' (loginSuccess)='hide(); getUser();'></app-login-modal>
      </ng-template> -->


      <!-------------------------------------- HELP TUTORIAL MODALS -------------------------------------->

      @if (helpView) {
        <div (click)="hide()" class='help-view-background'></div>
      }
      @if (helpSection!='patternExplanation' && helpSection!='elementExplanation') {
        <div (click)="hide()"
          [ngClass]="{'right-cover': helpSection=='designAreaExplanation' || helpSection=='toolbarExplanation' , 'center-cover': helpSection=='customBoxExplanation' || helpSection=='viewerExplanation'}"
        class='help-view-piece-background'></div>
      }

      @if (helpSection=='elementExplanation') {
        <div class="element-explanation">
          <div class="arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
          <div class="text-container">
            <p>Add elements like backgrounds, images, and text to your design.</p>
            <div class="help-options">
              <a (click)="hide()" class="exit-tour">EXIT TOUR</a>
              <a (click)="showHelpSection('patternExplanation'); changeActiveMenu('Patterns', 0);"
              class="continue-tour">NEXT</a>
            </div>
          </div>
        </div>
      }

      @if (helpSection=='patternExplanation') {
        <div class="pattern-explanation">
          <div class="arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
          <div class="text-container">
            <p>Search, browse, or open the full gallery of options for each design element.</p>
            <div class="help-options">
              <a (click)="hide()" class="exit-tour">EXIT TOUR</a>
              <a (click)="showHelpSection('toolbarExplanation'); changeActiveMenu('', -1)" class="continue-tour">NEXT</a>
            </div>
          </div>
        </div>
      }

      @if (helpSection=='toolbarExplanation') {
        <div class="toolbar-explanation">
          <div class="arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
          <div class="text-container">
            <p>Set of global tools that help duplicate, move, undo, redo, delete, even start over. Remember to save your work or add to cart.</p>
            <div class="help-options">
              <a (click)="hide()" class="exit-tour">EXIT TOUR</a>
              <a (click)="showHelpSection('rulerExplanation')" class="continue-tour">NEXT</a>
            </div>
          </div>
        </div>
      }

      @if (helpSection=='rulerExplanation') {
        <div class="ruler-explanation">
          <div class="arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
          <div class="text-container">
            <p>Toggle rulers to help you position your graphics.</p>
            <div class="help-options">
              <a (click)="hide()" class="exit-tour">EXIT TOUR</a>
              <a (click)="showHelpSection('designAreaExplanation')" class="continue-tour">NEXT</a>
            </div>
          </div>
        </div>
      }

      @if (helpSection=='designAreaExplanation') {
        <div class="design-area-explanation">
          <div class="arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
          <div class="text-container">
            <p>2D design area. Your added elements will display here to be edited.</p>
            <div class="help-options">
              <a (click)="hide()" class="exit-tour">EXIT TOUR</a>
              <a (click)="showHelpSection('customBoxExplanation')" class="continue-tour">NEXT</a>
            </div>
          </div>
        </div>
      }

      @if (helpSection=='customBoxExplanation') {
        <div class="custom-box-explanation">
          <div class="text-container">
            <p>Tools specific to your design element will display here to edit each item.</p>
            <div class="help-options">
              <a (click)="hide()" class="exit-tour">EXIT TOUR</a>
              <a (click)="showHelpSection('viewerExplanation')" class="continue-tour">NEXT</a>
            </div>
          </div>
          <div class="left-arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
        </div>
      }

      @if (helpSection=='viewerExplanation') {
        <div class="viewer-explanation">
          <div class="text-container">
            <p>Interactive 3D viewer to display your design in real-time.</p>
            <div class="help-options">
              <a (click)="hide()" class="exit-tour">EXIT TOUR</a>
              <a (click)="showHelpSection('twoSidedExplanation')" class="continue-tour">NEXT</a>
            </div>
          </div>
          <div class="left-arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
        </div>
      }

      @if (twoSidedPrintingAdded || helpSection=='twoSidedExplanation') {
        <div class="two-sided-explanation">
          <div class="text-container">
            <p>Switch between your outside and inside design views.</p>
            <div class="help-options">
              @if (twoSidedPrintingAdded) {
                <a (click)="twoSidedPrintingAdded = false; hide()" class="continue-tour">OK</a>
              }
              @if (helpView) {
                <a (click)="hide()" class="continue-tour">FINISH TOUR</a>
              }
            </div>
          </div>
          <div class="arrow-container"><img src="assets/cb/images/red_arrow.svg"></div>
        </div>
      }
    </div>

    @if (downloadUrl) {
      <iframe [src]="downloadUrl" style="border: none;" width="1" height="1"></iframe>
    }

    <ng-template #notFoundModal>
      <app-not-found-modal></app-not-found-modal>
    </ng-template>
    <!-- <ng-template #userIdle>
    <div class="modal-header pb-0 border-0">
      <div class="modal-title page-title eyebrow">
        <h1>Psst...your session is about to expire.</h1>
        <div class="brown-bar"></div>
      </div>
    </div>
    <div class="modal-body">
      <div class="container-fluid">
        <p>You will be logged out in {{ timerCount }} seconds to protect your account. <br>Please click "Continue" and be sure to save or you may lose your design.</p>
      </div>
      <div class="button-container">
        <button type="button" class="white-submit" (click)="continue()">Continue</button>
      </div>
    </div>
  </ng-template> -->
