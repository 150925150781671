import { environment } from '../../../environments/environment';
import MediaType from '../mediatype';
import Size from '../size';
import { AuditedSerializable, ISerializable, Pageable } from './base';
import { BoxType, Subcategory } from './box';
import moment from 'moment';
import { User } from './user';

export class Project extends AuditedSerializable<Project> {
  get online() {
    return this.type === "BASIC";
  }

  get offline() {
    return this.type === "ADVANCED";
  }

  static EMPTY: Project = new Project();
  name: string | undefined;
  type: string | undefined; // Basic or Advanced
  preset: string | undefined;
  boardStrength: string | undefined;
  boardType: string | undefined;
  boxType: BoxType | undefined;
  kraftType: string | undefined;
  container: string | undefined;
  content: string | undefined;
  customerId: string | undefined;
  dimensions: string | undefined;
  gloss: boolean | undefined;
  luxe: boolean | undefined;
  insideOnly: boolean | undefined;
  twoSided: boolean | undefined;
  filename: string | undefined;
  user: User | undefined;
  userId: number | undefined;
  screenshotData: string | undefined;
  screenshotUrl: string | undefined;
  created: Date | undefined;
  updated: Date | undefined;

  override deserialize(input: any): Project {
    super.deserialize(input);
    this.name = input.name;
    this.type = input.type;
    this.preset = input.preset;
    this.boardStrength = input.boardStrength;
    this.boardType = input.boardType;
    this.kraftType = input.kraftType;
    this.container = input.container;
    this.content = input.content;
    this.customerId = input.customerId;
    this.dimensions = input.dimensions;
    this.gloss = input.gloss;
    this.luxe = input.luxe;
    this.insideOnly = input.insideOnly;
    this.twoSided = input.twoSided;
    this.filename = input.filename;
    this.userId = input.userId;
    if (input.boxType) {
      this.boxType = new BoxType().deserialize(input.boxType);
    }
    if (input.createdTime) {
      this.created = moment(input.createdTime).toDate();
    }
    if (input.updatedTime) {
      this.updated = moment(input.updatedTime).toDate();
    }
    if (input.user) {
      this.user = new User().deserialize(input.user);
    }
    this.screenshotData = input.screenshotData;
    this.screenshotUrl = input.screenshotUrl;
    return this;
  }
}

export class Container implements ISerializable<Container> {
    name: string | undefined;
    files: ContainerFile[] = [];

    deserialize(input: any): Container {
        this.name = input.container;
        if (input.files) {
            (input.files as any[]).forEach((file) => {
                this.files.push(new ContainerFile().deserialize(file));
            });
        }
        return this;
    }
}

export class ContentType {
    type: string = '';
    subtype: string = '';

    deserialize(input: any): ContentType {
        this.type = input.type;
        this.subtype = input.subtype;
        return this;
    }

    get isImage() {
        return MediaType.isImage(this.toString());
    }

    get isJson() {
        return MediaType.isJson(this.toString());
    }

    get isPdf() {
        return MediaType.isPdf(this.toString());
    }

    get isSvg() {
        return MediaType.isSvg(this.toString());
    }

    get isText() {
        return MediaType.isText(this.toString());
    }

    public toString = (): string => {
        return this.type + '/' + MediaType.getSimpleSubtype(this.subtype);
    }
}

export class ContainerFile implements ISerializable<ContainerFile> {
    name: string | undefined;
    shortName: string | undefined;
    containerName: string | undefined;
    contentType: ContentType | undefined;
    length: string = '0';
    lengthReadable: string | undefined;
    content: string | undefined;
    created: Date | undefined;
    updated: Date | undefined;

    get url() {
        if (this.containerName) {
            return `${environment.apiHost}/project/container/${this.containerName}/${this.name}`;
        }
        return `${environment.apiHost}/project/container/${this.name}`;
    }

    deserialize(input: any): ContainerFile {
        this.name = input.name;
        this.shortName = input.shortName;
        this.containerName = input.containerName;
        this.contentType = input.contentType;
        this.length = input.length;
        this.lengthReadable = Size.convertAndFormat(this.length);
        this.content = input.content;
        this.contentType = new ContentType().deserialize(input.contentType);
        this.created = moment(input.created).toDate();
        this.updated = moment(input.updated).toDate();
        return this;
    }
}

export class UserImage implements ISerializable<UserImage> {
    content: string | undefined;
    contentType: ContentType | undefined;
    name: string | undefined;
    length: string = '0';
    lengthReadable: string | undefined;
    created: Date | undefined;
    updated: Date | undefined;

    deserialize(input: any): UserImage {
        this.content = input.content;
        if (input.contentType) {
            this.contentType = new ContentType().deserialize(input.contentType);
        } else if (input['content-type']) {
            this.contentType = new ContentType().deserialize(input['content-type']);
        }
        this.name = input.name;
        this.length = input.length;
        this.lengthReadable = Size.convertAndFormat(this.length);
        this.created = input.created;
        this.updated = input.updated;
        return this;
    }
}

