import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {

  constructor(
    private httpClient: HttpClient
  ) { }

  checkFormat(fileName: string) {
    const url = `${environment.apiHost}/converter/upload/${fileName}/format`;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  convert(convertUrl: string, formData: FormData) {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(convertUrl, formData)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  uploadFile(imageUploadBody: any) {
    const url = `${environment.apiHost}/converter/convert`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, imageUploadBody).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  convertContainerFile(containerId: any, target: any, filename: any, file: any) {
    const url = `${environment.apiHost}/converter/convert/container/${containerId}?target=${target}&filename=${filename}`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, file)
        .subscribe(res => {
          resolve(res);

        }, error => {
          reject(error.message || error);
        });
    });
  }

  getConvertedContent(jobId: string) {

    const url = `${environment.apiHost}/converter/convert/job/${jobId}/content?`;
    const delay = 1000;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(res => {
          if (res === null) {
            setTimeout(() => resolve(this.getConvertedContent(jobId)), delay);
          } else {
            resolve(res);
          }

        }, error => {
          reject(error.message || error);
        });
    });
  }

  getConvertUrl(target?: any) {
    return `${environment.apiHost}/zuul/converter/convert?target=${target || 'image/jpg'}`;
  }

  getUploadUrl(containerName: string) {
    return `${environment.apiHost}/zuul/converter/upload/${containerName}/content`;
  }
}
