<div class="content-container">
  <div class="bread-crumbs-container">
    <a (click)="goBack()"
      ><img src="assets/cb/images/chevron-left-grey.svg" alt="" /> Go Back</a
    >
  </div>
  <div class="background-container">
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
