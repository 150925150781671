import {Component, OnInit} from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import {Observable, Subject} from 'rxjs';
import { EscapeHtmlPipe } from '../../shared/pipes/escape/keep-html.pipe';

@Component({
    standalone:true,
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
    imports: [
    EscapeHtmlPipe
]
})
export class ConfirmationModalComponent implements OnInit {

    public body: string = ''
    public title: string | undefined;
    private onClose = new Subject<boolean | null>();
    private options: Options | undefined;

    public constructor(private bsModalRef: BsModalRef) {
    }

    get okButtonText() {
        return this.options && this.options.okButtonText ? this.options.okButtonText : this.isDangerous ? 'Yes' : 'OK';
    }

    get cancelButtonText() {
        return this.options && this.options.cancelButtonText ? this.options.cancelButtonText : this.isDangerous ? 'No' : 'Cancel';
    }

    get isDangerous() {
        return this.options && this.options.dangerous !== undefined ? this.options.dangerous : false;
    }

    get dangerousText() {
        return this.options && this.options.dangerousText !== undefined ? this.options.dangerousText : 'Are you sure?';
    }

    get showCancel() {
        return this.options && this.options.showCancel !== undefined ? this.options.showCancel : false;
    }

    public ngOnInit(): void {
    }

    public showModal(body: string, title?: string, options: Options = {}): Observable<boolean | null> {
        this.title = title;
        this.body = body;
        this.options = options;
        return this.onClose.asObservable();
    }

    public onConfirm(): void {
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.onClose.next(null);
        this.bsModalRef.hide();
    }
}

export interface Options {
    dangerous?: boolean;
    dangerousText?: string;
    showCancel?: boolean;
    okButtonText?: string;
    cancelButtonText?: string;
}
