import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss',
})
export class NavBarComponent {

  step0 = false;
  step1 = false;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;
  step6 = false;

  constructor(
    private router: Router,
    private navigationService: NavigationService
  ) {
    router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.resetSteps();
        if (
          event.url.includes('continue-project') ||
          event.url.includes('start')
        ) {
          this.step1 = true;
        } else if (
          event.url.includes('box-category') ||
          event.url.includes('box-style')
        ) {
          this.step2 = true;
        } else if (event.url.includes('box-options')) {
          this.step3 = true;
        } else if (
          event.url.includes('design') &&
          !event.url.includes('services')
        ) {
          this.step4 = true;
        } else if (event.url.includes('review-box')) {
          this.step5 = true;
        } else if (event.url.includes('cart')) {
          this.step6 = true;
        }
      });
  }

  resetSteps() {
    this.step0 = false;
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
    this.step6 = false;
  }

  goToBoxCategories() {
    if (this.step3 || this.step4 || this.step5 || this.step6) {
      this.router.navigate([
        '/box-category',
        { upload: this.navigationService.getIsUpload() },
      ]);
    }
  }

  goToBoxOptions() {
    if (this.step4 || this.step5 || this.step6) {
      this.router.navigate([
        '/box-options',
        this.navigationService.getCategoryCode(),
        this.navigationService.getSubcategory(),
        this.navigationService.getIsUpload(),
      ]);
    }
  }

  goToDesigner() {
    if (this.step5 || this.step6) {
      this.router.navigate([
        '/design',
        this.navigationService.getCategoryCode(),
        this.navigationService.getSubcategory(),
        this.navigationService.getProductDetails(),
      ]);
    }
  }
}
