import {ISerializable} from './base';

export class AuthToken implements ISerializable<AuthToken> {

  accessToken: string = "";
  tokenType: string = "";
  refreshToken: string = "";
  expiresIn: number = 0;
  scope: string = "";
  authorities: string[] = [];

  deserialize(input: any): AuthToken {
    this.accessToken = input.access_token;
    this.tokenType = input.token_type;
    this.refreshToken = input.refresh_token;
    this.expiresIn = input.expires_in;
    this.scope = input.scope;
    return this;
  }

}
