import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  private Cookie = {};

  create(name: string, value: string, days: number) {
    let expires;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    } else {
      expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
    return value;
  }
  createWithDomain(name: string, value: string) {
    const domain = 'domain=.companybox.com';
    document.cookie = name + '=' + value + '; max-age=10' + '; secure; ' + domain;
    return value;
  }

  // Function to read a cookie. It will return null if the cookie does not exist
  read(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  delete(name: string) {
    document.cookie = name + '=; expires=-1; path=/';
  }



  deleteWithDomain(name: string) {
    const domain = 'domain=' + '.companybox.com';
    document.cookie = name + '=; max-age=-1; ' + domain;
  }

}
