import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: "app-product-card",
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: "./product-card.component.html",
  styleUrl: "./product-card.component.scss",
})
export class ProductCardComponent {
  @Input() categoryCode: string = '';
  @Input() subcategory: any;
  @Input() uploadDesign: boolean = false;

  getDefaultPicture(subcategory: any) {
    if (subcategory.images.length > 0) {
      for (const image of subcategory.images) {
        if (image.default) {
          return image.location;
        }
      }
      return subcategory.images[0].location;
    }
  }

  private getGIF(subcategory: any) {
    for (const image of subcategory.images) {
      if (image.hover === "true") {
        return image.location;
      }
    }
    return this.getDefaultPicture(subcategory);
  }

  showGIF(div: any, subcategory: any) {
    div.style = "background: url(" + this.getGIF(subcategory) + ");";
  }

  showStaticPicture(div: any, subcategory: any) {
    div.style = "background: url(" + this.getDefaultPicture(subcategory) + ");";
  }
}
