<div class="modal-container">
  <button type="button" class="close-button pull-right" (click)="hide()">
    <img src="assets/cb/images/close-icon.png" alt="Close" />
  </button>
  <div class="left-col">
    <div class="information-container">
      <h4>Pattern Library</h4>
      <!-- <mat-form-field>
      <input matInput [(ngModel)]="searchText" (keyup.enter)="search()" type="text">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field> -->
    <ul>
      @for (collection of patternCollections; track collection) {
        <li
          (click)="scroll('pattern-collection-' + collection.id)">
        {{collection.name}}</li>
      }
    </ul>
  </div>
</div>
<div class="pattern-container">
  @for (collection of patternCollections; track collection) {
    <div>
      <h5 id="pattern-collection-{{collection.id}}">{{collection.name | uppercase}}</h5>
      <div class="patterns-list">
        @for (pattern of getCollectionPatterns(collection.id); track trackByFn($index, pattern)) {
          <div class="image-container">
            <img [src]="pattern.previewUrl" (click)="addPattern(pattern)">
          </div>
        }
      </div>
    </div>
  }
</div>
</div>