import { CommonModule, UpperCasePipe } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-pattern-library',
  templateUrl: './pattern-library.component.html',
  styleUrls: ['./pattern-library.component.scss'],
  imports: [
    CommonModule,
    UpperCasePipe
  ]
})
export class PatternLibraryComponent implements OnInit {

  @Input() patternCollections: any;
  @Input() patterns: any[] = [];

  @Output()
  public hideModal: EventEmitter<any> = new EventEmitter();

  @Output()
  patternSelected: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getCollectionPatterns(collectionId: number) {
    return this.patterns.filter((pattern: { collection: { id: number; }; }) => {
      return pattern.collection.id === collectionId;
    });
  }
  public trackByFn(index: any, item: any) {
    if (!item) {
      return null;
    } else {
      return item.id;
    }
  }
  scroll(id: any) {
    const element = window.document.getElementById(id);
    if(element) element.scrollIntoView();
  }

  hide() {
    this.hideModal.emit();
  }

  addPattern(pattern: any) {
    this.patternSelected.emit(pattern);
  }
}
