import {Component, OnInit, TemplateRef} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  standalone:true,
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  private modal: BsModalRef | undefined;

  constructor(private bsModalService: BsModalService) {
  }

  ngOnInit() {
  }

  showModal(template: TemplateRef<any>) {
    this.modal = this.bsModalService.show(template);
  }

  hideModal() {
    this.modal?.hide();
  }

}
