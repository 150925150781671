import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  standalone:true,
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  imports:[CommonModule]
})
export class LoadingModalComponent implements OnInit {

  @Input() content: string = '';
  @Input() showLoadingBar = false;

  public loadingProgress = 0;

  constructor() { }

  async ngOnInit() {
    if (this.showLoadingBar) {
      await this.increaseLoadingProgress();
    }
  }

  increaseLoadingProgress() {
    return new Promise<void>((resolve) => {
      const increment = 50;
      if (this.loadingProgress < 50) {
        this.loadingProgress += 0.5;
        setTimeout(() => resolve(this.increaseLoadingProgress()), increment);
      } else if (this.loadingProgress < 98) {
        this.loadingProgress += 0.25;
        setTimeout(() => resolve(this.increaseLoadingProgress()), increment);
      } else {
        resolve();
      }
    });
  }


}
