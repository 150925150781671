<div class="modal-header pb-0 border-0">
  <button (click)="hideModal.emit()" type="button" class="close-button pull-right">
    <img src="assets/cb/images/close-icon.png" alt="Close" />
  </button>
</div>
@if (!uploadInProgress) {
  <div class="upload-container">
    <ngx-dropzone (change)="processImage($event)">
      <ngx-dropzone-label><img src="assets/cb/images/upload_art_grey.svg">DROP YOUR ART HERE OR CLICK
      TO UPLOAD</ngx-dropzone-label>
    </ngx-dropzone>
    <p>Upload images with at least <strong>300 DPI</strong> or it may result in pixelation.
    <br>Accepted file types are <strong>ai, eps, jpg, pdf, svg</strong> and <strong>png.</strong>
    <br>When possible use a <strong>vector-based</strong> format.</p>
    @if (imageUploadError) {
      <p><span>{{imageUploadError}}</span></p>
    }
  </div>
}

@if (uploadInProgress) {
  <div class="upload-container">
    <h5>Conversion Progress</h5>
    <div class="convert-progress-container">
      <div class="convert-progress" [ngStyle]="{ 'width': conversionProgress + '%' }"></div>
    </div>
    <!-- <mat-progress-bar mode="determinate" value="40"></mat-progress-bar> -->
    <h5>Upload Progress</h5>
    <div class="upload-progress-container">
      <div class="upload-progress" [ngStyle]="{ 'width': uploadProgress + '%' }"></div>
    </div>
  </div>
}
