import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  private templateDownloadUrl!: SafeUrl;
  userService: any;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer ) { }

  getTemplate(box: any): Promise<any> {

    const url = `${environment.apiHost}/project/container/`;

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, {},
        {
          params: {
            code: box.boxType.code,
            boxType: box.boxType.aeCode.toUpperCase(),
            boxLength: box.boxLength,
            boxLengthName: box.boxLength.toString().replace('.', '_'),
            boxWidth: box.boxWidth,
            boxWidthName: box.boxWidth.toString().replace('.', '_'),
            boxDepth: box.boxDepth,
            boxDepthName: box.boxDepth.toString().replace('.', '_'),
            theme: 'IPD',
            flute: box.flute
          }
        })
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }


  getTapeTemplate(box: any): Promise<any> {

    const url = `${environment.apiHost}/project/container/`;

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, {},
        {
          params: {
            code: box.boxType.code,
            boxType: box.boxType.aeCode,
            boxLength: box.boxLength,
            boxLengthName: box.boxLength,
            boxWidth: box.boxWidth,
            boxWidthName: box.boxWidth,
            boxDepth: box.boxDepth,
            boxDepthName: box.boxDepth,
            theme: 'IPD',
            flute: box.flute,
          }
        })
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  getTissueTemplate(box: any): Promise<any> {
    const url = `${environment.apiHost}/project/container/`;

    const boxLengthName = box.boxLength.toString().replace('.', '_');
    const boxWidthName = box.boxWidth.toString().replace('.', '_');
    const boxDepthName = box.boxDepth.toString().replace('.', '_');

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, {},
        {
          params: {
            code: box.boxType.code,
            boxType: 'TIS',
            boxLength: box.boxLength,
            boxLengthName,
            boxWidth: box.boxWidth,
            boxWidthName,
            boxDepth: box.boxDepth,
            boxDepthName,
            theme: 'IPD',
            flute: box.flute,
          }
        })
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  getLabelTemplate(box: any, labelType: string): Promise<any> {
    const url = `${environment.apiHost}/project/container/`;

    const boxLengthName = box.boxLength.toString().replace('.', '_');
    const boxWidthName = box.boxWidth.toString().replace('.', '_');
    const boxDepthName = box.boxDepth.toString().replace('.', '_');

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, {},
        {
          params: {
            code: 'LBL',
            boxType: labelType,
            boxLength: box.boxLength,
            boxLengthName,
            boxWidth: box.boxWidth,
            boxWidthName,
            boxDepth: box.boxDepth,
            boxDepthName,
            theme: 'IPD',
            flute: box.flute,
          }
        })
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  claimContainer(containerId: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then((user: { id: any; }) => {
        const url = `${environment.apiHost}/project/container/${containerId}?userId=${user.id}`;
        this.httpClient.put(url, {})
          .subscribe((response: any) => {
            resolve(response);
          }, error => {
            reject(error.message || error);
          });
      }, (error: { message: any; }) => {
        reject(error.message || error);
      });
    });
  }

  convertBasicDesign(containerName: string, design: any, filename: string) {
    let url = `${environment.apiHost}/zuul/project/container/${containerName}/upload/design?convertOnly=true&`;

    if (filename) {
      url += 'filename=' + filename;
    }

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, design, { headers: { 'Content-type': 'application/xml' } }).subscribe(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  uploadBasicDesign(containerName: string, design: any, filename: string) {
    let url = `${environment.apiHost}/zuul/project/container/${containerName}/upload/design?`;

    if (filename) {
      url += 'filename=' + filename;
    }

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, design, { headers: { 'Content-type': 'application/xml' } }).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  updateFile(containerName: any, filename: any, content: any) {

    const url = `${environment.apiHost}/project/container/${containerName}/${filename}/?x=${Date.now()}`;

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, content).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  getFileContents(containerResponse: any) {
    let url = `${environment.apiHost}/project/container/${containerResponse.containerName}/`;

    if (containerResponse.templateZipName) {
      url += `${containerResponse.templateZip}?download=true&downloadAs=${containerResponse.templateZipName}`;
    } else {
      url += `template.pdf?download=true&downloadAs=${containerResponse.templateName}`;
    }
    return this.templateDownloadUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  storeTexture(containerId: string, filename: string, content: any) {
    const now = Date.now();
    const url = `${environment.apiHost}/project/container/${containerId}/${filename}/?x=${now}`;

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, content)
        .subscribe(response => {
          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }

  deleteFile(containerName: string, filename: string) {
    const now = Date.now();

    const url = `${environment.apiHost}/project/container/${containerName}/${filename}/?x=${now}`;

    return new Promise((resolve, reject) => {
      this.httpClient.delete(url).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  getContainerInfo(containerId: any) {
    const url = `${environment.apiHost}/project/container/${containerId}/info?includeDesign=true`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(res => {
          resolve(res);
        }, error =>
          reject(error));
    });
  }

  getContainerFiles(containerId: any) {
    const url = `${environment.apiHost}/project/container/${containerId}/files?includeContent=true`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(res => {
          resolve(res);
        }, error =>
          reject(error));
    });
  }


  uploadBasicDesignFile(containerId: string, formData: FormData) {
    const url = `${environment.apiHost}/zuul/project/container/${containerId}/upload/design`;

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, formData)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
}
