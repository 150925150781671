export default class MediaType {

  static getType(mediatype: string) {
    const matches = this.getMatches(mediatype);
    if (!matches) {
      return undefined;
    }
    switch (matches[1]) {
      case 'image':
        switch (matches[2]) {
          case 'png':
            return 'png';
          case 'jpeg':
          case 'jpg':
          case 'jpeg-2000':
          case 'jpeg-2000-image':
          case 'x-jpeg-2000':
            return 'jpg';
          case 'svg-xml':
          case 'svg+xml':
            return 'svg';
          case 'gif':
            return 'gif';
          case 'x-photoshop':
          case 'vnd.adobe.photoshop':
            return 'psd';
          default:
            return undefined;
        }
      case 'application':
        switch (matches[2]) {
          case 'x-pdf':
          case 'pdf':
          case 'vnd.pdf':
          case 'vnd.cups-pdf':
            return 'pdf';
          case 'illustrator':
            return 'ai';
          case 'svg-xml':
          case 'svg+xml':
            return 'svg';
          case 'xml':
            return 'xml';
          case 'postscript':
            return 'eps';
          case 'json':
            return 'json';
          default:
            return undefined;
        }
      case 'text':
        switch (matches[2]) {
          case 'x-pdf':
          case 'pdf':
            return 'pdf';
          case 'illustrator':
            return 'ai';
          case 'xml':
            return 'xml';
          case 'postscript':
            return 'eps';
          case 'plain':
            return 'text';
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  }

  static isPdf(mediatype: string) {
    const matches = this.getMatches(mediatype);
    if (!matches) {
      return false;
    }
    switch (matches[1]) {
      case 'image':
        switch (matches[2]) {
          case 'x-photoshop':
          case 'vnd.adobe.photoshop':
            return true;
          default:
            return false;
        }
      case 'application':
        switch (matches[2]) {
          case 'x-pdf':
          case 'pdf':
          case 'vnd.pdf':
          case 'vnd.cups-pdf':
            return true;
          default:
            return false;
        }
      case 'text':
        switch (matches[2]) {
          case 'x-pdf':
          case 'pdf':
            return true;
          default:
            return false;
        }
      default:
        return false;
    }
  }

  static isImage(mediatype: string) {
    const matches = this.getMatches(mediatype);
    if (!matches) {
      return false;
    }
    switch (matches[1]) {
      case 'image':
        switch (matches[2]) {
          case 'png':
          case 'jpeg':
          case 'jpg':
          case 'jpeg-2000':
          case 'jpeg-2000-image':
          case 'x-jpeg-2000':
          case 'svg-xml':
          case 'svg+xml':
          case 'gif':
            return true;
          default:
            return false;
        }
      case 'application':
        switch (matches[2]) {
          case 'svg-xml':
          case 'svg+xml':
            return true;
          default:
            return false;
        }
      default:
        return false;
    }
  }

  static isSvg(mediatype: string) {
    const matches = this.getMatches(mediatype);
    if (!matches) {
      return false;
    }
    switch (matches[1]) {
      case 'image':
        switch (matches[2]) {
          case 'svg':
          case 'svg-xml':
          case 'svg+xml':
            return true;
          default:
            return false;
        }
      case 'application':
        switch (matches[2]) {
          case 'svg':
          case 'svg-xml':
          case 'svg+xml':
            return true;
          default:
            return false;
        }
      default:
        return false;
    }
  }

  static isText(mediatype: string) {
    const matches = this.getMatches(mediatype);
    if (!matches) {
      return false;
    }
    switch (matches[1]) {
      case 'application' :
        switch (matches[2]) {
          case 'json':
          case 'xml':
            return true;
          default:
            return false;
        }
      case 'text' :
        switch (matches[2]) {
          case 'xml':
          case 'plain':
            return true;
          default:
            return false;
        }
      default:
        return false;
    }
  }

  static isJson(mediatype: string) {
    const matches = this.getMatches(mediatype);
    if (!matches) {
      return false;
    }
    switch (matches[1]) {
      case 'application' :
        switch (matches[2]) {
          case 'json':
            return true;
        }
    }
    return false;
  }

  private static getMatches(mediatype: string) {
    // console.log('matches', matches);
    return new RegExp('(\\w+)\\/([-+.\\w]+)', 'g').exec(mediatype);
  }

  static getSimpleSubtype(subtype: string) {
    const idx = subtype.indexOf('+') || subtype.indexOf('-');
    if (idx > 0) {
      return subtype.substr(0, idx);
    }
    return subtype;
  }
}
