import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { CanvasShapeComponent } from '../canvas-shape/canvas-shape.component';

@Component({
  standalone:true,
  selector: 'app-shape-library',
  templateUrl: './shape-library.component.html',
  styleUrls: ['./shape-library.component.scss'],
  imports: [
    CommonModule,
    // CanvasShapeComponent
  ]
})
export class ShapeLibraryComponent implements OnInit {

  @Input() shapeCollections: any;
  @Input() shapes: any[] = [];

  @Output()
  public hideModal: EventEmitter<any> = new EventEmitter();

  @Output()
  shapeSelected: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getCollectionShapes(collectionId: number) {
    return this.shapes.filter((shape: { collection: { id: number; }; }) => {
      return shape.collection.id === collectionId;
    });
  }

  scroll(id: any) {
    const element = window.document.getElementById(id);
    if(element) element.scrollIntoView();
  }
  public trackByFn(index: any, item: { id: any; }) {
    if (!item) {
      return null;
    } else {
      return item.id;
    }
  }
  hide() {
    this.hideModal.emit();
  }

  addShape(shape: any) {
    this.shapeSelected.emit(shape);
  }

}
