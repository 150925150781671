import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-not-found-modal',
  templateUrl: './not-found-modal.component.html',
  styleUrls: ['./not-found-modal.component.scss']
})
export class NotFoundModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  refreshPage() {
    window.location.reload();
  }

}
