import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-connected-better',
  standalone: true,
  imports: [FormsModule, MatFormFieldModule],
  templateUrl: './connected-better.component.html',
  styleUrl: './connected-better.component.scss'
})
export class ConnectedBetterComponent implements OnInit {
  @ViewChild("auth", {static: false}) authFormElement!: ElementRef;
  loginUrl: SafeUrl = '';
  productConfigData = '';
  configurationData = '';
  formKey = '';

  constructor(private sanitized: DomSanitizer) {
  }

  ngOnInit(): void {

    let item = sessionStorage.getItem("sessionData");
    if (item == null) {
      window.location.href = environment.config.marketingSite;
      return;
    }

    let sessionData = JSON.parse(<string>item);

    this.loginUrl = this.sanitized.bypassSecurityTrustResourceUrl(sessionData.loginUrl)
    this.productConfigData = sessionData.productConfigData;
    this.configurationData = `{}`;
    this.formKey = sessionData.formKey;

  }

}
