import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from "@angular/common/http";

export const sessionInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
    let newReq = req;
    const sessionKey = sessionStorage.getItem("sessionKey");
    if(sessionKey) {
      const headers = req.headers.set('x-session', sessionKey);
      newReq = req.clone({ headers});
    }   
  return next(newReq);
};