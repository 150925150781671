import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {EventEmitter, Injectable} from "@angular/core";
import {NGXLogger} from "ngx-logger";
import {SessionStorage} from "ngx-webstorage";
import {CheckoutService} from "./checkout.service";
import {environment} from "../../environments/environment";
import {AuthToken} from "../shared/models/auth";
import {interval, startWith, Subscription, switchMap, takeWhile} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  @SessionStorage()
  // tslint:disable-next-line:variable-name
  private _token: AuthToken | undefined;

  public update$ = new EventEmitter<any>();

  healthCheckPolling = Subscription.EMPTY;

  constructor(
    protected httpClient: HttpClient,
    private logger: NGXLogger,
    private checkoutService: CheckoutService
  ) {

    if (this.healthCheckPolling == Subscription.EMPTY) {
      this.healthCheckPolling = interval(10000)
        .pipe(
          startWith(0),
          switchMap(() => this.healthCheck())
        )
        .subscribe(loggedIn => {
          if (!loggedIn) {
            this.getAuthData().then(res => {
              res.userId = undefined
              sessionStorage.setItem("sessionData", JSON.stringify(res));
            })
          }
        });
    }
  }

  getAuthData(): Promise<any> {

    const url = `${environment.apiHost}/ecom/auth`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error);
        });
    });
  }

  async healthCheck(): Promise<boolean> {
    try {
      this.httpClient.post(environment.config.healthCheckUrl, {
        "seller_id": environment.config.fedexSellerId
      }, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
        .subscribe(res => {
          console.log("User is logged in")
          return Promise.resolve(true);
        }, () => {
          console.log("User is not logged in")
          return Promise.resolve(false);
        })

    } catch (error: any) {
    }
    return Promise.resolve(false);
  }

  checkPassword(loginData: {
    username: string;
    password: string;
  }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const params = new HttpParams()
        .append("grant_type", "password")
        .append("username", loginData.username)
        .append("password", loginData.password);

      const oldToken = this._token;
      this._token = undefined;
      this.httpClient
        .post(`${environment.apiHost}/user/oauth/token`, params, {
          headers: new HttpHeaders({
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          }),
        })
        .subscribe({
          next: (_res: any) => {
            this._token = oldToken;
            resolve(true);
          },
          error: (_response: HttpResponse<any>) => {
            this._token = oldToken;
            // console.log('response', response);
            reject(false);
          },
        });
    });
  }

  authorize(loginData: {
    username: string;
    password: string;
  }): Promise<AuthToken> {
    return new Promise<AuthToken>((resolve, reject) => {
      this._token = undefined;

      const params = new HttpParams()
        .append("grant_type", "password")
        .append("username", loginData.username)
        .append("password", loginData.password);

      this.httpClient
        .post<HttpResponse<AuthToken>>(
          `${environment.apiHost}/user/oauth/token`,
          params,
          {
            headers: new HttpHeaders({
              "Content-type":
                "application/x-www-form-urlencoded; charset=utf-8",
            }),
          }
        )
        .subscribe(
          (res: HttpResponse<AuthToken>) => {
            this._token = new AuthToken().deserialize(res);
            this.shopifyToken(loginData.username, loginData.password).then(
              (_shopifyToken) => {
                // this.logger.debug('New token', this._token);
                this.logger.debug("response success", res);
                this.update$.emit();
                this.checkToken().then(
                  (authenticated) => {
                    if (this._token && authenticated) {
                      resolve(this._token);
                    } else {
                      reject({
                        error: "invalid_grant",
                        message: "Invalid username or password",
                      });
                    }
                  },
                  (_response) => {
                    // console.log('response error', response);
                    this.logger.debug({
                      error: "session_expired",
                      message: "Session Expired",
                    });
                    reject({
                      error: "session_expired",
                      message: "Session Expired",
                    });
                  }
                );
              },
              (_error) => {
                reject({
                  error: "invalid_grant",
                  message: "Invalid username or password",
                });
              }
            );
          },
          (_response: HttpResponse<any>) => {
            // console.log('response', response);
            reject({
              error: "invalid_grant",
              message: "Invalid username or password",
            });
          }
        );
    });
  }

  get token(): AuthToken | undefined {
    // console.log('Current token', this._token);
    if (this._token && this._token.expiresIn > 0) {
      return this._token;
    } else {
      this._token = undefined;
      return undefined;
    }
  }

  checkToken(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this._token) {
        resolve(false);
      } else {
        this.httpClient
          .get(`${environment.apiHost}/user/oauth/check_token`, {
            params: {
              token: this._token.accessToken,
            },
          })
          .subscribe(
            (res: any) => {
              // this.logger.trace('Check token response', res);
              this._token!.authorities = [];
              (res.authorities as any[]).forEach((role) => {
                this._token!.authorities.push(role);
              });
              // noinspection SillyAssignmentJS
              this._token = this._token; // triggers an update of session storage
              resolve(true);
            },
            (error) => {
              this._token = undefined;
              this.logger.trace(error);
              reject(error);
            }
          );
      }
    });
  }

  renewToken(): Promise<AuthToken> {
    return new Promise<AuthToken>((resolve, reject) => {
      this.logger.info("Refreshing user token");
      if (!this._token) {
        reject({message: "No token to refresh"});
      }
      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      const body =
        "refresh_token=" +
        this._token!.refreshToken +
        "&grant_type=refresh_token";

      this.httpClient
        .post(`${environment.apiHost}/user/oauth/token`, body, {headers})
        .subscribe(
          (res: any) => {
            this.logger.trace("Check token response", res);
            this._token!.accessToken = res.access_token;
            this.logger.trace("new token", this._token);
            // noinspection SillyAssignmentJS
            this._token = this._token; // triggers an update of session storage
            resolve(this._token!);
          },
          (error) => {
            this._token = undefined;
            this.logger.trace(error);
            reject(error);
          }
        );
    });
  }

  clearToken() {
    this._token = undefined;
  }

  logout(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiHost}/user/logout`, null)
        .subscribe(
          (_response: any) => {
            this.logger.info("User logged out");
            this._token = undefined;
            localStorage.clear();
            sessionStorage.clear();
            this.checkoutService.clear();
            resolve(true);
          },
          (error) => {
            console.log(error);
            this.logger.debug("Unable to log out user", error);
            reject(error);
          }
        );
    });
  }

  validate(authToken: AuthToken, roles: string[], name: string): boolean {
    if (!authToken && !this.token) {
      return false;
    }

    this.logger.trace("authorities", authToken.authorities);

    let passed = true;
    roles.forEach((role) => {
      let inverted = false;
      if (role.startsWith("!")) {
        inverted = true;
        role = role.substr(1);
      }

      const indexOfRole = authToken.authorities.indexOf("ROLE_" + role);
      if (!inverted && indexOfRole > -1) {
        this.logger.trace("Roles inverted", inverted);
        if (name) {
          this.logger.trace(
            "User has '" +
            role +
            "' role, access granted to named resource '" +
            name +
            "'"
          );
        } else {
          this.logger.trace("User has '" + role + "' role");
        }
        // this.logger.debug('User has \'' + role + '\' role');
        passed = passed && true;
      } else if (inverted && indexOfRole === -1) {
        this.logger.trace("Roles inverted", inverted);
        if (name) {
          this.logger.trace(
            "User does not have excluded '" +
            role +
            "' role, access granted named resource '" +
            name +
            "'"
          );
        } else {
          this.logger.trace("User does not have excluded '" + role + "' role");
        }
        // this.logger.debug('User has \'' + role + '\' role');
        passed = passed && true;
      } else {
        this.logger.trace("Roles inverted", inverted);
        if (name) {
          this.logger.trace("User does NOT have access");
        }
        // this.logger.debug('User does NOT have \'' + role + '\' role');
        passed = false;
      }
    });

    return passed;
  }

  shopifyToken(username: any, password: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiHost}/user/token`, {username, password})
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
