import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(
    private httpClient: HttpClient
  ) { }


  upload(url: string, formData: FormData) {
    console.log('The file is: ', formData.get('file'));
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, formData)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  uploadPixabay(containerId: string, pixabayUrl: string) {
    const url = `${environment.apiHost}/zuul/converter/upload/${containerId}/url?url=${pixabayUrl}`;
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, {}).subscribe(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  convertContentFromUrl(containerId: string, url: string, target?: string) {
    target = target || 'application/svg%2Bxml';
    const fullUrl = `${environment.apiHost}/converter/convert/url?target=${target}&url=${url}`;

    return new Promise((resolve, reject) => {
      this.httpClient.post(fullUrl, {}).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  searchPixabay(url: string) {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  getUserImages(userID: number) {
    const url = `${environment.apiHost}/project/image/${userID}/list?filter=.*.jpg%24%7C.*.png%24%7C.*.gif%24%7C.*.svg%24&sort=-updated`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  getContainerFiles(containerId: string) {
    const filter = encodeURIComponent('.*upload/.*\.jpg$|.*upload/.*\.png$|.*upload/.*\.gif$|.*upload/.*\.svg$');
    const url = `${environment.apiHost}/project/container/${containerId}/files?includeContent=false&filter=${filter}&sort=-updated`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  uploadImageToUserLibrary(file: any, userID: number) {
    const url = `${environment.apiHost}/project/image/${userID}`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, file)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  uploadImageToContainer(file: any, containerId: string) {
    const url = `${environment.apiHost}/project/container/${containerId}/image`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, file)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  copyImageToContainer(file: any, containerId: string) {
    const url = `${environment.apiHost}/project/container/${containerId}/image`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, file)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  copyFromContainer(containerId: string, userId: number) {
    const url = `${environment.apiHost}/project/image/copy/${containerId}/${userId}`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, {})
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  removeImage(userId: number, image: any) {
    const url = `${environment.apiHost}/project/image/${userId}/${image.name}`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.delete(url)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  deleteContainerImage(containerId: string, image: any) {
    const url = `${environment.apiHost}/project/container/${containerId}/upload/${image.name}`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.delete(url)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });
  }


  saveImageToContainer(containerId: string, filename: string, fileContent: any) {
    const url = `${environment.apiHost}/project/container/${containerId}/upload/json?filename=${filename}`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, fileContent)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error.message || error);
        });
    });

  }


getFileContent(containerId: string, filename: string) {
  const url = `${environment.apiHost}/project/container/${containerId}/files/content?filename=${filename}`;

  return new Promise<any>((resolve, reject) => {
    this.httpClient.get(url)
      .subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(error.message || error);
      });
  });
}
}

