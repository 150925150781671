import {
  Component,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  ParamMap,
  Router,
  RouterModule,
} from "@angular/router";
import { LoggerModule, NGXLogger } from "ngx-logger";
// import cryptoJs from 'crypto-js';
import { BoxService } from "../../services/box.service";
import { LocalStorageService } from "../../services/localstorage.service";
import { NavigationService } from "../../services/navigation.service";
import { LoadingModalComponent } from "../../features/loading-modal/loading-modal.component";
import { ModalModule, BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { CommonModule } from "@angular/common";
import { NotFoundModalComponent } from "../../features/not-found-modal/not-found-modal.component";
import { ProductCardComponent } from "../../features/product-card/product-card.component";
import { PageContentWrapperComponent } from "../../features/page-content-wrapper/page-content-wrapper.component";
import {AuthService} from "../../services/auth.service";

declare var window: any;

@Component({
  standalone: true,
  selector: "app-box-style",
  templateUrl: "./box-style.component.html",
  styleUrls: ["./box-style.component.scss"],
  imports: [
    CommonModule,
    PageContentWrapperComponent,
    RouterModule,
    LoadingModalComponent,
    NotFoundModalComponent,
    LoggerModule,
    ModalModule,
    MatSnackBarModule,
    ProductCardComponent,
  ],
})
export class BoxStyleComponent implements OnInit {
  @ViewChild("notFoundModal", { static: false })
  notFoundModal!: TemplateRef<any>;

  private modal: BsModalRef | undefined;

  categoryCode = signal<string>("");
  shownCategory = signal<number>(0);
  subcategories = signal<any[]>([]);
  failedcategories = signal<boolean>(false);
  uploadDesign = signal<boolean>(false);
  pageLoading = signal<boolean>(false);
  subcategoryDescriptions = signal<any[]>([]);

  constructor(
    private boxService: BoxService,
    private authService: AuthService,
    private bsModalService: BsModalService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private navigationService: NavigationService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.pageLoading.set(true);
    this.localStorageService.clear();
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      sessionStorage.clear();
      const sessionKey: string = params.get("sessionID") ?? "";
      if (sessionKey) {
        sessionStorage.setItem("sessionKey", sessionKey);
        this.authService.getAuthData().then(res => {
          res.userId = undefined
          sessionStorage.setItem("sessionData", JSON.stringify(res));
        })
      }
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      const catCode: string = params.get("categoryCode") ?? "";
      this.categoryCode.set(catCode);

      const uploadDesign = Boolean(
        JSON.parse(params.get("uploadDesign") ?? "false")
      );
      this.uploadDesign.set(uploadDesign);
      window.scroll(0, 0);
    });
    this.logger.debug(
      `calling BoxStyleComponent.ngOnInit with categoryCode ${this.categoryCode()}`
    );

    this.navigationService.setCategoryCode(this.categoryCode());
    this.getSubcategories();
  }

  goBack() {
    this.router.navigate(["/box-category"], {
      fragment: `upload=${this.uploadDesign()}`,
    });
  }

  getSubcategories() {
    this.boxService
      .getCategory(this.categoryCode(), true)
      .then(
        (category) => {
          this.failedcategories.set(false);
          if (category.subcategories.length === 1) {
            this.router.navigate([
              "/box-options",
              this.categoryCode(),
              category.subcategories[0].code,
              this.uploadDesign(),
            ]);
          } else {
            this.subcategories.set([...category.subcategories]);
          }
        },
        (reason) => {
          this.showModal(this.notFoundModal);
          const snackBarRef = this.snackBar.open(
            "Unable to load box styles",
            "X",
            {
              duration: 10000,
              verticalPosition: "top",
              horizontalPosition: "right",
              panelClass: ["red-snackbar"],
            }
          );
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });

          this.logger.error(reason);
          this.failedcategories.set(true);
        }
      )
      .finally(() => this.pageLoading.set(false));
  }

  showModal(template: TemplateRef<any>) {
    this.modal = this.bsModalService.show(template);
  }
  hide() {
    if (this.modal) this.modal.hide();
  }
  public trackByFn(_index: any, item: { id: any }) {
    if (!item) {
      return null;
    } else {
      return item.id;
    }
  }
  // the below all deal with the carousel for the mobile view
  getDefaultPicture(subcategory: any) {
    if (subcategory.images.length > 0) {
      for (const image of subcategory.images) {
        if (image.default) {
          return image.location;
        }
      }
      return subcategory.images[0].location;
    }
  }

  getGIF(subcategory: any) {
    for (const image of subcategory.images) {
      if (image.hover === "true") {
        return image.location;
      }
    }
    return this.getDefaultPicture(subcategory);
  }

  showGIF(div: any, subcategory: any) {
    div.style = "background: url(" + this.getGIF(subcategory) + ");";
  }

  showStaticPicture(div: any, subcategory: any) {
    div.style = "background: url(" + this.getDefaultPicture(subcategory) + ");";
  }

  previousCategory() {
    if (this.shownCategory() !== 0) {
      this.shownCategory.update((value) => value--);
    }
  }

  nextCategory() {
    if (this.shownCategory() !== this.subcategories().length - 1) {
      this.shownCategory.update((value) => value++);
    }
  }
}
