<div class="loading-container">
  <div class="content">
    <img src="assets/cb/images/loading_box.gif">
    <h1>{{content.toUpperCase()}}...</h1>
    @if (showLoadingBar) {
      <p> It may take up to 90 seconds to generate your custom template. Thanks for your
        patience!
      </p>
    }
    @if (showLoadingBar) {
      <div class="loading-progress-container">
        <div class="loading-progress" [ngStyle]="{ 'width': loadingProgress + '%' }"></div>
      </div>
    }
  </div>
</div>