<div class="modal-header pb-0 border-0">
  <div class="modal-title page-title eyebrow pull-left inline">
    <h4>Terms and Conditions</h4>
  </div>
  <button type="button" class="close pull-right" (click)="hideModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <p>These Terms and Conditions (“Terms”) apply to all Company Box customers, prospects, and website browsers who order products from us, including through the Company Box website (“Site”).</p>

  <p>In addition to the content on the Site, the Site provides you with opportunities to design products using our art design and/or structural design services, and to complete your purchases.</p>

  <p>By using our Site and its services, you agree to the following Terms and Conditions:</p>

  1) You agree to refrain from any use of our Site that
  <p style="padding-left: 60px;">(a) is, or may be, damaging to our Site;<br>
    (b) in any way impacts other users’ access to or use of our Site;<br>
    (c) causes, or may cause, harm to the Site or to any person or business entity, or<br>
    (d) is contrary to applicable laws and regulations</p>
  2) Rate Quotations
  <p style="padding-left: 60px;">Rates and service quotations provided to you by Company Box, including but not limited to, rates and service quotations provided by our employees, agents, interactive voice response systems and customer automation platforms are estimates and will be based upon information provided by you. Final rates and service charges may vary from the quotes based upon the characteristics of the order actually tendered to us and the application of these Terms and Conditions.</p>
  3) Company Box Printing Standards:
  <p style="padding-left: 30px;">a) Inappropriate Printing Requests</p>
  <p style="padding-left: 60px;">(1) Each day, people come to Company Box to request custom printed packaging to promote their brands and increase their sales. We recognize that there are all types of businesses looking to create packaging, and that those custom packages go out into the world to represent their brands.</p>
  <p style="padding-left: 60px;">(2) Your packaging also reflects on our brand. That’s why we have developed a set of Printing Standards that outline what is and is not allowed in the packaging we produce for you. The goal of our Printing Standards is to encourage positive, creative expression within a safe and appropriate environment.</p>
  <p style="padding-left: 60px;">(3) All Company Box customers must follow the guidelines outlined in our “Artwork Guidelines” under ‘Resources’ on the Company Box Site.</p>
  <p style="padding-left: 60px;">(4) Company Box does not accept artwork or text portraying inappropriate, offensive or unlawful subject matter, including nudity, sexual activity, violence, criminal activity, terrorist propaganda or hate speech of any nature. Hate speech includes, but is not limited to, any attack on people based on race, national origin, gender, ethnicity, religious affiliation, sexual orientation or disability. We are also committed to banning content that encourages or promotes any real-world harm, including physical, financial and emotional injury.</p>
  <p style="padding-left: 30px;">b) Company Box takes intellectual property rights seriously and believes that those rights are important to promoting creativity and expression. Accordingly, we ask that you respect other people’s patent, copyright, trademark and other legal rights.</p>
  <p style="padding-left: 30px;">c) You represent and warrant that you either own, or have proper legal permissions to use, all text and graphics you upload to our Site and that your use of such materials does not and will not infringe any third party’s rights. Company Box reserves the right, in its sole discretion, to reject or remove any of the graphics or text that you upload to the Site at any time, and for any reason, without notice. You further represent and warrant that you will defend, indemnify and hold Company Box harmless from and against all claims or losses, without limitation, arising from any text, graphics, or files that you upload to the Site or that are used by us at your direction in any printing we may do for you.</p>
  4) Payment Terms:
  <p style="padding-left: 30px;">a) You are responsible for payment for all purchases and as well as any applicable charges for Internet access services supplied by Site.</p>
  <p style="padding-left: 30px;">b) For all purchases for any products and services sold on the Site, Company Box will bill your credit card or alternative payment method accepted by Company Box (see “Payment Terms” below).</p>
  <p style="padding-left: 30px;">c) We do not provide individual consumer credit account privileges.</p>
  5) Approved Credit Accounts:
  <p style="padding-left: 30px;">a) As a condition of extending business credit privileges, Company Box may require current financial information and/or references. Payment Terms of “Net 30”, or as otherwise agreed, may be granted by Company Box, in its sole discretion, based on the information provided.</p>
  <p style="padding-left: 30px;">b) If credit privileges are extended, Company Box reserves the right to establish and enforce a credit limit on your account. At our sole discretion, we may review and amend the credit limit on your account.</p>
  <p style="padding-left: 30px;">c) Unless otherwise agreed in advance in writing, payment is due within 30 days from the invoice date. Failure to keep your Company Box account current will result in your account being placed on cash-only status. This status may impair your ability to use our services and delay your shipments.</p>
  <p style="padding-left: 30px;">d) IF THE ACCOUNT IS NOT IN GOOD CREDIT STANDING, ORDERS MAY BE HELD OR STOPPED IN TRANSIT UNTIL YOU MAKE ACCEPTABLE ALTERNATIVE PAYMENT ARRANGEMENTS.</p>
  <p style="padding-left: 30px;">e) Credit privileges will not be restored until all past-due balances are paid in full together with any costs, fees and expenses incurred by Company Box in collecting or attempting to collect the balance. Company Box may require you to secure the account with a form of payment as a prerequisite to credit restoration. Company Box may, in its sole discretion, decline to restore credit privileges even if all costs, fees and expenses are paid.</p>
  <p style="padding-left: 30px;">f) Customers requesting removal from cash-only status must contact Company Box Customer Service.</p>
  <p style="padding-left: 30px;">g) At our sole discretion, we may apply payments made on your account to any unpaid invoice in any sequence.</p>
  6) In the event that legal action is necessary to collect on balances due, you agree to reimburse Company Box for all expenses incurred to recover sums due, including attorneys’ fees and other legal expenses.

  7) Risk of Loss; Proof of Delivery
  <p style="padding-left: 30px;">a) Unless otherwise agreed in advance in writing, risk of loss for all purchases of boxes from us passes to you upon our delivery of the shipment to the carrier.</p>
  <p style="padding-left: 30px;">b) The performance of any services does not make us an agent of the shipper or any third party for any purpose.</p>
  <p style="padding-left: 30px;">c) We will, when available, provide proof-of-delivery information for packages delivered.</p>
  8) Routing and Rerouting
  <p style="padding-left: 30px;">a) We will determine the routing of all shipments, including the mode of transportation used, and may use air transportation, ground transportation or any combination thereof in providing our services. We reserve the right to divert any shipment (including use of other carriers) in order to facilitate its delivery.</p>
  b) At our sole discretion, some shipments may not be released without a delivery signature even when release is authorized, including, but not limited to:
  <p style="padding-left: 60px;">(1) Bill-recipient shipments when the recipient’s Company Box account is not in good credit standing.</p>
  <p style="padding-left: 60px;">(2) Indirect deliveries.</p>
  <p style="padding-left: 60px;">(3) The delivery location or circumstances are unsuitable for release without signature as determined at our sole discretion.</p>
  <p style="padding-left: 30px;">c) Company Box may accept a request to reroute a shipment subject to the following conditions:</p>
  <p style="padding-left: 60px;">(1) A special handling charge will be billed to the account number specified on the Company Box for each rerouted package, and it will appear as an address correction on the invoice.</p>
  <p style="padding-left: 60px;">(2) To reroute a shipment, the sender must call 980-215-0960 and provide us with a Company Box account number, the FedEx tracking number, the new destination and a valid contact telephone number for the recipient. A reroute to Hold at Location can also be requested through fedex.com.</p>
  <p style="padding-left: 60px;">(3) We may not honor a reroute request from the recipient other than to Hold at Location within the original destination city.</p>
  <p style="padding-left: 60px;">(4) Only one reroute will be allowed per package.</p>
  <p style="padding-left: 60px;">(5) Any requested change to an address that is not a reroute or an address correction is a new shipment, and new shipping charges will apply.</p>
  9) Undeliverable Shipments
  <p style="padding-left: 30px;">a) An undeliverable shipment is one that cannot be delivered for reasons that include, but are not limited to, any of the following:</p>
  <p style="padding-left: 60px;">(1) The recipient of a Hold at Location shipment cannot be located.</p>
  <p style="padding-left: 60px;">(2) The recipient refuses to accept the shipment prior to, during or after delivery.</p>
  <p style="padding-left: 60px;">(3) The recipient’s delivery address cannot be located.</p>
  <p style="padding-left: 60px;">(4) The recipient’s place of business is closed.</p>
  <p style="padding-left: 60px;">(5) No appropriate person was available to accept the shipment at a delivery location on the initial delivery attempt or reattempts.</p>
  <p style="padding-left: 30px;">b) Package shipments will be returned via FedEx Express Saver service at the shipper’s expense unless contrary instructions are received from the shipper after five business days from the initial delivery attempt. However, nonfreight package shipments will be returned via FedEx Priority Overnight service at no additional charge if the shipment is undeliverable because of a service failure or damage to the shipment caused by FedEx. If the shipment is undeliverable for any other reason, all return charges and fees will be assessed to the original shipper, along with the original transportation charges and fees.</p>
  <p style="padding-left: 30px;">c) If a FedEx Express Freight shipment is marked “Bill Recipient” and is refused or returned to the sender, the billing is automatically changed to “Bill Sender.”</p>
  <p style="padding-left: 30px;">d) All FedEx Express Freight shipments will be returned via FedEx 3-Day Freight. The freight shipment will be returned at no additional charge if the freight shipment is undeliverable because of a service failure. If the shipper requests return via another service, our regular rates will apply. If the freight shipment is undeliverable because of a non-service-failure reason, all return charges and fees will be assessed to the original shipper, along with the original transportation charges and fees.</p>
  10) Return Options
  <p style="padding-left: 30px;">a) Company Box return options are subject to all other terms and conditions provided in these Terms and Conditions. The contents of a return shipment are subject to the same restrictions and prohibited-item limitations as the original shipment.</p>
  <p style="padding-left: 30px;">b) FOR COMPANY BOX RETURNS, NOTWITHSTANDING ANY PAYMENT INSTRUCTIONS THAT ARE GIVEN TO COMPANY BOX, THE PARTY THAT INITIATES A RETURN SHIPMENT TRANSACTION WITH COMPANY BOX IS ULTIMATELY LIABLE FOR, WILL BE BILLED FOR, AND AGREES TO PAY, ALL CHARGES AND PICKUP FEES, INCLUDING ANY SPECIAL HANDLING FEES, REGARDLESS OF ANY PAYMENT INSTRUCTIONS TO THE CONTRARY, IF THE SENDER OR THIRD PARTY FAILS OR REFUSES TO PAY.</p>
  11) Adjustments, Refunds and Credits
  <p style="padding-left: 30px;">a) We will not be liable for, nor will any adjustment, refund or credit of any kind be given as a result of, any loss, damage, delay, missed delivery, non-delivery, misinformation or failure to provide information caused by or resulting in whole or in part from:</p>
  <p style="padding-left: 60px;">(1) the act, default or omission of any person or entity other than Company Box, including those of any local, state or federal government agencies.</p>
  <p style="padding-left: 60px;">(2) the nature of the shipment, including any defect, feature or characteristic of the shipment.</p>
  <p style="padding-left: 60px;">(3) your violation of any of the terms and conditions contained in the Company Box Terms and Conditions, as amended or supplemented, including, but not limited to, the improper or insufficient packing, securing, marking and addressing of shipments, or use of an account number not in good credit standing, or failure to give notices in the manner and time prescribed.</p>
  <p style="padding-left: 60px;">(4) Any force majeure event, perils of the air, public enemies, criminal acts of any person(s) or entities, including, but not limited to, acts of terrorism, public authorities acting with actual or apparent authority, authority of law, local disputes, civil commotion, hazards incident to a state of war, local or national weather conditions, national or local disruptions in air or ground transportation networks (as determined solely by us), strikes or anticipated strikes (of any entity, including, but not limited to, other carriers, vendors or suppliers), labor disruptions or shortages caused by pandemic conditions or other public health event or circumstances, natural disasters (earthquakes, floods and hurricanes are examples of natural disasters), conditions that present a danger to our personnel, and disruption or failure of communication and information systems (including, but not limited to, our systems).</p>
  <p style="padding-left: 60px;">(5) Requests for adjustments or refunds must be received within 60 days from the date of the invoice.</p>
  12) Warranties
  <p style="padding-left: 30px;">a) COMPANY BOX MAKES NO WARRANTIES, EXPRESS OR IMPLIED REGARDING ITS GOODS OR SERVICES. COMPANY BOX EXPRESSLY DISCLAIMS ALL WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. IN NO EVENT WILL COMPANY BOX BE LIABLE FOR ANY REPRESENTATION OR WARRANTY MADE TO ANY CUSTOMER OR THIRD PARTY BY YOU OR ANY OF YOUR EMPLOYEES OR AGENTS. THESE LIMITATIONS SHALL SURVIVE AND APPLY NOTWITHSTANDING THE VALIDITY OF THE LIMITED REMEDIES PROVIDED FOR IN THIS AGREEMENT.</p>
  b) Some jurisdictions do not permit limitations or exclusions of implied warranties in certain transactions, so the above limitations may not apply to you.

  13) DISCLAIMER OF LIABILITIES; LIMITATION
  <p style="padding-left: 30px;">a) COMPANY BOX WILL NOT BE LIABLE FOR ANY CLAIMS, LOSSES OR DAMAGES IN EXCESS OF THE DECLARED VALUE OF A SHIPMENT, WHETHER OR NOT COMPANY BOX KNEW OR SHOULD HAVE KNOWN THAT SUCH CLAIMS, LOSSES OR DAMAGES MIGHT BE INCURRED.</p>
  <p style="padding-left: 30px;">b) IN NO EVENT SHALL COMPANY BOX, INCLUDING, WITHOUT LIMITATION, ITS AGENTS, CONTRACTORS, EMPLOYEES AND AFFILIATES, BE LIABLE FOR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS OR INCOME, WHETHER OR NOT COMPANY BOX HAD KNOWLEDGE THAT SUCH DAMAGES MIGHT BE INCURRED.</p>
  14) Indemnification

  You agree to indemnify, defend and hold harmless Company Box, its officers, directors, employees, shareholders, affiliates, agents, successors and assigns from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of these terms and conditions

  15) Limitations on Legal Actions
  <p style="padding-left: 30px;">a) Any right that you might have to damages, refunds, credits, recovery of reliance interests, disgorgement, restitution, injunctive relief, declaratory relief or any other legal or equitable relief whatsoever against us under any cause of action arising from the transportation shall be extinguished unless you first comply with all applicable notice periods and requirements in these Terms and Conditions including, but not limited to, the periods and requirements for providing notice for billing claims. You and we understand that timely and complete compliance with such notice periods and requirements is a contractual condition precedent to your right to any relief whatsoever, and you must plead compliance with those conditions precedent on the face of any complaint that you file against us.</p>
  <p style="padding-left: 30px;">b) You and we agree that Company Box cannot be considered to have breached any obligation to you unless or until we wrongfully deny a claim submitted to us pursuant to the notice periods and requirements contained in these terms and conditions.</p>
  <p style="padding-left: 30px;">c) Finally, you and we agree that you will comply with applicable notice periods and requirements even if you believe that such compliance will not result in relief from us or if you lack knowledge regarding whether such compliance will result in relief from us.</p>
  <p style="padding-left: 30px;">d) You agree that you will not sue us as a class plaintiff or class representative, join as a class member, or participate as an adverse party in any way in a class-action lawsuit against us. Nothing in this paragraph, however, limits your rights to bring a lawsuit as an individual plaintiff.</p>
  <p style="padding-left: 30px;">e) To the extent that any court finds that state rather than federal law applies to any provision of this contract, the controlling law will be the law of the state where our principal office is located at the time the order was placed.</p>
  16) Severability.<br>
  <p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.</p>

  17) Entire Agreement.<br>
  <p>These Terms and Conditions, including any legal notices, limitations and disclaimers contained on this Site, constitute the entire agreement between Company Box and you in relation to your use of this Site and your purchase of any products or services, and supersede all prior agreements and understandings with respect to the same.</p>

  18) Governing Law &amp; Jurisdiction.<br>

  <p>These Terms and Conditions will be governed by and construed in accordance with the laws of the State of North Carolina, and you agree to be subject to the jurisdiction of the state and federal courts located in North Carolina for the resolution of any disputes.</p>

  <p>**********************</p>

  <p>If you do not understand any of the foregoing Terms and Conditions or if you have any questions or comments, we invite you to contact us at info&#64;Company Box or call our service center. We also have additional information under our Resources – FAQ, SHIPPING POLICY, ART and DESIGN INFO. This information is also part of our TERMS and CONDITIONS.</p>

  <p>If you have questions or concerns regarding Company Box send your correspondence to: Company Box Customer Relations Department, 13347 South Point Blvd., Charlotte, NC 28273 or call 980 215 0961</p>

  <p>© 2018 Company Box LLC. All rights reserved.<br><br>

    No part of these Company Box Terms and Conditions may be reproduced<br>
    in any form or by any process without permission in writing from Company Box LLC.<br>
  </p>

</div>
<div class="modal-footer">
  <div class="text-right">
    <button type="button" class="mr-2 btn btn-primary cb" (click)="hideModal()">OK</button>
    <button type="button" class="mr-2 btn btn-secondary fedex" (click)="hideModal()">OK</button>
  </div>
</div>
