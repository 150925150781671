import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesignService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getPatternCollections(): Promise<any> {
    const url = `${environment.apiHost}/project/builder/pattern/collection/list?published=PUBLISHED`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error.message || error);
        });

    });
  }

  getPatterns(collectionId: number): Promise<any> {
    const url = `${environment.apiHost}/project/builder/pattern/list?collection=${collectionId}&published=PUBLISHED`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  getShapeCollections(): Promise<any> {
    const url = `${environment.apiHost}/project/builder/shape/collection/list?published=PUBLISHED`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  getShapes(collectionId: number): Promise<any> {
    const url = `${environment.apiHost}/project/builder/shape/list?collection=${collectionId}&limit=23&published=PUBLISHED`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error.message || error);
        });
    });
  }

  getDesign(userID: number, containerID: string): Promise<any> {
    const url = `${environment.apiHost}/project/design/${userID}/container/${containerID}`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error);
        });
    });
  }

  createDesign(userID: number, design: any): Promise<any> {
    const url = `${environment.apiHost}/project/design/${userID}/`;

    const payload = JSON.stringify(design);

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, payload, headers)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error);
        });
    });
  }

  updateDesign(userID: number, design: any): Promise<any> {
    const url = `${environment.apiHost}/project/design/${userID}/`;

    const payload = JSON.stringify(design);

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return new Promise<any>((resolve, reject) => {
      this.httpClient.put(url, payload, headers)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error);
        });
    });
  }

  deleteDesign(userId: number, designId: number): Promise<any> {
    const url = `${environment.apiHost}/project/design/${userId}/${designId}`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.delete(url)
        .subscribe(res => {
          resolve(res);
        }, error => {
          reject(error);
        });
    });
  }
}
