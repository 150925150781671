import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import {environment} from '../../environments/environment';
import {formatCurrency, formatNumber} from '@angular/common';
import { Box, BoxType, Subcategory, Category, PaperType, Preset, PricingResponse, PrintType, Variant } from '../shared/models';

@Injectable({
  providedIn: 'root',
})
export class BoxService {
  constructor(
    protected httpClient: HttpClient,
    private logger: NGXLogger
  ) {}

  getPrice(
    boxCode: string,
    boardStrength: string,
    paperType: string,
    length: number,
    width: number,
    depth: number,
    quantity: number,
    twoSided: boolean,
    luxePrint: boolean,
    gloss: boolean,
    noPrint: boolean,
    options: string,
    expedited: boolean
  ): Promise<Box> {
    return new Promise<Box>((resolve, reject) => {
      let url = `${environment.apiHost}/box/price/${boxCode}/${boardStrength}/${paperType}?length=${length}&width=${width}&depth=${depth}&quantity=${quantity}&rush=${expedited}`;
      if (noPrint) {
        url += `&noPrint=${noPrint}`;
      } else {
        url += `&twoSided=${twoSided}&luxe=${luxePrint}&gloss=${gloss}&options=${options}`;
      }

      // url += '&debug=true';

      this.httpClient.get(url).subscribe(
        (res) => {
          const price = new Box().deserialize(res);
          resolve(price);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  getSamplePrice(
    code: any,
    boardStrength: any,
    kraftType: any,
    width?: any,
    length?: any,
    depth?: any,
    gloss?: any,
    luxe?: any,
    twoSided?: any,
    options?: any,
    quantity?: any,
    rush?: any
  ) {
    const params = {
      params: {
        width,
        length,
        depth,
        gloss,
        luxe,
        twoSided,
        quantity,
        options,
        rush,
      },
    };
    return new Promise((resolve, reject) => {
      const url = `${environment.apiHost}/box/price/${code}/${boardStrength}/${kraftType}`;
      this.httpClient.get(url, params).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject('Could not get price');
        }
      );
    });
  }

  listPrices(
    boxCode: string,
    boardStrength: string,
    paperType: string,
    length: number,
    width: number,
    depth: number,
    quantities: number[],
    twoSided: boolean,
    luxePrint: boolean,
    noPrint: boolean,
    options: string,
    expedited: boolean
  ): Promise<PricingResponse> {
    return new Promise<PricingResponse>((resolve, reject) => {
      let url = `${
        environment.apiHost
      }/box/price/${boxCode}/${boardStrength}/${paperType}/list?length=${length}&width=${width}&depth=${depth}&quantities=${quantities.join(
        ','
      )}&rush=${expedited}`;

      if (noPrint) {
        url += `&noPrint=${noPrint}`;
      } else {
        url += `&twoSided=${twoSided}&luxe=${luxePrint}`;
      }
      url += `&includeSubcategories=true&options=${options}&debug=true`;

      this.httpClient.get(url).subscribe(
        (res) => {
          const response = new PricingResponse().deserialize(res);
          resolve(response);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  listTapePrices(paperType: any, quantities: any[]) {
    const url = `${
      environment.apiHost
    }/box/price/tape/${paperType}/list?quantities=${quantities.join(
      ','
    )}&rush=false`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getTapePrice(paperType: any, quantity: any): Promise<any> {
    const url = `${environment.apiHost}/box/price/tape/${paperType}?quantity=${quantity}&rush=false`;

    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(url).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  listPaddedMailerPrices(
    code: string,
    paperType: string,
    quantities: number[],
    noPrint: boolean
  ) {
    const url = `${
      environment.apiHost
    }/box/price/padded-mailer/${code}/${paperType}/list?quantities=${quantities.join(
      ','
    )}&rush=false&noPrint=${noPrint}`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (response: any) => {
          (response.prices as any[]).forEach((price) => {
            this.setPaddedMailerPriceLabel(
              price,
              response.paddedMailer.type.subcategory.allowCustomSample
            );
          });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getPaddedMailerPrice(
    code: string,
    paperType: string,
    quantity: number,
    noPrint: boolean
  ) {
    const url = `${environment.apiHost}/box/price/padded-mailer/${code}/${paperType}?quantity=${quantity}&rush=false&noPrint=${noPrint}`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (response: any) => {
          this.setPaddedMailerPriceLabel(
            response.price,
            response.type.subcategory.allowCustomSample
          );
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  setPaddedMailerPriceLabel(
    price: {
      quantity: number;
      label: string;
      pricePerUnit: number;
      unitsPerCase: string;
    },
    allowCustomSample: any
  ) {
    // console.log(`q ${price.quantity} ${price.quantity === 1 && subcategory.allowCustomSample}`);
    if (price.quantity === 1 && allowCustomSample) {
      price.label = `1 Custom Sample ${formatCurrency(
        price.pricePerUnit,
        'en-us',
        '$'
      )}`;
    } else {
      price.label =
        formatNumber(price.quantity, 'en-us') +
        ' case(s) of ' +
        price.unitsPerCase +
        ' ' +
        formatCurrency(price.pricePerUnit, 'en-us', '$') +
        ' / Mailer';
    }
  }

  listTissuePrices(quantities: any[], weight: any) {
    const url = `${
      environment.apiHost
    }/box/price/tissue/White/${weight}/list?quantities=${quantities.join(
      ','
    )}&rush=false`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getTissuePrice(quantity: any, weight: any) {
    const url = `${environment.apiHost}/box/price/tissue/White/${weight}?quantity=${quantity}&rush=false`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  listCrinklePrices(quantities: any[], color: any) {
    const url = `${
      environment.apiHost
    }/box/price/crinkle/${color}/list?quantities=${quantities.join(',')}`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getCrinklePrice(quantity: any, color: any) {
    const url = `${environment.apiHost}/box/price/crinkle/${color}?quantity=${quantity}`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  listLabelPrices(
    quantities: any[],
    shape: any,
    paperType: any,
    length: any,
    width: any,
    depth: any
  ) {
    const url = `${
      environment.apiHost
    }/box/price/labels/${shape}/${paperType}/list?quantities=${quantities.join(
      ','
    )}&rush=false&length=${length}&width=${width}&depth=${depth}`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getLabelPrice(
    quantity: any,
    shape: any,
    paperType: any,
    length: any,
    width: any,
    depth: any
  ) {
    const url = `${environment.apiHost}/box/price/labels/${shape}/${paperType}?quantity=${quantity}&rush=false&length=${length}&width=${width}&depth=${depth}`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  listPartitionPrices(
    boardType: any,
    depth: any,
    length: any,
    lengthCells: any,
    paperType: any,
    quantities: any[],
    width: any,
    widthCells: any,
    lengthSlots: any,
    widthSlots: any
  ): Promise<PricingResponse> {
    const url = `${
      environment.apiHost
    }/box/price/partitions/${boardType}/${paperType}/list?depth=${depth}&length=${length}&lengthCells=${lengthCells}&quantities=${quantities.join(
      ','
    )}&rush=false&width=${width}&widthCells=${widthCells}&lengthSlots=${lengthSlots}&widthSlots=${widthSlots}`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (res) => {
          const response = new PricingResponse().deserialize(res);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getPartitionPrice(
    boardType: any,
    depth: any,
    length: any,
    lengthCells: any,
    paperType: any,
    quantity: any,
    width: any,
    widthCells: any,
    lengthSlots: any,
    widthSlots: any
  ) {
    const url = `${environment.apiHost}/box/price/partitions/${boardType}/${paperType}?depth=${depth}&length=${length}&lengthCells=${lengthCells}&quantity=${quantity}&rush=false&width=${width}&widthCells=${widthCells}&lengthSlots=${lengthSlots}&widthSlots=${widthSlots}`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getType(code: string): Promise<BoxType> {
    return new Promise<BoxType>((resolve, reject) => {
      const url = `${environment.apiHost}/box/type/${code}`;
      this.httpClient.get(url).subscribe(
        (res) => {
          const box = new BoxType().deserialize(res);
          resolve(box);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  listTypes(): Promise<BoxType[]> {
    return new Promise<BoxType[]>((resolve, reject) => {
      const url = `${environment.apiHost}/box/type/all`;
      this.httpClient.get(url).subscribe(
        (res) => {
          const boxes: BoxType[] | PromiseLike<BoxType[]> = [];
          (res as any[]).forEach((value) => {
            const box = new BoxType().deserialize(value);
            boxes.push(box);
          });
          this.logger.debug('boxes', boxes);
          resolve(boxes);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  getCategory(
    categoryCode: string,
    includeSubcategories: boolean
  ): Promise<Category> {
    return new Promise<Category>((resolve, reject) => {
      let url = `${environment.apiHost}/box/category/${categoryCode}?`;
      if (includeSubcategories) {
        url += `includeCategories=true&`;
      }
      this.httpClient.get(url).subscribe(
        (res) => {
          const category = new Category().deserialize(res);
          this.logger.debug('category', category);
          resolve(category);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }
  getSubcategory(subcategoryCode: string): Promise<Subcategory> {
    return new Promise<Subcategory>((resolve, reject) => {
      const url = `${environment.apiHost}/box/category/subcategory/${subcategoryCode}`;
      this.httpClient.get(url).subscribe(
        (res) => {
          const subcategory = new Subcategory().deserialize(res);
          this.logger.debug('subcategory', subcategory);
          resolve(subcategory);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  listCategories(includeSubcategories: boolean): Promise<Category[]> {
    return new Promise<Category[]>((resolve, reject) => {
      let url = `${environment.apiHost}/box/category/list?`;
      if (includeSubcategories) {
        url += `includeCategories=true`;
      }

      this.httpClient.get(url).subscribe(
        (res) => {
          const categories: Category[] | PromiseLike<Category[]> = [];
          (res as any[]).forEach((value) => {
            const category = new Category().deserialize(value);
            categories.push(category);
          });
          // this.logger.debug('categories', categories);
          resolve(categories);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  listPresets(
    categoryCode: string,
    subcategoryCode: string
  ): Promise<Preset[]> {
    return new Promise<Preset[]>((resolve, reject) => {
      const url = `${environment.apiHost}/box/category/${categoryCode}/${subcategoryCode}/presets/list?includeSubcategories=true`;
      this.httpClient.get(url).subscribe(
        (res) => {
          const presets: Preset[] | PromiseLike<Preset[]> = [];
          (res as any[]).forEach((value) => {
            const preset = new Preset().deserialize(value);
            presets.push(preset);
          });
          // this.logger.debug('presets', presets);
          resolve(presets);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  getPaperTypes(selectedSubcategory: Subcategory): PaperType[] {
    const paperTypes = [];
    if (selectedSubcategory?.displayPremiumWhite) {
      const paperType = new PaperType().deserialize({
        id: 'pkw',
        name: 'Premium White',
        finishes: ['Gloss', 'Matte'],
      });
      if (selectedSubcategory?.displayStandard) {
        paperType.printTypes.push(
          new PrintType().deserialize({
            id: 'standard',
            name: 'Standard',
            allowed: true,
          })
        );
      }
      if (selectedSubcategory?.displayLuxe) {
        paperType.printTypes.push(
          new PrintType().deserialize({
            id: 'luxe',
            name: 'Luxe',
            allowed: true,
          })
        );
      }
      paperTypes.push(paperType);
    }
    if (selectedSubcategory?.displayWhite) {
      const paperType = new PaperType().deserialize({
        id: 'kw',
        name: 'Standard White',
        finishes: ['Matte'],
      });
      if (selectedSubcategory?.displayStandard) {
        paperType.printTypes.push(
          new PrintType().deserialize({
            id: 'standard',
            name: 'Standard',
            allowed: true,
          })
        );
      }
      if (selectedSubcategory?.displayLuxe) {
        paperType.printTypes.push(
          new PrintType().deserialize({
            id: 'luxe',
            name: 'Luxe',
            allowed: true,
          })
        );
      }
      paperTypes.push(paperType);
    }
    if (selectedSubcategory?.displayKraft) {
      const paperType = new PaperType().deserialize({
        id: 'k',
        name: 'Kraft',
        finishes: ['Matte'],
      });
      if (selectedSubcategory?.displayStandard) {
        paperType.printTypes.push(
          new PrintType().deserialize({
            id: 'standard',
            name: 'Standard',
            allowed: true,
          })
        );
      }
      if (selectedSubcategory?.displayLuxe) {
        paperType.printTypes.push(
          new PrintType().deserialize({
            id: 'luxe',
            name: 'Luxe',
            allowed: false,
          })
        );
      }
      paperTypes.push(paperType);
    }
    paperTypes.forEach((paperType) => {
      if (selectedSubcategory.displaySecondaryWhite) {
        paperType.secondary.push('White');
      }
      if (selectedSubcategory.displaySecondaryKraft) {
        paperType.secondary.push('Kraft');
      }
    });
    return paperTypes;
  }

  getVariants(subcategoryId: number) {
    const url = `${environment.apiHost}/box/category/subcategory/${subcategoryId}/list?includeSubcategory=true`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (res) => {
          const variants: Variant[] = [];
          (res as any[]).forEach((value) => {
            const variant = new Variant().deserialize(value);
            variants.push(variant);
          });
          resolve(variants);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }
}
