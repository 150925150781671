<div class="page-container">
  @if (templateLoading) {
  <app-loading-modal
    [content]="'loading template'"
    [showLoadingBar]="true"
  ></app-loading-modal>
  } @if (!pageLoaded) {
  <app-loading-modal [content]="'loading options'"></app-loading-modal>
  } @if (downloadingTemplate) {
  <app-loading-modal [content]="'downloading template'" [showLoadingBar]="true">
  </app-loading-modal>
  }
  <app-page-content-wrapper [goBack]="goBack">
    <div class="page-title">
      <h1>Select Your Size & Specs</h1>
    </div>
    <div class="page-content">
      @if (presets) {
      <div class="row">
        <div class="left-column">
          <div
            class="preset-size-container"
            [class.custom-size-active]="customSizeActive"
          >
            <div class="preset-size-content container-fluid pl-2 pr-0">
              <div class="row">
                <div class="col-12">
                  <form>
                    @if (hasVariants) {
                    <div class="form-group">
                      @if (subcategoryCode.includes('SNAP2')) {
                      <mat-label
                        ><span>Tape Strength</span>
                        @if (this.subcategoryCode === 'SNAP2R') {
                        <a
                          class="hide-during-mobile"
                          href="javascript:"
                          (click)="showModal(styleInfo)"
                        >
                          <img
                            src="assets/cb/images/information-icon.png"
                            alt="Information"
                            class="info-icon"
                        /></a>
                        } @if (this.subcategoryCode === 'SNAP2') {
                        <a
                          class="hide-during-mobile"
                          href="javascript:"
                          (click)="showModal(styleInfo1)"
                        >
                          <img
                            src="assets/cb/images/information-icon.png"
                            alt="Information"
                            class="info-icon"
                        /></a>
                        }
                      </mat-label>
                      } @if (!subcategoryCode.includes('SNAP2')) {
                      <mat-label>Choose a Lid</mat-label>
                      }
                      <mat-form-field appearance="outline">
                        <mat-select
                          id="variants"
                          name="variants"
                          [(ngModel)]="selectedVariant"
                          disableOptionCentering
                        >
                          @for ( variant of variants; track trackByFn($index,
                          variant)) {
                          <mat-option
                            [value]="variant"
                            (click)="getPresets()"
                            >{{ variant.name }}</mat-option
                          >
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    } @if (isLabel) {
                    <div class="form-group">
                      <mat-label>Label Shape</mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          id="labelShape"
                          name="labelShape"
                          [(ngModel)]="shape"
                          (selectionChange)="getLabel()"
                          disableOptionCentering
                        >
                          @for ( shape of shapeOptions; track trackByFn($index,
                          shape)) {
                          <mat-option [value]="shape">{{ shape }} </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    } @if (!customSizeEntered && !isTape) {
                    <div class="form-group box-size mb-20" appearance="outline">
                      <mat-label for="preset"
                        >Choose @if (!isOtherProduct && !isPaddedMailer) {
                        <span>Box</span>
                        } @if (!isCrinkle && !isPaddedMailer) {
                        <span>
                          Size @if (!isPizza && !isPaddedMailer) {
                          <span>
                            (L x W@if (!isLabel) {
                            <span> x D</span>
                            }</span
                          >
                          }
                        </span>
                        } @if (isPaddedMailer) {
                        <span> an Interior Size (W x D</span>
                        }) @if (isCrinkle) {
                        <span> a Color</span>
                        } @if (!isOtherProduct && !isPaddedMailer) {
                        <a
                          class="hide-during-mobile"
                          href="javascript:"
                          (click)="showModal(boxSizeInfo)"
                        >
                          <img
                            src="assets/fedex/images/240px-Minimalist_info_Icon.png"
                            alt="Information"
                            class="info-icon"
                        /></a>
                        }
                      </mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          id="preset"
                          name="preset"
                          [(ngModel)]="selectedPreset"
                          (selectionChange)="updateForm()"
                          disableOptionCentering
                        >
                          @for (preset of presets; track trackByFn($index,
                          preset)) { @if (!preset.label) {
                          <mat-option [value]="preset">
                            {{ getWholeNumber(preset.length) }}
                            @if ( convertDecimalToFraction( preset.length )[0]
                            !== '0' ) {
                            <span
                              ><sup>{{
                                convertDecimalToFraction(preset.length)[0]
                              }}</sup
                              >&frasl;<sub>{{
                                convertDecimalToFraction(preset.length)[1]
                              }}</sub></span
                            >
                            } x {{ getWholeNumber(preset.width) }}
                            @if ( convertDecimalToFraction( preset.width )[0]
                            !== '0' ) {
                            <span
                              ><sup>{{
                                convertDecimalToFraction(preset.width)[0]
                              }}</sup
                              >&frasl;<sub>{{
                                convertDecimalToFraction(preset.width)[1]
                              }}</sub></span
                            >
                            } @if (!isLabel) {
                            <span> x {{ getWholeNumber(preset.depth) }} </span>
                            }@if ( convertDecimalToFraction( preset.depth )[0]
                            !== '0' ) {
                            <span
                              ><sup>{{
                                convertDecimalToFraction(preset.depth)[0]
                              }}</sup
                              >&frasl;<sub>{{
                                convertDecimalToFraction(preset.depth)[1]
                              }}</sub></span
                            >
                            }
                          </mat-option>
                          } @if (preset.label) {
                          <mat-option [value]="preset">{{
                            preset.label
                          }}</mat-option>
                          } }
                        </mat-select>
                      </mat-form-field>
                    </div>
                      @if (allowCustomSizes) {
                      <button
                        class="toggle-button mt-1" style="z-index:100"
                        (click)="customSizeActive = true; customSizeEntered = true">
                        Enter a Custom Size
                      </button>
                      }
                    } @if (customSizeEntered && !isTape) {
                    <div class="form-group mb-20">
                      <div class="title-table">
                        <div class="left-col section-title">
                          Choose a @if (!isOtherProduct) {
                          <span>Box</span>
                          } Size
                        </div>
                        @if (!isTissue) {
                        <div class="right-col">
                          <button
                            class="toggle-button mt-1"
                            (click)="customSizeActive = true">
                            Edit Custom Size
                          </button>
                        </div>
                        }
                      </div>
                      <div class="custom-size-results-container">
                        <table class="custom-size-results-table">
                          <tr>
                            <td>Length:</td>
                            <td>
                              {{ getWholeNumber(length) }}
                              @if ( convertDecimalToFraction(length)[0] !== '0'
                              ) {
                              <span>
                                <sup>{{
                                  convertDecimalToFraction(length)[0]
                                }}</sup
                                >&frasl;<sub>{{
                                  convertDecimalToFraction(length)[1]
                                }}</sub></span
                              >
                              } (in)
                            </td>
                          </tr>
                          <tr>
                            <td>Width:</td>
                            <td>
                              {{ getWholeNumber(width) }}
                              @if ( convertDecimalToFraction(width)[0] !== '0' )
                              {
                              <span>
                                <sup>{{
                                  convertDecimalToFraction(width)[0]
                                }}</sup
                                >&frasl;<sub>{{
                                  convertDecimalToFraction(width)[1]
                                }}</sub></span
                              >
                              } (in)
                            </td>
                          </tr>
                          @if (!isTissue) {
                          <tr>
                            @if (!isDHC) {
                            <td>Depth:</td>
                            } @if (isDHC) {
                            <td>Radius:</td>
                            }
                            <td>
                              @if (!isDHC || depth === 0) {
                              <span>{{ getWholeNumber(depth) }} </span>
                              }@if ( convertDecimalToFraction(depth)[0] !== '0'
                              ) {
                              <span>
                                <sup>{{
                                  convertDecimalToFraction(depth)[0]
                                }}</sup
                                >&frasl;<sub>{{
                                  convertDecimalToFraction(depth)[1]
                                }}</sub></span
                              >
                              } (in)
                            </td>
                          </tr>
                          }
                          <tr>
                            <td class="lh-15">Board Type:</td>
                            <td>{{ selectedBoardType?.name }}</td>
                          </tr>
                          <tr>
                            <td class="lh-15">Board Strength:</td>
                            <td>{{ selectedBoardStrength?.name }}</td>
                          </tr>
                        </table>
                      </div>
                      @if (!isDHC && !isOtherProduct) {
                      <button
                        class="toggle-button mt-1"
                        (click)="
                          customSizeEntered = false;
                          selectedPreset = presets[0];
                          updateForm()
                        "
                      >
                        Go Back to Standard Sizes
                      </button>
                      }
                    </div>
                    } @if (isTape) {
                    <div class="form-group">
                      <mat-label>Length of Repeating Pattern</mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          id="tapeLength"
                          name="tapeLength"
                          [(ngModel)]="length"
                          disableOptionCentering
                        >
                          @for ( tapeLength of tapeLengthOptions; track
                          trackByFn($index, tapeLength)) {
                          <mat-option [value]="tapeLength"
                            >{{ tapeLength }}
                            inches
                          </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    } @if (isPartition) {
                    <div class="form-group">
                      <mat-label>Number of Horizontal Cells</mat-label>
                      <input
                        matInput
                        class="mat-input"
                        id="lengthSlots"
                        name="lengthSlots"
                        [(ngModel)]="lengthSlots"
                        (change)="updateForm()"
                      />
                    </div>
                    } @if (isPartition) {
                    <div class="form-group">
                      <mat-label>Number of Vertical Cells</mat-label>
                      <input
                        matInput
                        class="mat-input"
                        id="widthSlots"
                        name="widthSlots"
                        [(ngModel)]="widthSlots"
                        (change)="updateForm()"
                      />
                    </div>
                    } @if (isPartition) {
                    <div class="form-group">
                      <mat-label>Choose a Cell Length</mat-label>
                      <input
                        matInput
                        class="mat-input"
                        id="cellLength"
                        name="cellLength"
                        [(ngModel)]="cellLength"
                        (change)="updateForm()"
                      />
                    </div>
                    } @if (isPartition) {
                    <div class="form-group">
                      <mat-label>Choose a Cell Width</mat-label>
                      <input
                        matInput
                        class="mat-input"
                        id="cellWidth"
                        name="cellWidth"
                        [(ngModel)]="cellWidth"
                        (change)="updateForm()"
                      />
                    </div>
                    } @if ( !isCrinkle && !isTape && !isTissue && !isLabel &&
                    !isPartition && !isPaddedMailer ) {
                    <div class="form-group">
                      <mat-label
                        >Printing
                        <a
                          class="hide-during-mobile"
                          href="javascript:"
                          (click)="showModal(printingInfo)"
                          ><img
                            src="assets/fedex/images/240px-Minimalist_info_Icon.png"
                            alt="Information"
                            class="info-icon" /></a
                      ></mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          name="printing"
                          [(ngModel)]="printing"
                          disableOptionCentering
                        >
                          @for ( printing of printingOptions; track
                          trackByFn($index, printing)) {
                          <mat-option
                            (click)="printingSelected(printing)"
                            [value]="printing"
                            >{{ printing }}</mat-option
                          >
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    } @if (isPartition && materialOptions.length > 1) {
                    <div class="form-group">
                      @if (material != 'Kraft') {
                      <mat-label
                        >Material
                        <a href="javascript:" (click)="showModal(materialInfo)"
                          ><img
                            src="assets/fedex/images/240px-Minimalist_info_Icon.png"
                            alt="Information"
                            class="info-icon" /></a
                      ></mat-label>
                      } @if (material == 'Kraft') {
                      <mat-label
                        >Material
                        <a href="javascript:" (click)="showModal(kraftInfo)"
                          ><img
                            src="assets/fedex/images/240px-Minimalist_info_Icon.png"
                            alt="Information"
                            class="info-icon" /></a
                      ></mat-label>
                      }
                      <mat-form-field appearance="outline">
                        <mat-select
                          name="material"
                          disableOptionCentering="true"
                          [(ngModel)]="material"
                          (selectionChange)="updateForm()"
                        >
                          @for ( material of materialOptions; track
                          trackByFn($index, material)) {
                          <mat-option [value]="material">{{
                            material
                          }}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    } @if (isTape && materialOptions.length > 1) {
                    <div class="form-group">
                      @if (material != 'Kraft') {
                      <mat-label
                        >Material
                        <a href="javascript:" (click)="showModal(materialInfo)"
                          ><img
                            src="assets/fedex/images/240px-Minimalist_info_Icon.png"
                            alt="Information"
                            class="info-icon" /></a
                      ></mat-label>
                      } @if (material == 'Kraft') {
                      <mat-label
                        >Material
                        <a href="javascript:" (click)="showModal(kraftInfo)"
                          ><img
                            src="assets/fedex/images/240px-Minimalist_info_Icon.png"
                            alt="Information"
                            class="info-icon" /></a
                      ></mat-label>
                      }
                      <mat-form-field appearance="outline">
                        <mat-select
                          name="material"
                          disableOptionCentering="true"
                          [(ngModel)]="material"
                          (selectionChange)="updateForm()"
                        >
                          @for ( material of materialOptions; track
                          trackByFn($index, material)) {
                          <mat-option [value]="material">{{
                            material
                          }}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    } @if ( !isLabel && !isCrinkle && !isTissue && !isPartition
                    && !isTape ) {
                    <div class="form-group">
                      <mat-label
                        >@if (!isPaddedMailer) {
                        <span>Outer </span>
                        }Material
                        <a href="javascript:" (click)="showModal(materialInfo)"
                          ><img
                            src="assets/fedex/images/240px-Minimalist_info_Icon.png"
                            alt="Information"
                            class="info-icon" /></a
                      ></mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          name="outerMaterial"
                          disableOptionCentering="true"
                          [(ngModel)]="outerMaterial"
                          (selectionChange)="updateForm()"
                        >
                          @for ( outerMaterial of outerMaterialOptions; track
                          trackByFn($index, outerMaterial)) {
                          <mat-option [value]="outerMaterial">{{
                            outerMaterial
                          }}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    } @if ( !isLabel && !isCrinkle && !isTissue && !isPartition
                    && !isTape && !isPaddedMailer ) {
                    <div class="form-group">
                      <mat-label
                        >Inner Material
                        <a href="javascript:" (click)="showModal(materialInfo)"
                          ><img
                            src="assets/fedex/images/240px-Minimalist_info_Icon.png"
                            alt="Information"
                            class="info-icon" /></a
                      ></mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          name="innerMaterial"
                          disableOptionCentering="true"
                          [(ngModel)]="innerMaterial"
                          (selectionChange)="updateForm()"
                        >
                          @for ( innerMaterial of innerMaterialOptions; track
                          trackByFn($index, innerMaterial)) {
                          <mat-option [value]="innerMaterial">{{
                            innerMaterial
                          }}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    } @if ( !isLabel && !isCrinkle && !isTissue &&
                    !isPaddedMailer ) {
                    <div class="form-group">
                      <mat-label
                        >Finish
                        <a href="javascript:" (click)="showModal(finishInfo)"
                          ><img
                            src="assets/fedex/images/240px-Minimalist_info_Icon.png"
                            alt="Information"
                            class="info-icon" /></a
                      ></mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          name="finish"
                          disableOptionCentering="true"
                          [(ngModel)]="finish"
                          (selectionChange)="updateForm()"
                        >
                          @for ( finish of finishOptions; track
                          trackByFn($index, finish)) {
                          <mat-option [value]="finish">{{ finish }}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    }
                    <div class="form-group">
                      @if (!customQuantityActive) {
                      <mat-label for="quantity" [class.disabled]="!prices">
                        Quantity @if (isCrinkle) {<span>(10LB per Box)</span>}
                        @if (isTape) {<span>(8 rolls per case)</span>}
                      </mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select
                          id="quantity"
                          name="quantity"
                          [(ngModel)]="selectedPrice"
                          [disabled]="!prices"
                          (focusout)="
                            enteredCustomQuantity =
                              selectedPrice!.quantity.toString()
                          "
                          disableOptionCentering
                        >
                          @for ( price of prices | slice : 0 :
                          standardQuantities.length; track trackByFn(i, price);
                          let i = $index) {
                          <mat-option
                            (click)="quantityArrayIndex = i"
                            [value]="price"
                          >
                            @if ( !isTape && !isTissue && !isCrinkle && !isPizza
                            && !isPaddedMailer ) {
                            {{ price.quantity | number }} ({{
                              price.pricePerUnit | currency
                            }}
                            each) } @else if(isPaddedMailer) {
                            {{ price.label }}
                            } @else if(isTape) {
                            {{ price.quantity | number }} case(s) ({{
                              price.pricePerCase ?? 0 / 8 | currency
                            }}
                            / Roll) } @else if(isCrinkle) {
                            {{ price.quantity | number }} box(s) ({{
                              price.pricePerUnit | currency
                            }}
                            / Box) } @else if(isTissue) {
                            {{ price.totalSheets | number }} sheets ({{
                              price.pricePerReam / 100 | currency
                            }}
                            per sheet) } @else if(isPizza) {
                            {{ price.quantity | number }} ({{
                              price.pricePerUnit | currency
                            }}
                            each) }
                          </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>

                      } @else if (customQuantityActive) {
                      <mat-label for="quantity">Quantity</mat-label>
                      <input
                        matInput
                        class="mat-input"
                        id="quantity"
                        name="quantity"
                        autocomplete="off"
                        [(ngModel)]="enteredCustomQuantity"
                        (keypress)="numberOnly($event)"
                        (focusout)="checkForViolations()"
                        (focusin)="stripText()"
                        (keydown.enter)="$event.preventDefault()"
                      />
                      } 
                      </div>
                      @if (!customQuantityActive && !isPizza) {
                      <button
                        class="toggle-button mt-1"
                        (click)="
                          customQuantityActive = !customQuantityActive;
                          customQuantityEntered = !customQuantityEntered;
                          enteredCustomQuantity =
                            selectedPrice!.quantity.toString()
                        "
                      >
                        Enter a Custom Quantity
                      </button>
                      } @if (customQuantityActive) {
                      <button
                        class="toggle-button mt-1"
                        (click)="
                          customQuantityActive = !customQuantityActive;
                          customQuantityEntered = !customQuantityEntered;
                          customQuantityViolation = false
                        "
                      >
                        Select a Preset Quantity
                      </button>
                      } @if (customQuantityViolation) {
                      <div class="custom-quantity-disclaimer">
                        This item has a minimum quantity of @if (luxe) {
                        <span>{{ prices[0].luxeMinimumOrder }}</span>
                        }@if (!luxe) {
                        <span>{{ prices[0].minimumOrder }}</span>
                        } and a maximum quantity of {{ prices[0].maxQuantity }}.
                        Please adjust your quantity to meet these requirements.
                      </div>
                      } @if (tissueIncrementViolation) {
                      <div class="custom-quantity-disclaimer">
                        This item must be ordered in increments of 100 sheets.
                        Please adjust your quantity to meet these requirements.
                      </div>
                      }
                    @if (isPartition && violations.length > 0) {
                    <div class="violations-container">
                      @for ( violation of violations; track trackByFn($index,
                      violation)) {
                      <div class="violation-content">
                        {{ violation.message }}
                      </div>
                      }
                    </div>
                    }
                    <div class="total-container">
                      <div class="total-label">
                        SUBTOTAL: @if (selectedPrice && prices) {
                        <span>{{ selectedPrice.totalPrice | currency }}</span>
                        }@if (!prices) {
                        <span>
                          <span class="one-dot"> . </span
                          ><span class="two-dot">. </span
                          ><span class="three-dot">. </span>
                        </span>
                        }
                      </div>
                      <div class="web-only">
                        <div class="button-container">
                          @if ( !uploadDesign && !isPartition && !isCrinkle &&
                          printing !== 'Blank Boxes | No Printing' ) {
                          <button
                            type="button"
                            [disabled]="!prices || customQuantityViolation"
                            (click)="createBasicContainer()"
                            class="orange-submit"
                            [ngClass]="{ disabled: !prices }"
                          >
                            DESIGN ONLINE
                          </button>
                          } @if (!isCrinkle && !isPartition) {
                          <button
                            type="button"
                            (click)="createAdvancedContainer()"
                            class="orange-outline mt-2"
                          >
                            UPLOAD YOUR DESIGN
                          </button>
                          } @if ( uploadDesign && printing !== 'Blank Boxes | No Printing' && !isCrinkle && !isPartition ) {
                          <button
                            type="button"
                            [disabled]="!prices || customQuantityViolation"
                            (click)="createAdvancedContainer()"
                            class="white-submit"
                            [ngClass]="{ disabled: !prices }"
                            role="submit"
                          >
                            LOAD TEMPLATE
                          </button>
                          } @if (isPartition) {
                          <button
                            type="button"
                            [disabled]="
                              !prices ||
                              customQuantityViolation ||
                              violations.length > 0
                            "
                            (click)="addPartitionToCart()"
                            class="white-submit"
                            [ngClass]="{ disabled: !prices }"
                            role="submit"
                          >
                            ADD TO CART
                          </button>
                          } @if (isCrinkle) {
                          <button
                            type="button"
                            [disabled]="
                              !prices ||
                              customQuantityViolation ||
                              violations.length > 0
                            "
                            (click)="addCrinkleToCart()"
                            class="white-submit"
                            [ngClass]="{ disabled: !prices }"
                            role="submit"
                          >
                            ADD TO CART
                          </button>
                          } @if (printing === 'Blank Boxes | No Printing') {
                          <button
                            type="button"
                            [disabled]="!prices || customQuantityViolation"
                            (click)="showModal(blankbox)"
                            class="white-submit"
                            [ngClass]="{ disabled: !prices }"
                            role="submit"
                          >
                            ADD TO CART
                          </button>
                          }
                        </div>
                        @if (!isPartition && !isCrinkle) {
                        <div class="or-label mt-5 d-block">Want to Design Elsewhere?</div>
                        <div class="download-container">
                          <a
                            href="javascript:"
                            (click)="showModal(downloadTemplate)"
                            >Download PDF Template Here</a
                          >
                        </div>
                        }
                        <br />
                      </div>
                      <div class="mobile-tab-only">
                        <div class="button-container">
                          <button
                            type="button"
                            (click)="saveProject()"
                            class="white-submit"
                          >
                            SAVE
                          </button>
                        </div>
                      </div>
                      @if (customSizeEntered && !isOtherProduct) {
                      <div class="box-info">
                        It may take up to 90 seconds to generate your custom
                        template.<br />
                        Thanks for your patience!
                      </div>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            class="custom-size-container"
            [class.custom-size-active]="customSizeActive"
          >
            <div class="custom-size-content">
              <div class="title-table">
                <div class="left-col section-title bigger">
                  Enter Custom Size
                </div>
              </div>
              <form>
                <div class="form-title section-title">Inside Dimensions</div>
                <div class="table-container">
                  <table>
                    <tr>
                      <th></th>
                      <th>Whole Inches</th>
                      <th>Fractions</th>
                    </tr>
                    <tr>
                      <td>LENGTH</td>
                      <td>
                        @if (subcategoryCode !== 'OPF') {
                        <input
                          sumatInput
                          name="lengthInput"
                          [(ngModel)]="lengthWhole"
                          (change)="checkForPresets()"
                          type="number"
                          min="1"
                        />
                        } @if (subcategoryCode === 'OPF') {
                        <input
                          sumatInput
                          name="lengthInput"
                          [(ngModel)]="lengthWhole"
                          (change)="checkForPresets()"
                          type="number"
                          min="4"
                        />
                        }
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <mat-select
                            name="length"
                            disableOptionCentering="true"
                            (selectionChange)="checkForPresets()"
                            [(ngModel)]="lengthFraction"
                          >
                            <mat-option label="0" [value]="0">0</mat-option>
                            <mat-option label='1/16"' [value]="0.0625"
                              ><sup>1</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='1/8"' [value]="0.125"
                              ><sup>1</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='3/16"' [value]="0.1875"
                              ><sup>3</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='1/4"' [value]="0.25"
                              ><sup>1</sup>&frasl;<sub>4</sub>"</mat-option
                            >
                            <mat-option label='5/16"' [value]="0.3125"
                              ><sup>5</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='3/8"' [value]="0.375"
                              ><sup>3</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='7/16"' [value]="0.4375"
                              ><sup>7</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='1/2"' [value]="0.5"
                              ><sup>1</sup>&frasl;<sub>2</sub>"</mat-option
                            >
                            <mat-option label='9/16"' [value]="0.5625"
                              ><sup>9</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='5/8"' [value]="0.625"
                              ><sup>5</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='11/16"' [value]="0.6875"
                              ><sup>11</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='3/4"' [value]="0.75"
                              ><sup>3</sup>&frasl;<sub>4</sub>"</mat-option
                            >
                            <mat-option label='13/16"' [value]="0.8125"
                              ><sup>13</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='7/8"' [value]="0.875"
                              ><sup>7</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='15/16"' [value]="0.9375"
                              ><sup>15</sup>&frasl;<sub>16</sub>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>WIDTH</td>
                      <td>
                        <input
                          matInput
                          name="widthInput"
                          [(ngModel)]="widthWhole"
                          (change)="checkForPresets()"
                          type="number"
                          min="1"
                        />
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <mat-select
                            name="width"
                            disableOptionCentering="true"
                            (selectionChange)="checkForPresets()"
                            [(ngModel)]="widthFraction"
                          >
                            <mat-option label="0" [value]="0">0</mat-option>
                            <mat-option label='1/16"' [value]="0.0625"
                              ><sup>1</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='1/8"' [value]="0.125"
                              ><sup>1</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='3/16"' [value]="0.1875"
                              ><sup>3</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='1/4"' [value]="0.25"
                              ><sup>1</sup>&frasl;<sub>4</sub>"</mat-option
                            >
                            <mat-option label='5/16"' [value]="0.3125"
                              ><sup>5</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='3/8"' [value]="0.375"
                              ><sup>3</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='7/16"' [value]="0.4375"
                              ><sup>7</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='1/2"' [value]="0.5"
                              ><sup>1</sup>&frasl;<sub>2</sub>"</mat-option
                            >
                            <mat-option label='9/16"' [value]="0.5625"
                              ><sup>9</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='5/8"' [value]="0.625"
                              ><sup>5</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='11/16"' [value]="0.6875"
                              ><sup>11</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='3/4"' [value]="0.75"
                              ><sup>3</sup>&frasl;<sub>4</sub>"</mat-option
                            >
                            <mat-option label='13/16"' [value]="0.8125"
                              ><sup>13</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='7/8"' [value]="0.875"
                              ><sup>7</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='15/16"' [value]="0.9375"
                              ><sup>15</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      @if (!isDHC) {
                      <td>DEPTH</td>
                      } @if (isDHC) {
                      <td>RADIUS</td>
                      } @if (!isDHC) {
                      <td>
                        <input
                          matInput
                          name="depthInput"
                          [(ngModel)]="depthWhole"
                          (change)="checkForPresets()"
                          type="number"
                          [class.error]="depthError"
                          min="1"
                        />
                      </td>
                      } @if (!isDHC) {
                      <td>
                        <mat-form-field appearance="outline">
                          <mat-select
                            name="depth"
                            disableOptionCentering="true"
                            (selectionChange)="checkForPresets()"
                            [(ngModel)]="depthFraction"
                          >
                            <mat-option label="0" [value]="0">0</mat-option>
                            <mat-option label='1/16"' [value]="0.0625"
                              ><sup>1</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='1/8"' [value]="0.125"
                              ><sup>1</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='3/16"' [value]="0.1875"
                              ><sup>3</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='1/4"' [value]="0.25"
                              ><sup>1</sup>&frasl;<sub>4</sub>"</mat-option
                            >
                            <mat-option label='5/16"' [value]="0.3125"
                              ><sup>5</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='3/8"' [value]="0.375"
                              ><sup>3</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='7/16"' [value]="0.4375"
                              ><sup>7</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='1/2"' [value]="0.5"
                              ><sup>1</sup>&frasl;<sub>2</sub>"</mat-option
                            >
                            <mat-option label='9/16"' [value]="0.5625"
                              ><sup>9</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='5/8"' [value]="0.625"
                              ><sup>5</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='11/16"' [value]="0.6875"
                              ><sup>11</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='3/4"' [value]="0.75"
                              ><sup>3</sup>&frasl;<sub>4</sub>"</mat-option
                            >
                            <mat-option label='13/16"' [value]="0.8125"
                              ><sup>13</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                            <mat-option label='7/8"' [value]="0.875"
                              ><sup>7</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='15/16"' [value]="0.9375"
                              ><sup>15</sup>&frasl;<sub>16</sub>"
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </td>
                      } @if (isDHC) {
                      <td class="radius-fraction">
                        <mat-form-field appearance="outline">
                          <mat-select
                            name="depth"
                            disableOptionCentering="true"
                            (selectionChange)="checkForPresets()"
                            [(ngModel)]="depthFraction"
                          >
                            <mat-option label="0" [value]="0">0</mat-option>
                            <mat-option label='1/4"' [value]="0.25"
                              ><sup>1</sup>&frasl;<sub>4</sub>"</mat-option
                            >
                            <mat-option label='3/8"' [value]="0.375"
                              ><sup>3</sup>&frasl;<sub>8</sub>"
                            </mat-option>
                            <mat-option label='1/2"' [value]="0.5"
                              ><sup>1</sup>&frasl;<sub>2</sub>"</mat-option
                            >
                            <mat-option label="1" [value]="1">1</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </td>
                      }
                    </tr>
                    @if (depthError) {
                    <tr class="error-message-row">
                      <td></td>
                      <td colspan="2">
                        <div class="error-message">
                          Depth must be greater than or equal to 1.25"
                        </div>
                      </td>
                    </tr>
                    }
                  </table>
                </div>
                @if (!isDHC && !isOtherProduct) {
                <button
                  class="toggle-button mt-1"
                  (click)="
                    customSizeActive = false;
                    customSizeEntered = false;
                    selectedPreset = presets[0];
                    updateForm()
                  "
                >
                  Go Back to Standard Sizes
                </button>
                }
                <div class="form-group board-type-group">
                  <mat-label
                    >Board Type
                    <a href="javascript:" (click)="showModal(boardTypeInfo)"
                      ><img
                        src="assets/cb/images/information-icon.png"
                        alt="Information"
                        class="info-icon" /></a
                  ></mat-label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      name="boardType"
                      id="boardType"
                      disableOptionCentering
                      [(ngModel)]="selectedBoardType"
                      (selectionChange)="updateForm()"
                    >
                      @for ( boardType of boardTypeList; track trackByFn($index,
                      boardType)) {
                      <mat-option [value]="boardType"
                        >{{ boardType.name }}
                      </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group board-type-group">
                  <mat-label
                    >Board Strength
                    <a href="javascript:" (click)="showModal(boardStrengthInfo)"
                      ><img
                        src="assets/cb/images/information-icon.png"
                        alt="Information"
                        class="info-icon" /></a
                  ></mat-label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      name="boardStrength"
                      id="boardStrength"
                      disableOptionCentering
                      [(ngModel)]="selectedBoardStrength"
                      (selectionChange)="updateForm()"
                    >
                      @for ( boardStrength of boardStrengthList; track
                      trackByFn($index, boardStrength)) {
                      <mat-option [value]="boardStrength"
                        >{{ boardStrength.name }}
                      </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                @if (violations.length > 0) {
                <div class="violations-container">
                  @for (violation of violations; track trackByFn($index,
                  violation)) {
                  <div class="violation-content">
                    {{ violation.message }}
                  </div>
                  }
                </div>
                } @if (presetMatches.length > 0) {
                <div class="custom-size-disclaimer-container">
                  <div class="custom-size-disclaimer">
                    You’re so close to one of our standard sizes! Production can
                    take longer with custom sizing. Do any of these options fit
                    your needs?
                  </div>
                  @for (preset of presetMatches; track trackByFn($index,
                  preset)) {
                  <div class="suggestion-link">
                    <a
                      href="javascript:"
                      (click)="
                        customSizeActive = false;
                        presetSuggestionSelected(preset)
                      "
                      >{{ preset.label }} (in)</a
                    >
                  </div>
                  }
                </div>
                } @if (!presetSuggestion && !isDHC) {
                <div class="button-container">
                  <button
                    class="white-submit"
                    role="submit"
                    (click)="
                      customSizeEntered = true;
                      customSizeActive = false;
                      updateForm()
                    "
                    [class.disabled]="
                      (violations.length > 0 && hasViolationErrors) || !prices
                    "
                    [disabled]="
                      (violations.length > 0 && hasViolationErrors) || !prices
                    "
                  >
                    OK
                  </button>
                </div>
                } @if (!presetSuggestion && isDHC) {
                <div class="button-container">
                  <button
                    class="white-submit"
                    role="submit"
                    (click)="
                      customSizeEntered = true;
                      customSizeActive = false;
                      updateForm()
                    "
                  >
                    OK
                  </button>
                </div>
                }
                <div class="recommendations-modal-link hide-during-mobile">
                  <a
                    href="javascript:"
                    (click)="
                      showModal(recommendations, 'recommendations-modal')
                    "
                    >View Recommendations<img
                      src="assets/cb/images/information-icon.png"
                      alt="Information"
                      class="info-icon"
                  /></a>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- <div class="right-column col-lg-8 web-tab-only"> -->
        <div class="right-column web-tab-only">
          <div
            class="image-container"
            [ngStyle]="{ background: 'url(' + mainGalleryImageURL + ')' }"
          ></div>
          <div class="thumbnails-container">
            @for ( thumbnails of selectedSubcategory?.images ?? []; track
            trackByFn(j, thumbnails); let j = $index) {
            <div class="thumbnail">
              <button
                class="thumbnail-image"
                [ngStyle]="{
                  background: 'url(' + thumbnails.location + ')'
                }"
                [class.active]="selectedImage === j"
                (click)="
                  mainGalleryImageURL = thumbnails.location; selectedImage = j
                "
              ></button>
            </div>
            }
          </div>
          <div class="product-data-container">
            <ul>
              @for ( row of infoDataRows; track trackByFn(j, row); let j =
              $index) {
              <li [innerHTML]="infoDataRows ? infoDataRows[j].value : ''"></li>
              }
            </ul>
            @for ( row of noteDataRows; track trackByFn(j, row); let j = $index)
            {
            <p
              class="small"
              [innerHTML]="noteDataRows ? noteDataRows[j].value : ''"
            ></p>
            } @if (!isOtherProduct && !isPaddedMailer) {
            <p class="fedex">
              To ship a box smaller than 7” x 4” x 2”, it must be placed inside
              a larger box to meet the FedEx minimum package size.
            </p>
            }
          </div>
        </div>
      </div>
      }

      <div class="cta-block white-background fedex">
        <div class="web-only">
          <div class="cta-background-container"></div>
          <h3>Need Design Help?</h3>
          <h4>Collaborate with a designer to make your design come to life.</h4>

          <div class="link-container chevron-anchor red border collab">
            <a [routerLink]="['/design-services']"
              >Collaborate with a designer</a
            >
          </div>
        </div>
        <div class="mobile-only">
          <div class="size-alert">
            <p>
              3D Builder is not available on mobile. Please switch to desktop or
              tablet view to customize this design.
            </p>
          </div>
        </div>
      </div>
    </div>
  </app-page-content-wrapper>
</div>

<ng-template #noBoxSelected>
  <div class="col-12 col-lg-6 col-xl-5">
    <alert type="info"
      >Please select box style, size, paper and board strength.</alert
    >
  </div>
</ng-template>

<ng-template #pricesFailedToLoad>
  <div class="row">
    <div class="col">
      <div
        class="btn btn-secondary float-right mt-0 mb-0"
        #retryEl
        (click)="listPrices()"
      >
        Retry
      </div>
    </div>
  </div>
</ng-template>

<ng-template #subcategoriesFailedToLoad>
  <div class="row">
    <div class="col">
      <div
        class="btn btn-secondary float-right mt-0 mb-0"
        #retryEl
        (click)="getPresets()"
      >
        Retry
      </div>
    </div>
  </div>
</ng-template>

<ng-template #constraintViolation>
  @for (violation of box?.violations ?? []; track trackByFn($index, violation))
  { @if (!hasViolationErrors || !violation.warning) {
  <alert [type]="violation.warning ? 'warning' : 'danger'">{{
    violation.message
  }}</alert>
  } }
</ng-template>

<ng-template #alternativePresets>
  @if (presetMatches.length > 0) {
  <alert type="info">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          Custom sizes are
          <b class="presentation-value">{{ customSizeIncrease | percent }}</b>
          more expensive than our standards.
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          Here are some standards that you might consider instead
        </div>
      </div>
      <div class="row">
        @for (preset of presetMatches; track trackByFn($index, preset)) {
        <div class="col-3">
          <a href="javascript:" (click)="selectPresetMatch(preset)">{{
            preset.label
          }}</a>
        </div>
        }
      </div>
    </div>
  </alert>
  }
</ng-template>

<ng-template #boxSizeInfo>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Box Size</h1>
      <div class="brown-bar"></div>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="info-container mb-3">
            <p>
              Our industry standard measurements you see are listed as interior
              dimensions. They are displayed as Length x Width x Depth (L x W x
              D)
            </p>

            <img
              class="box-size"
              src="assets/cb/images/boxSizeInfo.png"
              alt="Box Size"
            />

            <p>
              To determine what box size is best, we recommend rounding up to
              the nearest tenth of an inch. You will want a box that uses the
              least amount of internal packaging material while still protecting
              your product.
              <span class="cb"
                >To learn more about measuring a box, check out our
                <a
                  class="box-info"
                  href="https://www.companybox.com/blog/how-to-perfectly-measure-a-box/"
                  target="_blank"
                  >post.</a
                >
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #styleInfo>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Box Style</h1>
      <div class="brown-bar"></div>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="info-container mb-3">
            <p>
              Our Super Seal tear tape is only available in standard sizes 6 x 6
              x 3 ½, 9 x 6 x 3 ½ and 12 x 9 x 4. It is double coated and 40%
              wider than our standard tape giving it a cleaner tear. For $0.25
              extra, your packaging will get an extra strong and secure closure.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #styleInfo1>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Box Style</h1>
      <div class="brown-bar"></div>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="info-container mb-3">
            <p>
              Our Super Seal tear tape is only available in standard sizes 6 x 6
              x 2, 9 x 6 x 3 and 12 x 9 x 4. It is double coated and 40% wider
              than our standard tape giving it a cleaner tear. For $0.25 extra,
              your packaging will get an extra strong and secure closure.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #printingInfo>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Printing</h1>
      <div class="brown-bar"></div>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="info-container printing mb-3">
            <p>
              Luxe is our most luxurious, beautiful option available. Always
              printed on our HP Pagewide C500, this one goes far beyond first
              impressions.
            </p>

            <app-printing-table></app-printing-table>
            <p>
              Luxe is a great choice for showing off high resolution images,
              small details, vibrant color, and bold patterns.
            </p>
            <p>
              Our Luxe water-based ink will set your mind at ease for food
              packaging since it is odorless, 100% free of UV-reactive chemicals
              and meets the most stringent food packaging guidelines. Nothing
              will make a bolder statement.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #kraftInfo>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Printing on Kraft vs. White</h1>
      <div class="brown-bar"></div>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="kraft-container">
            <div class="info-container mb-3">
              <h3>PRINTING ON KRAFT</h3>

              <img
                alt="Printing Chart"
                class="printing-chart"
                src="assets/cb/images/kraft_chart.png"
              />
              <p>
                Printing on kraft gives a natural look and feel to the design,
                but keep in mind that light colors do not print accurately on
                this substrate. The lighter colors (if designed on a white
                background) need the bright background to achieve the desired
                look. Darker colors work best on kraft substrates.
              </p>

              <p>
                The saturation of the color will be different on a kraft box
                compared to a white box. A good example to help understand; a
                yellow high-lighter looks bright yellow on white paper, but has
                a green feel to it when used on brown.
              </p>
            </div>
            <div class="info-container mb-3">
              <h3>PRINTING ON WHITE</h3>

              <img
                alt="Printing Chart"
                class="printing-chart"
                src="assets/cb/images/white_printing_chart.png"
              />

              <p>
                Anywhere white is visible in the design will be the board color.
                When designing or uploading an image/graphic with white being
                used, please understand that the white ink does not print on the
                kraft board.
              </p>
              <p>
                The white that is used in the design will show up as kraft/brown
                when selecting a kraft box. This is good to consider for logos
                and images that have the color white (negative space) in the
                design.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #boardTypeInfo>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Board Type</h1>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="info-container board-type mb-3">
            <p>
              Corrugated fiberboard (sometimes called cardboard) is comprised of
              three fiberboard layers; two linerboards sandwich a middle sheet
              that is in a wave-shaped pattern known as flutes. Flutes give
              strength and structure to boxes, serving as a protector, insulator
              and cushioning device.
            </p>
            <img
              class="board-types"
              src="assets/cb/images/board_types.svg"
              alt="Board Types"
            />

            <h3>HOW DO YOU KNOW WHICH FLUTE SIZE TO CHOOSE?</h3>
            <p>
              <strong>E-flute</strong> is ideal for high quality printing with a
              thickness of 1/16". It is generally used for mailers and some
              specialty displays like the beer and wine cases.
            </p>
            <p>
              <strong>F-flute</strong> is half as thick as E-flute making it our
              lightest packaging option. Since this has the largest number of
              flutes per foot, it also allows for the most accurate folding
              operations. You’ll see F-flute offered in our smallest sizes.
            </p>
            <p>
              <strong>B-flute</strong> is a good alternative to E-flute when
              wanting to support heavier items. With a thickness of 1/8" and
              shorter flutes, this type of box still provides a rigid, flat
              surface for printing.
            </p>
            <p>
              <strong>C-flute</strong> is commonly used for shippers. The flutes
              are taller here at 3/16", giving C-flute somewhat better
              cushioning properties than B-flute.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #boardStrengthInfo>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Board Strength Info</h1>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="mb-3">
            Information about board strength will be put here.
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #materialInfo>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Material</h1>
      <div class="brown-bar"></div>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          @if (!isPaddedMailer) {
          <div class="info-container mb-3">
            <h3>PREMIUM WHITE</h3>
            <p>
              Premium White is our high performer. It will add a look of luxury
              to your print. Premium White is available in both a matte and
              gloss finish. Gloss finish is a process where ink is cured to
              appear glossier than a traditional matte finish. It is only
              available on Premium White.
            </p>
            <h3>STANDARD WHITE</h3>
            <p>
              Standard White is the typical corrugated material used for
              packaging. It has slight variations in the white color if you look
              very closely.
            </p>
            <h3>KRAFT</h3>
            <p>
              Kraft is the standard brown material you see on boxes, but once
              you add your branding (logo, color!) it’s not so standard anymore.
              Be sure to think about the colors you use when choosing this
              material. Colors will look darker on Kraft than colors printed on
              Standard or Premium White.
              <strong
                >Luxe printing is not available on kraft at this time.</strong
              >
            </p>
          </div>
          } @if (isPaddedMailer) {
          <div class="info-container mb-3">
            <h3>White</h3>
            <p>
              White is a great choice for showing off crisp, bright colors.
              Please note, the white mailer is not 100% opaque. At a closer
              look, you can see a faint outline of the inside padding.
            </p>
            <h3>Kraft</h3>
            <p>
              Our kraft is the same unbleached, brown color you see on many
              boxes. It brings a natural and organic look to your mailer. Colors
              will look darker on kraft than colors printed on white. We do not
              offer printing with white ink at this time.
            </p>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #finishInfo>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Finish</h1>
      <div class="brown-bar"></div>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="info-container mb-3">
            <p>
              Gloss finish is a process where ink is cured to appear glossier
              than a traditional matte finish. It is only available on Premium
              White.
            </p>

            <p>
              On our new C500 the gloss is an Over Print Varnish (OPV) that
              gives the print a glossy look while still being food safe.
              <strong
                >The C500 can only print with a semi gloss at this time.</strong
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #quantityInfo>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Quantity Info</h1>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="mb-3">Information about quantity will be put here.</div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button type="button" class="red-submit" (click)="hide()">OK</button>
    </div>
  </div>
</ng-template>

<ng-template #recommendations>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Recommendations</h1>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="info-container recommendations-container mb-3">
            <h3>BOARD TYPE RECOMMENDATIONS</h3>
            <h5>Board Category</h5>
            <table>
              <tr>
                <td>Board Type</td>
                <td>
                  Regular Slotted<br />
                  Case (RSC)
                </td>
                <td>
                  Half Slotted<br />
                  Carton (HSC)
                </td>
                <td>
                  Full Overlapping<br />
                  Carton (FOL)
                </td>
                <td>
                  One Piece<br />
                  Folder (OPF)
                </td>
              </tr>
              <tr>
                <td>E-Flute</td>
                <td>L=W &lt;= 12</td>
                <td>L+W &lt;= 12</td>
                <td>L+W &lt;= 12</td>
                <td>
                  <sup>1</sup>&frasl;<sub>4</sub>" &lt;= D &lt;=
                  <sup>5</sup>&frasl;<sub>8</sub>":<br />
                  E-flute
                </td>
              </tr>
              <tr>
                <td>B-Flute</td>
                <td>12 &lt; L+W &lt;= 22</td>
                <td>12 &lt; L+W &lt;= 22</td>
                <td>12 &lt; L+W &lt;= 22</td>
                <td>
                  <sup>5</sup>&frasl;<sub>8</sub>" &lt; D &lt;= 1
                  <sup>3</sup>&frasl;<sub>4</sub>":<br />
                  B-flute
                </td>
              </tr>
              <tr>
                <td>C-Flute</td>
                <td>22 &lt; L+W &lt;= 56</td>
                <td>22 &lt; L+W &lt;= 56</td>
                <td>22 &lt; L+W &lt;= 56</td>
                <td>
                  1 <sup>3</sup>&frasl;<sub>4</sub>" &lt; D &lt;= 6":<br />
                  C-flute
                </td>
              </tr>
            </table>
            <h5>Sales Kit Category</h5>
            <table>
              <tr>
                <td>Board Type</td>
                <td>Tuck Top Carton (TTC)</td>
                <td>Ear Lock Tray (ELT)</td>
              </tr>
              <tr>
                <td>E-Flute</td>
                <td>L+W &lt;= 12: E-flute</td>
                <td>D &lt;= 2"</td>
              </tr>
              <tr>
                <td>B-Flute</td>
                <td>12 &lt; L+W &lt;= 22: B-flute</td>
                <td>D &gt; 2" & W &lt;= 16"</td>
              </tr>
              <tr>
                <td>C-Flute</td>
                <td>22 &lt; L+W &lt;= 56: C-flute</td>
                <td>D &gt; 2" & 16 &lt; W &lt;= 25"</td>
              </tr>
            </table>
            <h5>Point Of Purchase Category</h5>
            <table>
              <tr>
                <td>Board Type</td>
                <td>Four Side Roll-Over (FSRO)</td>
              </tr>
              <tr>
                <td>E-Flute</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>B-Flute</td>
                <td>12 &lt; L+W &lt;= 22: B-flute</td>
              </tr>
              <tr>
                <td>C-Flute</td>
                <td>22 &lt; L+W &lt;= 56: C-flute</td>
              </tr>
            </table>
            <h3>BOARD STRENGTH RECOMMENDATIONS</h3>
            <h5>Board Category</h5>
            <table>
              <tr>
                <td>Board Type</td>
                <td>
                  Regular Slotted<br />
                  Case (RSC)
                </td>
                <td>
                  Half Slotted<br />
                  Carton (HSC)
                </td>
                <td>
                  Full Overlapping<br />
                  Carton (FOL)
                </td>
                <td>
                  One Piece<br />
                  Folder (OPF)
                </td>
              </tr>
              <tr>
                <td>E-Flute - 32</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>B-Flute - 32</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>C-Flute - 32</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>C-Flute - 44</td>
                <td>65-95 lbs</td>
                <td>65-95 lbs</td>
                <td>65-95 lbs</td>
                <td>65-95 lbs</td>
              </tr>
            </table>
            <h5>Sales Kit Category</h5>
            <table>
              <tr>
                <td>Board Type</td>
                <td>Tuck Top Carton (TTC)</td>
                <td>Ear Lock Tray (ELT)</td>
              </tr>
              <tr>
                <td>E-flute - 32</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>B-flute - 32</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>C-flute - 32</td>
                <td>0-65 lbs</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>C-flute - 44</td>
                <td>65-95 lbs</td>
                <td>65-95 lbs</td>
              </tr>
            </table>
            <h5>Point of Purchase Category</h5>
            <table>
              <tr>
                <td>Board Type</td>
                <td>Four Side Roll-Over (FSRO)</td>
              </tr>
              <tr>
                <td>E-flute - 32</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>E-flute - 44</td>
                <td>65-95 lbs</td>
              </tr>
              <tr>
                <td>B-flute - 32</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>B-flute - 44</td>
                <td>65-95 lbs</td>
              </tr>
              <tr>
                <td>C-flute - 32</td>
                <td>0-65 lbs</td>
              </tr>
              <tr>
                <td>C-flute - 44</td>
                <td>65-95 lbs</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #downloadTemplate>
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow">
      <h1>Taking your design offline?</h1>
    </div>
    <button type="button" class="close-button pull-right" (click)="hide()">
      <img src="assets/cb/images/close-icon.png" alt="Close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="terms-of-service mb-3">
            <p>
              <strong>Friendly Reminder!</strong> Please do not adjust the
              artboard or alter any die lines from your download. It will affect
              the quality your box and CompanyBox cannot be held accountable. To
              continue, you must first read and agree to our
              <a
                href="https://www.companybox.com/terms-and-conditions/"
                target="_blank"
                >Terms & Conditions.</a
              >
            </p>

            <p>
              <strong>Note!</strong> You will need your @if (isOtherProduct) {
              <span>product</span>
              } @if (!isOtherProduct) {
              <span>box</span>
              } details to upload this design later. Your @if (isOtherProduct) {
              <span>product</span>
              } @if (!isOtherProduct) {
              <span>box</span>
              } details are:
            </p>

            <div class="box-details-container">
              @if (!isTape && !isTissue) {
              <p
                [innerHtml]="
                  (selectedPreset?.type?.name?.toUpperCase() ?? '') +
                  (selectedBoardType?.name ?? '')
                "
              ></p>
              }
              <p>SIZE: {{ length }} X {{ width }} X {{ depth }} (IN)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button
        type="button"
        class="white-submit"
        (click)="createAndDownloadTemplate(); hide()"
      >
        I AGREE
      </button>
    </div>
  </div>
</ng-template>

@if (templateDownloadUrl) {
<iframe
  [src]="templateDownloadUrl"
  style="border: none"
  width="1"
  height="1"
></iframe>
}
<ng-template #connected>
  <app-connected-better></app-connected-better>
</ng-template>
<ng-template #blankbox>
  <div class="modal-header pb-0 border-0"></div>
  <div class="modal-body">
    <div class="container-fluid">
      <p>
        Double checking! You’re about to add blank boxes to your cart. Is this
        what you want? If not, click CANCEL and we’ll take you back to your
        options.
      </p>
    </div>
    <div class="multi-button-container">
      <button type="button" class="white1-submit" (click)="cancel()">
        Cancel
      </button>
      <button type="button" class="white-submit" (click)="addToCart()">
        Continue
      </button>
    </div>
  </div>
</ng-template>
