<div class="pixabay-content">
  @if (pixabayLoading) {
    <app-loading-modal [content]="'loading Pixabay'">
    </app-loading-modal>
  }
  <mat-expansion-panel [disabled]='true' [expanded]="isPixabayAdvancedSearchActive">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="col start">
          <h5>Add Pixabay Image</h5>
          <input matInput [(ngModel)]="searchText" (keyup.enter)="getPixabay()" (focusout)="getPixabay()"
            (keydown)="$event.stopPropagation()">
        </div>
        <div class="col end">
          <a (click)="isPixabayAdvancedSearchActive = !isPixabayAdvancedSearchActive">Advanced Search <img class="arrow" src="assets/cb/images/arrow_down.svg"></a>
          <h6>Images provided by: <a href="https://pixabay.com/" target="_blank"><img
          src="assets/cb/images/designer/pixabay_logo.png"></a></h6>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row advanced">
      <div class="col">
        <div class="input-group  col-left">
          <mat-label for="image-type">Image Type</mat-label>
          <mat-select name="image-type" (selectionChange)="getPixabay()" [(ngModel)]="selectedImageType">
            <mat-option value="" selected></mat-option>
            @for (image of pixabayImageTypes; track trackByFn($index, image)) {
              <mat-option name="imageType" [value]="image">
                {{image}}
              </mat-option>
            }
          </mat-select>
        </div>
      </div>
      <div class="col">
        <div class="input-group  col-right">
          <mat-label for="category">Category</mat-label>
          <mat-select name="category" (selectionChange)="getPixabay()" [(ngModel)]="selectedCategory">
            <mat-option value="" selected></mat-option>
            @for (category of pixabayCategories; track trackByFn($index, category)) {
              <mat-option [value]="category">
                {{category}}
              </mat-option>
            }
          </mat-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="colors">
        <h5>Colors</h5>
        <div class="color-options">
          @for (color of pixabayColors; track trackByFn($index, color)) {
            <mat-checkbox (click)="toggleColor(color); getPixabay()">
            {{color}}</mat-checkbox>
          }
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <div class="pixabay-container">
    @for (pixabay of pixabays; track trackByFn($index, pixabay)) {
      <div>
        <img (click)="addPixabay(pixabay)" [src]="pixabay.previewURL">
      </div>
    }
    @if (pixabays.length == 0) {
      <div>
        @if (loading) {
          <span>Loading...</span>
        }
        @if (!loading) {
          <span>No results</span>
        }
      </div>
    }
  </div>
</div>