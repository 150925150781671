
import {
  HttpRequest,
  HttpInterceptorFn,
  HttpHandlerFn
} from "@angular/common/http";
import { inject } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { tap } from "rxjs/operators";

export const logInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
    const logger = inject(NGXLogger);
    return next(req).pipe(
      tap({
        error: (e) => {
          if(!e.url.endsWith('heartbeat')) logger.error(e)},
      })
    );
};