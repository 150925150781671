import { Routes } from '@angular/router';
import { BoxStyleComponent } from './pages/box-style/box-style.component';
import { ReviewBoxComponent } from './pages/review-box/review-box.component';
import { DesignComponent } from './pages/design/design.component';
import { BoxOptionsComponent } from './pages/box-options/box-options.component';

export const routes: Routes = [
  { path: "", redirectTo: "/box-style/:categoryCode", pathMatch: "full" },

  {
    path: "box-style/:categoryCode",
    component: BoxStyleComponent,
    data: {
      title: "Box Style",
    },
  },

  {
    path: "box-style/:categoryCode/:uploadDesign",
    component: BoxStyleComponent,
    data: {
      title: "Box Style",
    },
  },

  {
    path: "box-options/:categoryCode/:subcategory",
    component: BoxOptionsComponent,
    data: {
      title: "Box Options",
    },
  },
  {
    path: "box-options/:categoryCode/:subcategory/:uploadDesign",
    component: BoxOptionsComponent,
    data: {
      title: "Box Options",
    },
  },
  {
    path: "review-box",
    component: ReviewBoxComponent,
    data: {
      title: "Box Style",
    },
  },
  {
    path: "design",
    component: DesignComponent,
  },
  {
    path: "design/:categoryCode/:subcategory",
    component: DesignComponent,
  }
];
