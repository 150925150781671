import { Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { PageHeaderComponent } from './features/page-header/page-header.component';
import { PageFooterComponent } from './features/page-footer/page-footer.component';

@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, PageHeaderComponent, PageFooterComponent],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  private title = environment.config.title;

  location!: Location;

  constructor(
    private titleService: Title
  ) {}

  ngOnInit(): void {
    // this.toastyConfig.theme = 'bootstrap';
    // this.toastyConfig.timeout = 10000;
    this.titleService.setTitle(this.title);

    if (environment.production) {
      if (location.protocol === "http:") {
        window.location.href = location.href.replace("http", "https");
      }
      // LogRocket.init('edplti/companybox');
    }
  }
}
