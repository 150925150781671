export default class Fractions {

    static convertDecimalToFraction(num: any) {
        const fractionMap = new Map();
        fractionMap.set(0, ['0', '0']);
        fractionMap.set(0.0625, ['1', '16']);
        fractionMap.set(0.125, ['1', '8']);
        fractionMap.set(0.1875, ['3', '16']);
        fractionMap.set(0.25, ['1', '4']);
        fractionMap.set(0.3125, ['5', '16']);
        fractionMap.set(0.375, ['3', '8']);
        fractionMap.set(0.4375, ['7', '16']);
        fractionMap.set(0.5, ['1', '2']);
        fractionMap.set(0.5625, ['9', '16']);
        fractionMap.set(0.625, ['5', '8']);
        fractionMap.set(0.6875, ['11', '16']);
        fractionMap.set(0.75, ['3', '4']);
        fractionMap.set(0.8125, ['13', '16']);
        fractionMap.set(0.875, ['7', '8']);
        fractionMap.set(0.9375, ['15', '16']);

        const str = String(num).split('.', 2);
        const decimal = parseFloat('0.' + str[1]);
        return fractionMap.get(decimal);
    }

    static getFractionLabel(num: any) {
        const fractionMap = new Map();
        fractionMap.set(0, ['0', '0']);
        fractionMap.set(0.0625, ['1', '16']);
        fractionMap.set(0.125, ['1', '8']);
        fractionMap.set(0.1875, ['3', '16']);
        fractionMap.set(0.25, ['1', '4']);
        fractionMap.set(0.3125, ['5', '16']);
        fractionMap.set(0.375, ['3', '8']);
        fractionMap.set(0.4375, ['7', '16']);
        fractionMap.set(0.5, ['1', '2']);
        fractionMap.set(0.5625, ['9', '16']);
        fractionMap.set(0.625, ['5', '8']);
        fractionMap.set(0.6875, ['11', '16']);
        fractionMap.set(0.75, ['3', '4']);
        fractionMap.set(0.8125, ['13', '16']);
        fractionMap.set(0.875, ['7', '8']);
        fractionMap.set(0.9375, ['15', '16']);

        const str = String(num).split('.', 2);
        const decimal = parseFloat('0.' + str[1]);
        return fractionMap.get(decimal);

    }
}
