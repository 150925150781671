import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { environment } from "../../environments/environment";
import { User } from "../shared/models/user";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "./auth.service";
import { lastValueFrom } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UserService {
  private user: User | undefined;
  public update$ = new EventEmitter<User>();

  constructor(
    protected httpClient: HttpClient,
    private logger: NGXLogger,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  async createUser(customer: User): Promise<User> {
    const payload = JSON.stringify(customer);
    try {
      const res = await lastValueFrom(
        this.httpClient.post<User>(
          `${environment.apiHost}/user/user`,
          payload,
          {
            headers: { "Content-Type": "application/json" },
          }
        )
      );
      console.log("the response is: ", res);
      return await Promise.resolve(res!);
    } catch (error: any) {
      if (error.status === 400) {
        this.logger.debug("Register failed", error.message);

        const snackBarRef = this.snackBar.open(
          error.error.fields[0].message,
          "X",
          {
            duration: 10000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["red-snackbar"],
          }
        );
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }
      if (error.status === 409 && error.error.username === "") {
        this.logger.debug("Register failed", error.message);
        const snackBarRef = this.snackBar.open(
          error.error.fields[0].message,
          "X",
          {
            duration: 10000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["red-snackbar"],
          }
        );
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }
      if (error.status === 409) {
        const snackBarRef = this.snackBar.open(error.error.message, "X", {
          duration: 10000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["red-snackbar"],
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }
      if (customer.phone === "") {
        this.logger.debug("Register failed", error.message);
        const snackBarRef = this.snackBar.open("Fields cannot be empty", "X", {
          duration: 10000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["red-snackbar"],
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }
      return await Promise.reject(error);
    }
  }

  async getCurrentUser(): Promise<User> {
    // this.logger.debug('session user', this.user);
    if (this.user) {
      return Promise.resolve(this.user);
    } else {
      // TODO if (this.authService.token) {
      return this.httpClient
        .get(`${environment.apiHost}/ecom/users/current`)
        .toPromise()
        .then((res: any) => {
          this.user = new User().deserialize(res);
          // TODO LogRocket.identify(this.user.username, {
          //     name: this.user.username,
          // });
          return this.user;
        })
        .catch((error: HttpErrorResponse) => {
          this.logger.error(error);
          return Promise.reject(error);
        });
      // }
      // else {
      //   return Promise.reject("Not logged in");
      // }
    }
  }

  async getUser(
    userId: number,
    forceReload: boolean = false
  ): Promise<User | undefined> {
    if (userId === 0) {
      return Promise.resolve(undefined);
    }
    const onfulfilled = (res: any) => {
      const user = new User().deserialize(res);
      // this.logger.debug('user', user);
      return user;
    };
    const onrejected = (error: HttpErrorResponse) => {
      this.logger.error(error);
      return Promise.reject(error);
    };
    if (forceReload) {
      return this.httpClient
        .post(`${environment.apiHost}/user/user/${userId}/reload`, {})
        .toPromise()
        .then(onfulfilled)
        .catch(onrejected);
    } else {
      return this.httpClient
        .get(`${environment.apiHost}/user/user/${userId}`)
        .toPromise()
        .then(onfulfilled)
        .catch(onrejected);
    }
  }

/*  async getUserByExternalId(userId: number): Promise<User> {
    return this.httpClient
      .get(`${environment.apiHost}/user/user/external/${userId}`)
      .toPromise()
      .then((res: any) => {
        if (res) {
          return new User().deserialize(res);
        }
        return User.EMPTY;
      })
      .catch((error: HttpErrorResponse) => {
        this.logger.error(error);
        return Promise.reject(error);
      });
  }
  setPassword(oldPassword: string, password: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.getCurrentUser().then(
        (currentUser) => {
          const auth = Object.assign(new User(), currentUser);
          auth.password = oldPassword;

          this.authService.checkPassword(auth).then(
            (value) => {
              if (value === false) {
                reject("Incorrect password");
                return;
              }
              const user = Object.assign(new User(), currentUser);
              user.password = password;

              const url = `${environment.apiHost}/user/user/setpassword`;
              return this.httpClient.put(url, user).subscribe({
                next: (res: any) => {
                  const u = new User().deserialize(res);
                  this.logger.debug("Updated password for user", u);
                  this.user = u;
                  resolve(this.user);
                },
                error: (reason) => {
                  this.logger.error(
                    "Unable to change password for user",
                    reason
                  );
                  reject(reason);
                },
              });
            },
            (reason) => {
              this.logger.debug("Unable to authorize user", reason);
              reject(reason);
            }
          );
        },
        (reason) => {
          this.logger.debug("Unable to get current user", reason);
          reject(reason);
        }
      );
    });
  }
  checkPassword(oldPassword: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.getCurrentUser().then(
        (currentUser) => {
          const auth = Object.assign(currentUser);
          auth.password = oldPassword;

          this.authService.checkPassword(auth).then(
            (value) => {
              if (value === false) {
                reject("Incorrect password");
              } else {
                resolve(currentUser);
              }
            },
            (reason) => {
              this.logger.debug("Unable to authorize user", reason);
              reject(reason);
            }
          );
        },
        (reason) => {
          this.logger.debug("Unable to get current user", reason);
          reject(reason);
        }
      );
    });
  }
  recoverPassword(username: string) {
    return new Promise<boolean>((resolve, reject) => {
      const user = new User();
      user.username = username;

      const url = `${environment.apiHost}/user/user/recover?deleteToken=true`;

      return this.httpClient.post(url, user).subscribe({
        next: (_res: any) => {
          this.logger.debug("Recover password email sent for email", username);
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error(error);
          reject(error);
        },
      });
    });
  }*/
}
