import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-printing-table',
  templateUrl: './printing-table.component.html',
  styleUrls: ['./printing-table.component.scss']
})
export class PrintingTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
