import { Component, ElementRef, OnInit, TemplateRef, ViewChild, signal } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BoxService } from '../../services/box.service';
import { Price } from '../../shared/models';
import { Subcategory } from '../../shared/models';
import Fractions from '../../shared/fractions';
import { NotFoundModalComponent } from '../../features/not-found-modal/not-found-modal.component';
import { LoadingModalComponent } from '../../features/loading-modal/loading-modal.component';
import { ProjectService } from '../../services/project.service';
import { ImagesService } from '../../services/images.service';
import { ContainerService } from '../../services/container.service';
import { ERROR_BODY, ERROR_TITLE } from '../../../globals';
import { CheckoutService } from '../../services/checkout.service';
import { ConfirmationModalComponent } from '../../features/confirmation-modal/confirmation-modal.component';
import { PageContentWrapperComponent } from '../../features/page-content-wrapper/page-content-wrapper.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  standalone: true,
  selector: "app-review-box",
  templateUrl: "./review-box.component.html",
  styleUrls: ["./review-box.component.scss"],
  imports: [
    PageContentWrapperComponent,
    LoadingModalComponent,
    NotFoundModalComponent,
    CommonModule,
    MatOptionModule,
    MatCheckboxModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
  ],
})
export class ReviewBoxComponent implements OnInit {
  @ViewChild("notFoundModal", { static: false }) notFoundModal!: TemplateRef<any>;
  @ViewChild("addToCartForm", { static: false }) addToCartForm!: ElementRef;

  public box: any;
  public selectedPrice: Price = new Price();
  selectedSubcategory: Subcategory | undefined;
  public prices: Price[] = [];
  public productDetails: any;
  public pageLoading: boolean = false;
  public cartLoading: boolean = false;

  public isTape = false;
  public isTissue = false;
  public isLabel = false;
  public isPizza = false;
  public isPaddedMailer = false;

  // default to outside only printing
  public showOutsideButton = true;
  public showInsideButton = false;

  public quantity: number = 0;
  private quantities: number[] = [];
  public quantityArrayIndex = 0;
  public standardQuantities = [
    1, 10, 25, 50, 100, 250, 500, 1000, 1500, 2000, 5000, 10000, 12000,
  ];
  private luxeQuantities = [500, 1000, 2000, 3000, 5000, 8000, 10000];

  public customQuantityEntered = false;
  public customQuantityViolation = false;

  public length: number = 0;
  public width: number = 0;
  public depth: number = 0;

  private boxData: any;

  public prodImage: any;
  public insideImage: any;
  public outsideImage: any;

  public activeButton = "outsideArt";
  options: string = "";
  modal!: BsModalRef<unknown>;
  disableBtn = signal<boolean>(false);
  cartUrl = signal<string>("");
  cxmlurlencoded = signal<string>("");

  constructor(
    private location: Location,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private boxService: BoxService,
    private imagesService: ImagesService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private containerService: ContainerService,
    private checkoutService: CheckoutService
  ) {}

  ngOnInit() {
    this.pageLoading = true;
    window.scroll(0, 0);

    this.route.params.subscribe((params) => {
      this.productDetails = JSON.parse(
        sessionStorage.getItem("productDetails") ?? "{}"
      );
      this.quantity = params["quantity"];
    });
    this.productDetails = JSON.parse(
      sessionStorage.getItem("productDetails") ?? "{}"
    );

    this.checkPrintingSide();

    this.containerService
      .getContainerFiles(this.productDetails.ContainerName)
      .then((response: any) => {
        const outsideFile = response.files.find(
          (x: { name: string }) => x.name === "Texture_0.png"
        ).content;
        const insideFile = response.files.find(
          (x: { name: string }) => x.name === "Texture_1.png"
        ).content;

        this.outsideImage = this.sanitizer.bypassSecurityTrustResourceUrl(
          "data:image/jpeg;base64, " + outsideFile
        );
        this.insideImage = this.sanitizer.bypassSecurityTrustResourceUrl(
          "data:image/jpeg;base64, " + insideFile
        );
        this.pageLoading = false;
      })
      .catch(() => {
        this.pageLoading = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    if (this.productDetails.BoxDimensions) {
      const dimensions = this.productDetails.BoxDimensions.split("x");
      this.length = dimensions[0];
      this.width = dimensions[1];
      this.depth = dimensions[2];
    }

    if (this.productDetails.Code?.toLowerCase() === "tape") {
      this.isTape = true;
      this.listTapePrices();
    } else if (this.productDetails.Code?.toLowerCase() === "tissue") {
      this.isTissue = true;
      this.listTissuePrices();
    } else if (this.productDetails.Code?.toLowerCase() === "label") {
      this.isLabel = true;
      this.listLabelPrices();
    } else if (this.productDetails.Code?.startsWith("PIZ")) {
      this.isPizza = true;
      this.listPizzaPrices();
    } else if (this.productDetails.Code?.startsWith("RPM")) {
      this.isPaddedMailer = true;
      this.listPaddedMailerPrices();
    } else {
      this.listPrices();
    }
    this.boxService
      .getType(this.productDetails.Code)
      .then((response) => {
        this.boxData = response;
        this.boxData.flute = response.boardType?.flute;
        this.boxData.overlay = false;
        this.boxData.preConstructedZip = false;
        if (
          this.productDetails !== null &&
          this.productDetails.subcategory != null &&
          this.productDetails.subcategory.startsWith("SNAP2")
        ) {
          this.boxData.options = "superseal";
        }
        this.boxData.nameAsHtml = {};
        this.boxData.defaultQuantity = 200;
      })
      .catch(() => {
        this.pageLoading = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  goBack() {
    this.location.back();
  }

  getPrintingSide() {
    const i = this.productDetails?.print?.indexOf("") || 0;
    return this.productDetails.print?.substring(i).trim() || "";
  }

  getPrintingType() {
    const i = this.productDetails?.print?.indexOf(" ") || 0;
    return this.productDetails?.print?.substring(0, i).trim() || "";
  }

  setActiveButton(buttonName: string) {
    this.activeButton = buttonName;
  }

  // showOutsideButton is already set to true
  checkPrintingSide() {
    if (
      this.productDetails?.insideOnly &&
      JSON.parse(this.productDetails.insideOnly)
    ) {
      this.showInsideButton = true;
      this.showOutsideButton = true;
      this.activeButton = "insideArt";
    } else if (
      this.productDetails?.twoSided &&
      JSON.parse(this.productDetails.twoSided)
    ) {
      this.showInsideButton = true;
    }
  }

  listPrices() {
    this.prices = [];
    let paperType;
    if (
      this.getPaperTypeID(this.productDetails.outerMaterial) === "pkw" ||
      this.getPaperTypeID(this.productDetails.innerMaterial) === "pkw"
    ) {
      paperType = "pkw";
      // } else if (this.productDetails.categoryCode === 'Event Boxes') {
      //   this.productDetails.paperTypeId = this.getPaperTypeID(this.productDetails.outerMaterial);
    } else if (
      this.getPaperTypeID(this.productDetails.outerMaterial) === "kw" &&
      this.getPaperTypeID(this.productDetails.innerMaterial) === "k"
    ) {
      paperType = "kw";
    } else if (
      this.getPaperTypeID(this.productDetails.outerMaterial) === "k" &&
      this.getPaperTypeID(this.productDetails.innerMaterial) === "kw"
    ) {
      paperType = "kw";
    } else if (
      this.getPaperTypeID(this.productDetails.outerMaterial) === "k" ||
      this.getPaperTypeID(this.productDetails.innerMaterial) === "k"
    ) {
      paperType = "k";
    } else {
      paperType = "kw";
    }

    this.quantities = [];
    if (
      this.getPrintingType() === "Standard" ||
      this.productDetails.print.includes("Standard")
    ) {
      this.standardQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    } else {
      this.luxeQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    }

    this.boxService
      .getPrice(
        this.productDetails.Code,
        this.productDetails.BoardStrength,
        paperType,
        this.length,
        this.width,
        this.depth,
        this.quantity,
        this.productDetails.twoSided,
        this.productDetails.luxe,
        this.productDetails.gloss,
        false,
        this.productDetails.options,
        this.productDetails.rush
      )
      .then((priceResponse) => {
        this.selectedPrice = priceResponse.price ?? new Price();
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.boxService
      .listPrices(
        this.productDetails.Code,
        this.productDetails.BoardStrength,
        paperType,
        this.length,
        this.width,
        this.depth,
        this.quantities,
        this.productDetails.twoSided,
        this.productDetails.luxe,
        false,
        this.productDetails.options,
        this.productDetails.rush
      )
      .then(
        (priceData) => {
          this.selectedSubcategory = priceData.box?.boxType?.subcategory;
          const allowCustomSample = this.selectedSubcategory?.allowCustomSample;
          if (JSON.parse(this.productDetails.luxe)) {
            priceData.prices = priceData.prices.filter(
              (x: Price) =>
                (priceData.prices[0] &&
                  x.quantity >= priceData.prices[0].luxeMinimumOrder &&
                  x.quantity <= priceData.prices[0].maxQuantity) ||
                (x.quantity === 1 && allowCustomSample)
            );
          } else {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].minimumOrder &&
                  x.quantity <= priceData.prices[0].maxQuantity) ||
                (x.quantity === 1 && allowCustomSample)
            );
          }

          this.prices = priceData.prices;
        },
        () => {
          this.showModal(this.notFoundModal);
          // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
          this.prices = [];
        }
      );
  }
  getPaperTypeID(paperType: string) {
    if (paperType === "Kraft") {
      return "k";
    } else if (paperType === "Premium White") {
      return "pkw";
    } else {
      return "kw";
    }
  }
  listPizzaPrices() {
    this.prices = [];

    this.quantities = [
      500, 750, 1000, 1250, 1500, 1750, 2000, 3000, 4000, 5000, 6000, 7000,
      8000, 9000, 10000, 11000, 12000,
    ];

    this.boxService
      .getPrice(
        this.productDetails.Code,
        this.productDetails.BoardStrength,
        this.productDetails.paperType,
        this.length,
        this.width,
        this.depth,
        this.quantity,
        this.productDetails.twoSided,
        this.productDetails.luxe,
        this.productDetails.gloss,
        false,
        this.productDetails.options,
        false
      )
      .then((priceResponse) => {
        this.selectedPrice = priceResponse.price ?? new Price();
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.boxService
      .listPrices(
        this.productDetails.Code,
        this.productDetails.BoardStrength,
        this.productDetails.paperType,
        this.length,
        this.width,
        this.depth,
        this.quantities,
        this.productDetails.twoSided,
        this.productDetails.luxe,
        false,
        this.productDetails.options,
        false
      )
      .then(
        (priceData) => {
          this.selectedSubcategory = priceData.box.boxType?.subcategory;
          const allowCustomSample = this.selectedSubcategory?.allowCustomSample;
          if (!JSON.parse(this.productDetails.luxe)) {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].minimumOrder &&
                  x.quantity <= priceData.prices[0].maxQuantity) ||
                (x.quantity === 1 && allowCustomSample)
            );
          } else {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].luxeMinimumOrder &&
                  x.quantity <= priceData.prices[0].maxQuantity) ||
                (x.quantity === 1 && allowCustomSample)
            );
          }

          this.prices = priceData.prices;
        },
        () => {
          this.showModal(this.notFoundModal);
          // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
          this.prices = [];
        }
      );
  }

  listPaddedMailerPrices() {
    let paperTypeName = "white";
    if (this.productDetails.material === "Kraft") {
      paperTypeName = "kraft";
    }

    this.boxService
      .getPaddedMailerPrice(
        this.productDetails.Code,
        paperTypeName,
        this.quantity,
        false
      )
      .then((priceResponse: any) => {
        this.selectedSubcategory = priceResponse.type.subcategory;
        this.selectedPrice = priceResponse.price;
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    switch (this.productDetails.Code) {
      case "RPM00Z":
        this.quantities = [1, 2, 10, 20, 100];
        break;
      case "RPM02Z":
      case "RPM05Z":
        this.quantities = [1, 5, 20, 50, 100, 200];
        break;
      case "RPM06Z":
        this.quantities = [1, 10, 50, 150, 250, 500];
        break;
    }

    this.boxService
      .listPaddedMailerPrices(
        this.productDetails.Code,
        paperTypeName,
        this.quantities,
        false
      )
      .then(
        (priceData: any) => {
          // priceData.prices = priceData.prices.filter(x => x);
          // priceData.prices.forEach(price => price.pricePerUnit = price.pricePerCase);
          this.prices = priceData.prices;
        },
        () => {
          this.showModal(this.notFoundModal);
          // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
          this.prices = [];
        }
      );
  }

  listTapePrices() {
    let paperTypeName = "white";
    if (this.productDetails.material === "Kraft") {
      paperTypeName = "kraft";
    }

    this.boxService
      .getTapePrice(paperTypeName, this.quantity)
      .then((priceResponse: any) => {
        this.selectedPrice = priceResponse.price;
        this.selectedPrice!.pricePerUnit = priceResponse.price.pricePerCase;
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.quantities = [3, 10, 20, 50, 100];

    this.boxService.listTapePrices(paperTypeName, this.quantities).then(
      (priceData: any) => {
        this.selectedSubcategory = priceData.tape.boxType.subcategory;
        priceData.prices = priceData.prices.filter((x: any) => x);
        priceData.prices.forEach(
          (price: { pricePerUnit: any; pricePerCase: any }) =>
            (price.pricePerUnit = price.pricePerCase)
        );
        this.prices = priceData.prices;
      },
      () => {
        this.showModal(this.notFoundModal);
        // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
        this.prices = [];
      }
    );
  }

  listTissuePrices() {
    this.boxService
      .getTissuePrice(this.quantity, 18)
      .then((priceResponse: any) => {
        this.selectedPrice = priceResponse.price;
        this.selectedPrice!.pricePerUnit =
          priceResponse.price.pricePerReam / 100;
        this.selectedPrice!.quantity = this.selectedPrice!.quantity ?? 0 * 100;
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.quantities = [2, 3, 4, 5, 6, 7, 8];

    this.boxService.listTissuePrices(this.quantities, 18).then(
      (priceData: any) => {
        priceData.prices = priceData.prices.filter((x: any) => x);

        priceData.prices.forEach(
          (price: {
            totalSheets: number;
            quantity: number;
            minimumOrder: number;
            maxQuantity: number;
            pricePerUnit: number;
            pricePerReam: number;
          }) => {
            price.totalSheets = price.quantity * 100;
            price.minimumOrder = price.minimumOrder * 100;
            price.maxQuantity = price.maxQuantity * 100;
            price.quantity *= 100;
            price.pricePerUnit = price.pricePerReam / 100;
          }
        );
        this.selectedSubcategory = priceData.tissue.boxType.subcategory;
        this.prices = priceData.prices;
        this.quantity *= 100;
      },
      () => {
        this.showModal(this.notFoundModal);
        // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
        this.prices = [];
      }
    );
  }

  listLabelPrices() {
    this.boxService
      .getLabelPrice(
        this.quantity,
        this.productDetails.shape.toLowerCase(),
        "c",
        this.length,
        this.width,
        this.depth
      )
      .then((priceResponse: any) => {
        this.selectedPrice = priceResponse.price;
      })
      .catch(() => {
        this.pageLoading = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.quantities = [
      100, 200, 250, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
      5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000,
    ];

    this.boxService
      .listLabelPrices(
        this.quantities,
        this.productDetails.shape.toLowerCase(),
        "c",
        this.length,
        this.width,
        this.depth
      )
      .then(
        (priceData: any) => {
          priceData.prices = priceData.prices.filter((x: any) => x);
          this.selectedSubcategory = priceData.label.boxType.subcategory;
          this.prices = priceData.prices;
        },
        () => {
          this.showModal(this.notFoundModal);
          // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
          this.prices = [];
        }
      );
  }

  getPrice() {
    let paperType;
    if (
      this.getPaperTypeID(this.productDetails.outerMaterial) === "pkw" ||
      this.getPaperTypeID(this.productDetails.innerMaterial) === "pkw"
    ) {
      paperType = "pkw";
      // } else if (this.productDetails.categoryCode === 'Event Boxes') {
      //   this.productDetails.paperTypeId = this.getPaperTypeID(this.productDetails.outerMaterial);
    } else if (
      this.getPaperTypeID(this.productDetails.outerMaterial) === "kw" &&
      this.getPaperTypeID(this.productDetails.innerMaterial) === "k"
    ) {
      paperType = "kw";
    } else if (
      this.getPaperTypeID(this.productDetails.outerMaterial) === "k" &&
      this.getPaperTypeID(this.productDetails.innerMaterial) === "kw"
    ) {
      paperType = "kw";
    } else if (
      this.getPaperTypeID(this.productDetails.outerMaterial) === "k" ||
      this.getPaperTypeID(this.productDetails.innerMaterial) === "k"
    ) {
      paperType = "k";
    } else {
      paperType = "kw";
    }
    this.customQuantityEntered = true;

    if (this.isTape) {
      let paperType1 = "white";
      if (this.productDetails.material === "Kraft") {
        paperType1 = "kraft";
      }
      this.boxService
        .getTapePrice(paperType1, this.quantity)
        .then((value: any) => {
          if (this.prices && this.prices[0]) {
            this.customQuantityViolation =
              this.quantity < (this.prices[0] as Price).minimumOrder ||
              this.quantity > this.prices[0].maxQuantity;
          }
          if (!this.customQuantityViolation) {
            this.selectedPrice = value.price;
            // this.enteredCustomPrice = `${this.quantity} ($${(
            //   this.selectedPrice?.pricePerCase / 8
            // ).toFixed(2)}/Roll)`;
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else if (this.isTissue) {
      this.boxService
        .getTissuePrice(Math.ceil(this.quantity / 100), 18)
        .then((value: any) => {
          if (this.prices && this.prices[0]) {
            this.customQuantityViolation =
              this.quantity < (this.prices[0] as Price).minimumOrder ||
              this.quantity > this.prices[0].maxQuantity;
          }
          if (!this.customQuantityViolation) {
            // this.selectedPrice = value.price;
            // this.enteredCustomPrice = `${this.quantity} sheets ($${(
            //   this.selectedPrice!.pricePerReam / 100
            // ).toFixed(2)} per sheet)`;
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else if (this.isLabel) {
      this.boxService
        .getLabelPrice(
          this.quantity,
          this.productDetails.shape.toLowerCase(),
          "c",
          this.length,
          this.width,
          this.depth
        )
        .then((value: any) => {
          if (this.prices && this.prices[0]) {
            this.customQuantityViolation =
              this.quantity < (this.prices[0] as Price).minimumOrder ||
              this.quantity > this.prices[0].maxQuantity;
          }
          if (!this.customQuantityViolation) {
            // this.selectedPrice = value.price;
            // this.enteredCustomPrice = `${
            //   this.quantity
            // } ($${this.selectedPrice.pricePerUnit.toFixed(2)}) each`;
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else if (this.isPaddedMailer) {
      this.boxService
        .getPaddedMailerPrice(
          this.productDetails.Code,
          paperType,
          this.quantity,
          false
        )
        .then((value: any) => {
          this.selectedSubcategory = value.paddedMailer.boxType.subcategory;
          if (this.prices && this.prices[0]) {
            this.customQuantityViolation =
              this.quantity < (this.prices[0] as Price).minimumOrder ||
              this.quantity > this.prices[0].maxQuantity;
          }
          if (!this.customQuantityViolation) {
            // this.selectedPrice = value.price;
            // this.enteredCustomPrice = `${this.quantity} cases of ${
            //   this.selectedPrice.unitsPerCase
            // } ($${this.selectedPrice.pricePerUnit.toFixed(2)}) each`;
          }
        })
        .catch(() => {
          this.pageLoading = false;
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else {
      this.boxService
        .getPrice(
          this.productDetails.Code,
          this.productDetails.BoardStrength,
          paperType,
          this.length,
          this.width,
          this.depth,
          this.quantity,
          this.productDetails.twoSided,
          this.productDetails.luxe,
          this.productDetails.gloss,
          false,
          this.productDetails.options,
          false
        )
        .then((value) => {
          if (
            !JSON.parse(this.productDetails.luxe) &&
            this.prices &&
            this.prices[0] &&
            (this.quantity < (this.prices[0] as Price).minimumOrder ||
              this.quantity > this.prices[0].maxQuantity)
          ) {
            this.customQuantityViolation = true;
          } else if (
            JSON.parse(this.productDetails.luxe) &&
            this.prices &&
            this.prices[0] &&
            (this.quantity < (this.prices[0] as Price).luxeMinimumOrder ||
              this.quantity > this.prices[0].maxQuantity)
          ) {
            this.customQuantityViolation = true;
          } else {
            this.customQuantityViolation = false;
          }

          if (!this.customQuantityViolation) {
            // this.selectedPrice = value.price;
            // this.enteredCustomPrice =
            //   this.quantity +
            //   ' ($' +
            //   this.selectedPrice.pricePerUnit.toFixed(2) +
            //   ') each';
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    }
  }

  addToCart() {
    this.cartLoading = true;
    if (this.isTissue) {
      this.quantity /= 100;
      this.selectedPrice.quantity /= 100;
    }
    let resolveObj = {};
    try {
      resolveObj = this.prepareOrderDesignPromise(resolveObj);
      this.createOrderDesignPromise(resolveObj).then(
        (response) => {
          response.quantity = this.selectedPrice.quantity;
          this.addCartItem(response);
        },
        () => {
          this.cartLoading = false;
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        }
      );
    } catch (e) {
      this.cartLoading = false;
      this.bsModalService
        .show(ConfirmationModalComponent)
        .content?.showModal(ERROR_BODY, ERROR_TITLE);
    }
  }
  public trackByFn(index: number, item: any) {
    if (!item) {
      return index;
    } else {
      //return item.id;
      return index;
    }
  }
  numberOnly(event: { which: any; keyCode: any }) {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  prepareOrderDesignPromise(resolveObj: any) {
    if (this.isTissue) {
      this.quantity = this.quantity / 100;
    }

    this.imagesService
      .getFileContent(
        sessionStorage.getItem("container") ?? "",
        sessionStorage.getItem("outsideFileName") ?? ""
      )
      .then((res: { content: any }) => {
        resolveObj.outsideCanvasJSON = res.content;
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
    this.imagesService
      .getFileContent(
        sessionStorage.getItem("container") ?? "",
        sessionStorage.getItem("insideFileName") ?? ""
      )
      .then((res: { content: any }) => {
        resolveObj.insideCanvasJSON = res.content;
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    resolveObj.twoSided = this.productDetails.twoSided;
    resolveObj.containerName = this.productDetails.ContainerName;
    resolveObj.boxCode = this.productDetails.Code;
    resolveObj.dimensions = this.productDetails.BoxDimensions;
    resolveObj.boardType = this.productDetails.Code;
    resolveObj.boardStrength = this.productDetails.BoardStrength;
    resolveObj.kraftType = this.productDetails.paperType;
    resolveObj.secondaryKraftType = this.productDetails.secondaryPaperType;
    resolveObj.preset = this.productDetails.Preset;
    resolveObj.gloss = this.productDetails.gloss;
    resolveObj.luxe = this.productDetails.luxe;
    resolveObj.quantity = this.quantity;
    resolveObj.rush = this.productDetails.rush;
    if (this.productDetails.Subcategory === "SNAP2SF") {
      resolveObj.options = "superseal";
    } else if (this.productDetails.Subcategory === "SNAP2RSF") {
      resolveObj.options = "superseal";
      {
        resolveObj.options = "";
      }
    }
    resolveObj.insideOnly = this.productDetails.insideOnly;
    resolveObj.filename = new Date().getTime();

    return resolveObj;
  }

  createOrderDesignPromise(resolveObj: any) {
    const design = {
      customerId: resolveObj.customerID,
      container: resolveObj.containerName,
      type: "BASIC",
      outside: resolveObj.outsideCanvasJSON,
      inside: resolveObj.insideCanvasJSON,
      name: "Auto Saved",
      dimensions: resolveObj.dimensions,
      boardType: resolveObj.boardType,
      boardStrength: resolveObj.boardStrength,
      kraftType: resolveObj.kraftType,
      secondaryKraftType: resolveObj.secondaryKraftType,
      gloss: resolveObj.gloss,
      luxe: resolveObj.luxe,
      twoSided: resolveObj.twoSided,
      preset: resolveObj.preset,
      options: resolveObj.options,
      filename: resolveObj.filename,
      rush: resolveObj.rush,
    };
    return new Promise<any>((resolve, reject) => {
      this.projectService
        .createOrderItem(design)
        .then((response: { container: any; type: any; preset: any }) => {
          resolveObj.design = response;
          resolveObj.containerName = response.container;
          resolveObj.type = response.type;
          resolveObj.preset = response.preset;

          if (this.isTape) {
            this.checkoutService
              .createTape(
                resolveObj.containerName,
                resolveObj.kraftType,
                this.length,
                resolveObj.quantity,
                false
              )
              .then((tape: any) => {
                resolveObj.tape = tape;
                resolve(resolveObj);
              })
              .catch((reason: any) => {
                reject(reason);
              });
          } else if (this.isTissue) {
            this.checkoutService
              .createTissue(
                resolveObj.containerName,
                resolveObj.kraftType,
                18,
                resolveObj.quantity / 100,
                false,
                this.length,
                this.width
              )
              .then((tissue: any) => {
                resolveObj.tissue = tissue;
                resolve(resolveObj);
              })
              .catch((reason: any) => {
                reject(reason);
              });
          } else if (this.isLabel) {
            this.checkoutService
              .createLabel(
                resolveObj.containerName,
                "c",
                this.productDetails.shape.toLowerCase(),
                this.length,
                this.width,
                this.depth,
                false,
                resolveObj.quantity
              )
              .then((label: any) => {
                resolveObj.label = label;
                resolve(resolveObj);
              })
              .catch((reason: any) => {
                reject(reason);
              });
          } else if (this.isPaddedMailer) {
            this.checkoutService
              .createPaddedMailer(
                resolveObj.containerName,
                resolveObj.boxCode,
                resolveObj.boardStrength,
                resolveObj.kraftType,
                resolveObj.filename,
                this.length,
                this.width,
                this.depth,
                true,
                false,
                resolveObj.quantity
              )
              .then((paddedMailer: any) => {
                resolveObj.paddedMailer = paddedMailer;
                resolve(resolveObj);
              })
              .catch((reason: any) => {
                reject(reason);
              });
          } else {
            this.checkoutService
              .createBox(
                resolveObj.containerName,
                resolveObj.boxCode,
                resolveObj.boardStrength,
                resolveObj.kraftType,
                resolveObj.secondaryKraftType,
                resolveObj.gloss,
                resolveObj.luxe,
                resolveObj.filename,
                this.length,
                this.width,
                this.depth,
                resolveObj.preset,
                resolveObj.twoSided,
                resolveObj.insideOnly,
                resolveObj.rush,
                resolveObj.options,
                resolveObj.quantity
              )
              .then((box: any) => {
                resolveObj.box = box;
                resolve(resolveObj);
              })
              .catch((reason: any) => {
                reject(reason);
              });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  async addCartItem(resolveObj: any) {
    let lineItem = {};
    try {
      if (this.isTape) {
        resolveObj.tape.quantity = this.selectedPrice.quantity;
        lineItem = this.checkoutService.createTapeLineItem(
          resolveObj.containerName,
          this.boxData,
          resolveObj.tape
        );
      } else if (this.isTissue) {
        resolveObj.tissue.quantity = this.selectedPrice.quantity;
        lineItem = this.checkoutService.createTissueLineItem(
          resolveObj.containerName,
          this.boxData,
          resolveObj.tissue
        );
      } else if (this.isLabel) {
        resolveObj.label.quantity = this.selectedPrice.quantity;
        lineItem = this.checkoutService.createLabelLineItem(
          resolveObj.containerName,
          this.boxData,
          resolveObj.label
        );
      } else if (this.isPaddedMailer) {
        resolveObj.paddedMailer.quantity = this.selectedPrice.quantity;
        lineItem = this.checkoutService.createPaddedMailerLineItem(
          resolveObj.containerName,
          this.boxData,
          resolveObj.paddedMailer
        );
      } else {
        resolveObj.box.quantity = this.selectedPrice.quantity;

        if (
          this.boxData.aeCode === "SNAP2" ||
          this.boxData.aeCode === "SNAP2R"
        ) {
          await this.containerService
            .getContainerInfo(this.productDetails.ContainerName)
            .then((containerInfo: { templateName: string }) => {
              this.boxData.aeCode = containerInfo.templateName.substr(
                0,
                containerInfo.templateName.indexOf("_")
              );
              lineItem = this.checkoutService.createLineItem(
                resolveObj.containerName,
                this.boxData,
                resolveObj.box
              );
            })
            .catch(() => {
              this.bsModalService
                .show(ConfirmationModalComponent)
                .content?.showModal(ERROR_BODY, ERROR_TITLE);
            });
        } else {
          lineItem = this.checkoutService.createLineItem(
            resolveObj.containerName,
            this.boxData,
            resolveObj.box
          );
        }
      }
    } catch (e) {
      console.log(e);
      this.cartLoading = false;
      this.bsModalService
        .show(ConfirmationModalComponent)
        .content?.showModal(ERROR_BODY, ERROR_TITLE);
      return;
    }

    this.checkoutService
      .addToCart(lineItem)
      .then((payload:any) => {
        this.cartUrl.set(payload.url);
        this.cxmlurlencoded.set(payload.content);
        setTimeout(() => {
          this.addToCartForm.nativeElement.submit();
        });

        sessionStorage.removeItem("insideFileName");
        sessionStorage.removeItem("outsideFileName");

        // this.router.navigate(["/cart"]);
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  showModal(template: TemplateRef<any>) {
    this.modal = this.bsModalService.show(template, { class: "modal-sm" });
  }
  hide() {
    this.modal.hide();
  }

  getWholeNumber(fraction: any) {
    const temp = String(fraction).split(".", 2);
    return parseFloat(temp[0]);
  }

  convertDecimalToFraction(num: any) {
    return Fractions.convertDecimalToFraction(num);
  }
}
