<div class="product-container col-lg-3 col-xs-8">
  <div class="product-content">
    <div
      class="image-container col-xs-12"
      [routerLink]="[
        '/box-options',
        categoryCode,
        subcategory.code,
        uploadDesign
      ]"
      [ngStyle]="{ background: 'url(' + getDefaultPicture(subcategory) + ')' }"
      (mouseover)="showGIF($event.target, subcategory)"
      (mouseleave)="showStaticPicture($event.target, subcategory)"
    ></div>
    <div class="product-header">
      <div [innerHTML]="subcategory.name"></div>
    </div>
    <div
      class="product-description"
      [innerHTML]="subcategory?.data[0]?.value"
    ></div>
    <div class="button-container">
      <div
        [routerLink]="[
          '/box-options',
          categoryCode,
          subcategory.code,
          uploadDesign
        ]"
        class="link-container chevron-anchor red border"
      >
        <a>Create</a>
      </div>
    </div>
  </div>
</div>
