@if (title) {
  <div class="modal-header pb-0 border-0">
    <div class="modal-title page-title eyebrow pull-left inline">
      <h4>{{title}}</h4>
    </div>
    <button type="button" class="close pull-right" (click)="hideConfirmationModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
}
<div class="modal-body">
  <p [innerHTML]="body | keepHtml"></p>
</div>
<div class="modal-footer">
  <div class="text-right">
    @if (isDangerous) {
      <span class="mr-2">
        {{dangerousText}}
      </span>
    }
    <button type="button" class="mr-2 btn btn-primary" (click)="onConfirm()">
      {{okButtonText}}
    </button>
    @if (showCancel) {
      <button type="button" class="btn btn-primary-outline" (click)="onCancel()">
        {{cancelButtonText}}
      </button>
    }
  </div>
</div>
