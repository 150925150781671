import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private categoryCode = '';
  private subcategory = '';
  private containerName = '';
  private isUpload = false;
  private productDetails: any;
  private reviewPageDetails: any;

  constructor() {}

  getCategoryCode() {
    return this.categoryCode;
  }

  setCategoryCode(categoryCode: string) {
    this.categoryCode = categoryCode;
  }

  getSubcategory() {
    return this.subcategory;
  }

  setSubcategory(subcategory: string) {
    this.subcategory = subcategory;
  }

  getContainerName() {
    return this.containerName;
  }

  setContainerName(containerName: string) {
    this.containerName = containerName;
  }

  getIsUpload() {
    return this.isUpload;
  }

  setIsUpload(isUpload: boolean) {
    this.isUpload = isUpload;
  }

  getProductDetails() {
    return this.productDetails;
  }

  setProductDetails(productDetails: any) {
    this.productDetails = productDetails;
  }

  getReviewPageDetails() {
    return this.reviewPageDetails;
  }

  setReviewPageDetails(reviewPageDetails: any) {
    this.reviewPageDetails = reviewPageDetails;
  }

  clearAll() {
    this.categoryCode = '';
    this.subcategory = '';
    this.containerName = '';
    this.isUpload = false;
    this.productDetails = null;
    this.reviewPageDetails = null;
  }
}
