<table>
    <tr>
        <td></td>
        <td>Luxe</td>
        <td>Standard</td>
        <td>Notes</td>
    </tr>
    <tr>
        <td>Gloss Finish Option</td>
        <td><img src="assets/cb/images/icon_checkmark.svg"></td>
        <td><img src="assets/cb/images/icon_checkmark.svg"></td>
        <td></td>
    </tr>
    <tr>
        <td>Premium White Option</td>
        <td><img src="assets/cb/images/icon_checkmark.svg"></td>
        <td><img src="assets/cb/images/icon_checkmark.svg"></td>
        <td></td>
    </tr>
    <tr>
        <td>Food-Safe</td>
        <td><img src="assets/cb/images/icon_checkmark.svg"></td>
        <td><img src="assets/cb/images/icon_x-mark.svg"></td>
        <td>Meets guidelines for both primary & seconday packaging</td>
    </tr>
    <tr>
        <td>Small Text Printing</td>
        <td>4 point</td>
        <td>10 point</td>
        <td>This is the smallest the text can go before blurring</td>
    </tr>
    <tr>
        <td>Odorless</td>
        <td><img src="assets/cb/images/icon_checkmark.svg"></td>
        <td><img src="assets/cb/images/icon_x-mark.svg"></td>
        <td></td>
    </tr>
    <tr>
        <td>Water-Based Ink</td>
        <td><img src="assets/cb/images/icon_checkmark.svg"></td>
        <td><img src="assets/cb/images/icon_x-mark.svg"></td>
        <td>CompanyBox proudly uses HP true Water-Based Inks</td>
    </tr>
    <tr>
        <td>High Resolution Printing</td>
        <td>600 dpi</td>
        <td>300 dpi</td>
        <td></td>
    </tr>
    <tr>
        <td>Perfect Skin Tone</td>
        <td><img src="assets/cb/images/icon_checkmark.svg"></td>
        <td><img src="assets/cb/images/icon_x-mark.svg"></td>
        <td></td>
    </tr>
    <tr>
        <td>True Litho Replacement</td>
        <td><img src="assets/cb/images/icon_checkmark.svg"></td>
        <td><img src="assets/cb/images/icon_x-mark.svg"></td>
        <td>Replicates that quality of Lithographic printing without the cost</td>
    </tr>

   
</table>