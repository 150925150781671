import moment from 'moment';

export interface ISerializable<T> {
    deserialize(input: any): T;
}

export class Sort implements ISerializable<Sort> {

    ascending: boolean | undefined;
    direction: string | undefined;
    property: string | undefined;

    deserialize(input: any): Sort {
        this.ascending = input.ascending;
        this.direction = input.direction;
        this.property = input.property;
        return this;
    }
}

export class Pageable<T> implements ISerializable<Pageable<T>> {
  currentPage: number = 0; // not from backend - used to track page currently being displayed
  pages: number[] = []; // not from backend - an array of all pages for iterating in page

  first: boolean | undefined;
  last: boolean | undefined;
  number: number | undefined;
  numberOfElements: number | undefined;
  size: number | undefined;
  content: T[] | undefined;
  totalElements: number = 0;
  totalPages: number = 0;

  sort: Sort[] = [];

  deserialize(input: any): Pageable<T> {
    this.first = input.first || input.firstPage;
    this.last = input.last || input.lastPage;
    this.number = input.number + 1;
    this.currentPage = this.number??0;
    this.numberOfElements = input.numberOfElements;
    this.size = input.size;
    this.totalElements = input.totalElements;
    this.totalPages = input.totalPages;
    this.pages = Array.from(
      new Array(this.totalPages),
      (val, index) => index + 1
    );
    if (input.sort) {
      (input.sort as any[]).forEach((sort) => {
        this.sort.push(new Sort().deserialize(sort));
      });
    }
    return this;
  }
}

export class AuditedSerializable<T> implements ISerializable<T> {
  id: number | undefined;
  createdBy: string | undefined;
  createdTime: Date | undefined;
  updatedBy: string | undefined;
  updatedTime: Date | undefined;

  deserialize(input: any): any {
    this.id = input.id;
    if (this.id === null) {
      this.id = 0;
    }
    this.createdBy = input.createdBy;
    this.createdTime = moment(input.createdTime).toDate();
    this.updatedBy = input.updatedBy;
    this.updatedTime = moment(input.updatedTime).toDate();
  }
}

export class Logos implements ISerializable<Logos> {
  small: string | undefined;
  large: string | undefined;

  deserialize(input: any): Logos {
    this.small = input.small;
    this.large = input.large;
    return this;
  }
}

export class Configuration implements ISerializable<Configuration> {
  tenant: string | undefined;
  orderPrefix: string | undefined;
  logos: Logos | undefined;

  deserialize(input: any): Configuration {
    this.tenant = input.tenant;
    this.orderPrefix = input.orderPrefix;
    if (input.logos) {
      this.logos = new Logos().deserialize(input.logos);
    }
    return this;
  }
}

export class GraphPair<T> implements ISerializable<GraphPair<T>> {
  label: string | undefined;
  value: T | undefined;

  deserialize(input: any): GraphPair<T> {
    this.label = input.label;
    this.value = input.value;
    return this;
  }
}

export class FieldError {
  name: string | undefined;
  message: string | undefined;
  type: FieldErrorType | undefined;
  // safeMessage: SafeHtml;

  static success(name: string, message: string): FieldError {
    return FieldError.create(name, message, FieldErrorType.SUCCESS);
  }

  static info(name: string, message: string): FieldError {
    return FieldError.create(name, message, FieldErrorType.INFO);
  }

  static warning(name: string, message: string): FieldError {
    return FieldError.create(name, message, FieldErrorType.WARNING);
  }

  static error(name: string, message: string): FieldError {
    return FieldError.create(name, message, FieldErrorType.ERROR);
  }

  static create(
    name: string,
    message: string,
    type: FieldErrorType
  ): FieldError {
    const alert: FieldError = new FieldError();
    alert.message = message;
    alert.type = type;
    alert.name = name;
    return alert;
  }

  get typeValue(): string {
    switch (this.type) {
      case FieldErrorType.SUCCESS:
        return 'success';
      case FieldErrorType.INFO:
        return 'info';
      case FieldErrorType.WARNING:
        return 'warning';
      case FieldErrorType.ERROR:
        return 'danger';
      default:
        return '';  
    }
  }

  set typeValue(value: string) {
    this.type = FieldError.type(value);
  }

  static type(value: string): FieldErrorType {
    switch (value) {
      case 'success':
        return FieldErrorType.SUCCESS;
      case 'warn':
      case 'warning':
        return FieldErrorType.WARNING;
      case 'danger':
        return FieldErrorType.ERROR;
      case 'info':
        return FieldErrorType.INFO;
      default:
        return FieldErrorType.INFO;
    }
  }
}

export enum FieldErrorType {
    SUCCESS = 1,
    INFO = 2,
    WARNING = 3,
    ERROR = 4
}
